import { useEffect, useState } from 'react';
import topSearch from '../images/top-search.svg';
import textIcon from '../images/text-icon.svg';
import { Box, Modal, Typography } from '@mui/material';
import close from '../images/close.svg';
import headerIcon from '../images/header-icon.svg';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Header, Spinner } from '../components';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import deleteNotification from '../images/delete-icon.svg';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const Reminder = () => {
	const navigate = useNavigate();
	const token = Cookies.get('token');
	// USER
	const userInfo = JSON.parse(localStorage.getItem('user'));
	// USER END

	const [loading, setIsLoading] = useState(false);

	const [down, setDown] = useState(0);

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [calls, setCalls] = useState([]);

	// REACT SELECT START
	const [selectedOptions, setSelectedOptions] = useState('');
	const formattedOptions =
		calls &&
		calls.map(item => ({
			value: item.id,
			label: item.name,
		}));

	// REACT SELECT END

	// POST NOTIFICATION START
	const [info, setInfo] = useState({ title: '', description: '', time: '', date: '' });

	const notificationSubmit = async e => {
		const formData = new FormData();
		formData.append('title', info.title);
		formData.append('description', info.description);
		formData.append('date', info.date + ' ' + info.time);
		formData.append('client_id', userInfo.is_admin == 1 ? userInfo.id : selectedOptions.value);

		e.preventDefault();
		setOpen(false);
		await axios
			.post(`https://crm.orzugrand.uz/api/notification`, formData, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const notificationSubmit = res.data.data;
				notification();
			})
			.catch(er => {
				// console.log(er);
			});
	};
	// POST NOTIFICATION END

	// FILTER SELECT START
	const [selectedOption, setSelectedOption] = useState(0);
	const handleSelectChange = e => {
		setSelectedOption(e.target.value);
	};
	// FILTER SELECT END

	// NOTIFICATION GET START
	const [notifications, setNotifications] = useState([]);
	const notification = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/notification?filter=${selectedOption}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const notification = res.data.data;
				setNotifications(notification);
				setIsLoading(false);
			})
			.catch(er => {
				// console.log(er);
				setIsLoading(true);
			});
	};
	useEffect(() => {
		if (!token) {
			navigate('/auth');
		}
		if (selectedOption !== '') {
			notification();
		}
	}, [selectedOption]);

	// NOTIFICATION GET END

	// GET CALLS START
	// FILTER START
	const filterId = notifications && notifications;
	const [inputValue, setInputValue] = useState('');
	const [filteredData, setFilteredData] = useState([]);
	const handleInputChange = event => {
		const { value } = event.target;
		setInputValue(value);
	};

	useEffect(() => {
		// Filter the data based on the input value whenever the input value changes
		const filtered =
			filterId &&
			filterId.filter(item => {
				return item.title.toString().toLowerCase().includes(inputValue);
				// .toString().includes(inputValue)
			});

		setFilteredData(filterId && filtered);
	}, [inputValue, filterId]);

	const getCalls = async elem => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/clients`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const calls = res.data.data;
				setCalls(calls.data);
				setIsLoading(false);
			})

			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};
	// GET CALLS END

	// NOTIFICATION .PUT START

	const handleParam = async (id, status) => {
		await axios
			.put(`https://crm.orzugrand.uz/api/notification/${id}?status=${status}`, [], {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const creatingUser = res.data.data;
				notification();
			})
			.catch(er => {
				// console.log(er);
			});
	};

	// NOTIFICATION .PUT END

	// NOTIFICATION DELETE START
	const handleDelete = async id => {
		await axios
			.delete(`https://crm.orzugrand.uz/api/notification/${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const creatingUser = res.data.data;
				notification();
			})
			.catch(er => {
				// console.log(er);
			});
	};
	// NOTIFICATION DELETE END

	useEffect(() => {
		getCalls();
	}, []);
	const [filteredStatus, setFilteredStatus] = useState('0'); // 'all', '1', or '0'

	const handleFilter = status => {
		setFilteredStatus(status);
	};

	const filteredItems = filteredData.filter(item => item.status == filteredStatus);

	const today = new Date(); // Joriy san'atni olish

	// MORE START
	const [showMoreId, setShowMoreId] = useState(null);

	const handleClick = id => {
		setShowMoreId(showMoreId === id ? null : id);
	};

	return (
		<div>
			{/* HEADER START */}
			<header className='header'>
				<div className='message-info'>
					<h2 className='font-bold text-3xl text-[#464255] mb-2'>Eslatma</h2>
					<p className='text-[#A3A3A3] font-medium text-base'>
						Sizni tabriklaymiz <span className='text-[#2d9cdb]'>{userInfo && userInfo.name}</span>! Bu yerda sizning qaydlaringiz
						tafsilotlari.
					</p>
				</div>
				<Header />
			</header>
			{/* HEADER END */}

			<div className='top__contract flex justify-between items-center'>
				<div className='flex'>
					<div
						onClick={() => {
							setDown(0);
							handleFilter('0');
						}}
						className={
							down == 0
								? 'mr-7 pt-2 pb-2 pl-7 pr-7 bg-[#00B074] text-white rounded-[10px] font-medium text-lg leading-[21.6px] hover:opacity-[0.8] cursor-pointer'
								: 'mr-7 pt-2 pb-2 pl-7 pr-7 bg-white text-black rounded-[10px] font-medium text-lg leading-[21.6px] hover:opacity-[0.8] cursor-pointer'
						}>
						<button className=''>Faol</button>
					</div>

					<div
						onClick={() => {
							setDown(1);
							handleFilter('1');
						}}
						className={
							down == 1
								? 'mr-7 pt-2 pb-2 pl-7 pr-7 bg-[#00B074] text-white rounded-[10px] font-medium text-lg leading-[21.6px] hover:opacity-[0.8] cursor-pointer'
								: 'mr-7 pt-2 pb-2 pl-7 pr-7 bg-white text-black rounded-[10px] font-medium text-lg leading-[21.6px] hover:opacity-[0.8] cursor-pointer'
						}>
						<button className=''>Bajarilgan</button>
					</div>
				</div>

				<div className='flex items-center'>
					<p className='mr-5 font-semibold text-xs'>Filtr:</p>
					<select
						value={selectedOption}
						onChange={handleSelectChange}
						className='flex items-center font-semibold text-xs justify-between cursor-pointer mr-5 pt-4 pb-4 w-56 pl-6 pr-6 bg-[#FFFFFF] text-black rounded-[10px]'>
						<option className='font-semibold text-xs' disabled value=''>
							Vaqtini tanlang
						</option>
						<option value={0} className='font-semibold text-xs'>
							Barchasi
						</option>
						<option value={1} className='font-semibold text-xs'>
							1 kun
						</option>
						<option value={2} className='font-semibold text-xs'>
							1 haftalik
						</option>
						<option value={3} className='font-semibold text-xs'>
							1 oy
						</option>
						<option value={4} className='font-semibold text-xs'>
							1 yillik
						</option>
					</select>

					<div className='top__wrapper callSchFillter py-2 mr-4'>
						<input
							className='top__input font-semibold text-xs'
							value={inputValue}
							onChange={handleInputChange}
							type='search'
							placeholder="Sarlavhasi bo'yicha"
						/>
						<img src={topSearch} alt='search icon' />
					</div>

					{/* MODAL FORM START */}
					<div>
						<button
							onClick={handleOpen}
							className='bg-[#FF6501]  rounded-xl text-white ml-auto pt-3 pl-8 pb-3 pr-8  border-[1px] border-transparent
									text-base hover:text-[#ff6501] hover:bg-white hover:border-[1px] hover:border-[#ff6501] transition duration-150 ease-out hover:ease-in'>
							Qo'shmoq
						</button>

						<div>
							{userInfo.is_admin == 1 ? (
								<Modal
									open={open}
									onClose={handleClose}
									aria-labelledby='modal-modal-title'
									aria-describedby='modal-modal-description'>
									<Box className='flex flex-col justify-center items-center modal-box' sx={style}>
										<img
											className='ml-auto cursor-pointer'
											onClick={() => {
												handleClose();
											}}
											src={close}
											alt='Close icon'
										/>
										<Typography className='modal-title' id='modal-modal-title' variant='h5' component='h5'>
											Eslatma yarating
										</Typography>
										<form onSubmit={notificationSubmit} className='w-full flex flex-col justify-center items-center'>
											<div className='mb-5 w-full'>
												<Box className='flex items-center '>
													<div>
														<Typography className='text-base font-medium text-[#6F6767]'>Eslatma sanasini tanlang:</Typography>
													</div>
													<div className='mr-auto text-base font-medium text-[#6F6767]'>
														<input
															onChange={e => {
																setInfo(prev => {
																	return { ...prev, time: e.target.value };
																});
															}}
															className='mx-2 cursor-pointer'
															type='time'
															required
															placeholder='soat'
														/>
													</div>

													<div>
														<input
															className='bg-[#F3F2F7] px-2 py-3 rounded-[10px]  cursor-pointer'
															min={new Date().toISOString().split('T')[0]}
															required
															onChange={e => {
																setInfo(prev => {
																	return { ...prev, date: e.target.value };
																});
															}}
															type='date'
															placeholder='	Tanlang'
														/>
													</div>
												</Box>
											</div>
											<label className='flex py-2  w-full bg-[#F3F2F7] px-5 rounded-[10px] mb-6'>
												<img className='mr-5' src={headerIcon} alt='header-icon' />
												<input
													onChange={e => {
														setInfo(prev => {
															return { ...prev, title: e.target.value };
														});
													}}
													className='w-full bg-[#F3F2F7] py-1 font-medium text-base'
													type='text'
													name=''
													id=''
													placeholder='Sarlavha'
													required
												/>
											</label>

											<textarea
												onChange={e => {
													setInfo(prev => {
														return { ...prev, description: e.target.value };
													});
												}}
												className='bg-[#F3F2F7] px-9 py-6 w-full rounded-[10px] resize-none mb-6'
												name=''
												id=''
												rows='7'
												required
												placeholder="Ertaga qo'ng'iroq qiling va eslatish uchun gaplashing mijoz telefonni ko'tarmaganligi sababli to'lov"></textarea>

											<button
												className='bg-[#FF6501] py-4 px-16 rounded-[10px] cursor-pointer text-white font-semibold text-base  border-[1px] border-transparent
													hover:text-[#ff6501] hover:bg-white hover:border-[1px] hover:border-[#ff6501] transition duration-150 ease-out hover:ease-in'>
												Saqlash
											</button>
										</form>
									</Box>
								</Modal>
							) : (
								<Modal
									open={open}
									onClose={handleClose}
									aria-labelledby='modal-modal-title'
									aria-describedby='modal-modal-description'>
									<Box className='flex flex-col justify-center items-center modal-box' sx={style}>
										<img
											className='ml-auto cursor-pointer'
											onClick={() => {
												handleClose();
											}}
											src={close}
											alt='Close icon'
										/>
										<Typography className='modal-title' id='modal-modal-title' variant='h5' component='h5'>
											Eslatma yarating
										</Typography>
										<form onSubmit={notificationSubmit} className='w-full flex flex-col justify-center items-center'>
											<div className='mb-5 w-full'>
												<Box className='flex items-center '>
													<div>
														<Typography className='text-base font-medium text-[#6F6767]'>Eslatma sanasini tanlang:</Typography>
													</div>
													<div className='mr-auto text-base font-medium text-[#6F6767]'>
														<input
															onChange={e => {
																setInfo(prev => {
																	return { ...prev, time: e.target.value };
																});
															}}
															className='mx-2 cursor-pointer'
															type='time'
															required
															placeholder='soat'
														/>
													</div>

													<div>
														<input
															className='bg-[#F3F2F7] px-2 py-3 rounded-[10px]  cursor-pointer'
															required
															min={new Date().toISOString().split('T')[0]}
															onChange={e => {
																setInfo(prev => {
																	return { ...prev, date: e.target.value };
																});
															}}
															type='date'
															placeholder='	Tanlang'
														/>
													</div>
												</Box>
											</div>
											<label className='flex py-2  w-full bg-[#F3F2F7] px-5 rounded-[10px] mb-6'>
												<img className='mr-5' src={headerIcon} alt='header-icon' />
												<input
													onChange={e => {
														setInfo(prev => {
															return { ...prev, title: e.target.value };
														});
													}}
													className='w-full bg-[#F3F2F7] py-1 font-medium text-base'
													type='text'
													name=''
													id=''
													placeholder='Sarlavha'
													required
												/>
											</label>

											<div className='flex py-2 w-full bg-[#F3F2F7] px-5 rounded-[10px] mb-6'>
												<img className='mr-3' src={textIcon} alt='header-icon' />
												<Select
													required
													className='w-full'
													defaultValue={selectedOptions}
													onChange={setSelectedOptions}
													options={formattedOptions}
												/>
											</div>
											<textarea
												onChange={e => {
													setInfo(prev => {
														return { ...prev, description: e.target.value };
													});
												}}
												className='bg-[#F3F2F7] px-9 py-6 w-full rounded-[10px] resize-none mb-6'
												name=''
												id=''
												rows='7'
												required
												placeholder="Ertaga qo'ng'iroq qiling va eslatish uchun gaplashing mijoz telefonni ko'tarmaganligi sababli to'lov"></textarea>

											<button
												className='bg-[#FF6501] py-4 px-16 rounded-[10px] cursor-pointer text-white font-semibold text-base  border-[1px] border-transparent
													hover:text-[#ff6501] hover:bg-white hover:border-[1px] hover:border-[#ff6501] transition duration-150 ease-out hover:ease-in'>
												Saqlash
											</button>
										</form>
									</Box>
								</Modal>
							)}
						</div>
					</div>
					{/* MODAL FORM END */}
				</div>
			</div>
			{/* filetr */}
			{loading && <Spinner />}
			<div className='flex  w-full justify-between bg-white rounded-[10px]  px-10 py-8 h-[678px] overflow-y-scroll wrapper'>
				<div className='w-[48%]'>
					<h2 className='text-[#FF6501] text-[22px] font-bold '>Bugun uchun</h2>

					{filteredItems &&
						filteredItems.map(data => {
							return (
								<div key={data.id}>
									{data.status == '0' ? (
										new Date(data.date).getDate() === today.getDate() &&
										new Date(data.date).getMonth() === today.getMonth() &&
										new Date(data.date).getFullYear() === today.getFullYear() ? (
											<div>
												<div className='flex justify-between mb-9 relative'>
													{/* info */}
													<div className='mr-4 mt-3 '>
														<h3 className='text-[#00B074]  text-base font-bold mb-3'>{data.title}</h3>
														{data.description.length > 500 ? (
															<div className=''>
																<p>{data.description.slice(0, 500)}</p>
																{showMoreId === data.id && <p>{data.description.slice(500)}</p>}
																<button className='text-emerald-400' onClick={() => handleClick(data.id)}>
																	{showMoreId === data.id ? 'Ozroq' : "Ko'proq"}
																</button>
															</div>
														) : (
															<p>{data.description}</p>
														)}
													</div>
													{/* btn */}
													<div className='max-w-[174px] w-full mr-4'>
														<h4 className='text-[#00B074] font-semibold mb-3'>Eslatma vaqti</h4>
														<div className='bg-[#00B074] whitespace-nowrap mb-2 w-full text-white py-3 px-5 rounded-[10px]'>
															{data.date}
														</div>
														<button
															onClick={() => {
																handleParam(data.id, data.status == '0' ? '1' : '0');
																// notification();
															}}
															className='bg-white hover:text-white w-full hover:bg-[#00b074]   text-[#00B074] py-3 px-5 rounded-[10px] border-2 border-[#00B074] transition duration-150 ease-out hover:ease-in'>
															Bajarildi
														</button>
													</div>
													<button
														onClick={() => {
															handleDelete(data.id);
														}}
														className='absolute top-0 right-0 hover:scale-125 ease-in duration-300'>
														<img className='w-7 h-7 object-cover' src={deleteNotification} alt='delete-icon' />
													</button>
												</div>
											</div>
										) : null
									) : null}
								</div>
							);
						})}

					{/* ---------------- STATUS  1 START ------------------*/}
					{filteredItems &&
						filteredItems.map(data => {
							return (
								<div key={data.id}>
									{data.status == '1' ? (
										new Date(data.date).getDate() === today.getDate() &&
										new Date(data.date).getMonth() === today.getMonth() &&
										new Date(data.date).getFullYear() === today.getFullYear() ? (
											<div>
												<div className='flex justify-between mb-9 relative'>
													{/* info */}
													<div className='mr-4 mt-3 '>
														<h3 className='text-[#00B074]  text-base font-bold mb-3'>{data.title}</h3>
														{data.description.length > 500 ? (
															<div className=''>
																<p>{data.description.slice(0, 500)}</p>
																{showMoreId === data.id && <p>{data.description.slice(500)}</p>}
																<button className='text-emerald-400' onClick={() => handleClick(data.id)}>
																	{showMoreId === data.id ? 'Ozroq' : "Ko'proq"}
																</button>
															</div>
														) : (
															<p>{data.description}</p>
														)}
													</div>
													{/* btn */}
													<div className='max-w-[174px] w-full mr-4'>
														<h4 className='text-[#00B074] font-semibold mb-3'>Eslatma vaqti</h4>
														<div className='bg-[#00B074] whitespace-nowrap mb-2 w-full text-white py-3 px-5 rounded-[10px]'>
															{data.date}
														</div>
														<button
															disabled
															// onClick={() => {
															// 	handleParam(data.id, data.status == '0' ? '1' : '0');

															// }}
															className='bg-[#00B074] whitespace-nowrap mb-2 w-full text-white py-3 px-5 rounded-[10px] opacity-70'>
															Tugatilgan
														</button>
													</div>
													<button
														onClick={() => {
															handleDelete(data.id);
														}}
														className='absolute top-0 right-0 hover:scale-125 ease-in duration-300'>
														<img className='w-7 h-7 object-cover' src={deleteNotification} alt='delete-icon' />
													</button>
												</div>
											</div>
										) : null
									) : null}
								</div>
							);
						})}
					{/* ----------------- STATUS 1 ------------------ */}
				</div>

				{/* filter2 */}
				<div className='w-[48%]'>
					<h2 className='text-[#6F6767] text-[22px] font-bold '>Haftalik uchun</h2>

					{filteredItems &&
						filteredItems.map(data => {
							return (
								<div key={data.id}>
									{data.status == 0 ? (
										new Date(data.date).getDate() === today.getDate() &&
										new Date(data.date).getMonth() === today.getMonth() &&
										new Date(data.date).getFullYear() === today.getFullYear() ? null : (
											<div>
												<div className='flex justify-between mb-9 relative'>
													{/* info */}
													<div className='mr-4 mt-3 '>
														<h3 className='text-[#00B074]  text-base font-bold mb-3'>{data.title}</h3>
														{data.description.length > 500 ? (
															<div className=''>
																<p>{data.description.slice(0, 500)}</p>
																{showMoreId === data.id && <p>{data.description.slice(500)}</p>}
																<button className='text-emerald-400' onClick={() => handleClick(data.id)}>
																	{showMoreId === data.id ? 'Ozroq' : "Ko'proq"}
																</button>
															</div>
														) : (
															<p>{data.description}</p>
														)}
													</div>
													{/* btn */}
													<div className='max-w-[174px] w-full mr-4'>
														<h4 className='text-[#6F6767] font-semibold mb-3'>Eslatma vaqti</h4>
														<div className='bg-[#6F6767] whitespace-nowrap mb-2 w-full text-white py-3 px-5 rounded-[10px]'>
															{data.date}
														</div>
														<button
															onClick={() => {
																handleParam(data.id, data.status == '0' ? '1' : '0');
															}}
															className='bg-white hover:text-white w-full hover:bg-[#6F6767]   text-[#6F6767] py-3 px-5 rounded-[10px] border-2 border-[#6F6767] transition duration-150 ease-out hover:ease-in'>
															Bajarildi
														</button>
													</div>
													<button
														onClick={() => {
															handleDelete(data.id);
														}}
														className='absolute top-0 right-0 hover:scale-125 ease-in duration-300'>
														<img className='w-7 h-7 object-cover' src={deleteNotification} alt='delete-icon' />
													</button>
												</div>
											</div>
										)
									) : null}
								</div>
							);
						})}

					{/* ------------------ STATUS 1 ------------------- */}
					{filteredItems &&
						filteredItems.map(data => {
							return (
								<div key={data.id}>
									{data.status == 1 ? (
										new Date(data.date).getDate() === today.getDate() &&
										new Date(data.date).getMonth() === today.getMonth() &&
										new Date(data.date).getFullYear() === today.getFullYear() ? null : (
											<div>
												<div className='flex justify-between mb-9 relative'>
													{/* info */}
													<div className='mr-4 mt-3 '>
														<h3 className='text-[#00B074]  text-base font-bold mb-3'>{data.title}</h3>
														{data.description.length > 500 ? (
															<div className=''>
																<p>{data.description.slice(0, 500)}</p>
																{showMoreId === data.id && <p>{data.description.slice(500)}</p>}
																<button className='text-emerald-400' onClick={() => handleClick(data.id)}>
																	{showMoreId === data.id ? 'Ozroq' : "Ko'proq"}
																</button>
															</div>
														) : (
															<p>{data.description}</p>
														)}
													</div>
													{/* btn */}
													<div className='max-w-[174px] w-full mr-4'>
														<h4 className='text-[#6F6767] font-semibold mb-3'>Eslatma vaqti</h4>
														<div className='bg-[#6F6767] whitespace-nowrap mb-2 w-full text-white py-3 px-5 rounded-[10px]'>
															{data.date}
														</div>
														<button
															disabled
															// onClick={() => {
															// 	handleParam(data.id, data.status == '0' ? '1' : '0');
															// }}
															className='bg-[#6F6767] whitespace-nowrap mb-2 w-full text-white py-3 px-5 rounded-[10px] opacity-70'>
															Tugatilgan
														</button>
													</div>
													<button
														onClick={() => {
															handleDelete(data.id);
														}}
														className='absolute top-0 right-0 hover:scale-125 ease-in duration-300'>
														<img className='w-7 h-7 object-cover' src={deleteNotification} alt='delete-icon' />
													</button>
												</div>
											</div>
										)
									) : null}
								</div>
							);
						})}
					{/* ------------------ STATUS 1 ------------------- */}
				</div>
			</div>
		</div>
	);
};

export default Reminder;
