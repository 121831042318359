import { Link, useNavigate } from 'react-router-dom';
import pageNotFound from '../images/404.svg';
import { useEffect } from 'react';

const PageNotFound = () => {
	const userInfo = JSON.parse(localStorage.getItem('user'));
	const navigate = useNavigate();
	useEffect(() => {
		setTimeout(() => {
			userInfo.is_admin == 1 ? navigate('/') : navigate('/calls-schedule');
		}, 3000);
	}, []);
	return (
		<div className='flex flex-col justify-center items-center'>
			<h1 className='text-5xl mb-5'>👇PageNotFound👇</h1>
			{userInfo.is_admin == 1 ? (
				<Link className='text-4xl underline' to={'/'}>
					Home page
				</Link>
			) : (
				<Link className='text-4xl underline' to={'/calls-schedule'}>
					Call schedule page
				</Link>
			)}
			<img className='w-[60%]' src={pageNotFound} alt='PageNotFound' />
		</div>
	);
};

export default PageNotFound;
