import { useNavigate } from 'react-router-dom';
import returnIcon from '../images/return-icon.svg';

const Return = props => {
	const navigate = useNavigate();
	return (
		<>
			<div className={`return ${props.classes}`} onClick={() => navigate(props.navigate)}>
				<img className='return__icon' src={returnIcon} alt='return icon' />
				<div className='return__name'>{props.title}</div>
			</div>
		</>
	);
};

export default Return;
