import './scss/global.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { initializeStore } from './redux/configureStore';

const root = ReactDOM.createRoot(document.getElementById('root'));
const { store, persistor } = initializeStore();
console.log(store, persistor, 'initialize');
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);
