import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Cookies from 'js-cookie';
import axios from 'axios';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import headerIcon from '../images/header-icon.svg';
import textIcon from '../images/text-icon.svg';
import close from '../images/close.svg';
import Select from 'react-select';
import { Spinner } from '../components';
import { Link } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const StatsTwo = props => {
	const token = Cookies.get('token');
	useEffect(() => {
		getCalls();
	}, []);

	// MODAL START
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [calls, setCalls] = useState([]);
	// REACT SELECT START
	const [selectedOptions, setSelectedOptions] = useState('');
	const formattedOptions =
		calls &&
		calls.map(item => ({
			value: item.id,
			label: item.name,
		}));

	// REACT SELECT END

	// POST NOTIFICATION START
	const [info, setInfo] = useState({ title: '', description: '', time: '', date: '' });

	const notificationSubmit = async e => {
		const formData = new FormData();
		formData.append('title', info.title);
		formData.append('description', info.description);
		formData.append('date', info.date + ' ' + info.time);
		formData.append('client_id', selectedOptions.value);

		e.preventDefault();
		setOpen(false);
		await axios
			.post(`https://crm.orzugrand.uz/api/notification`, formData, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const notificationSubmit = res.data.data;
				props.notification();
			})
			.catch(er => {
				// console.log(er);
			});
	};
	// POST NOTIFICATION END

	// MODAL END

	// NOTIFICATION GET START

	const currentDate = new Date();

	const getItemBackgroundColor = itemDate => {
		const arrayDate = new Date(itemDate);
		const timeDifference = arrayDate - currentDate;
		const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
		const hoursDifference = (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
		const minutesDifference = (timeDifference % (1000 * 60 * 60)) / (1000 * 60);

		if (daysDifference > 2) {
			return '#00B074';
		} else if (daysDifference < 1 && hoursDifference < 24 && minutesDifference >= 0) {
			return '#EA2121';
		} else {
			return '#FF6501';
		}
	};

	const filteredItems =
		props.notifications &&
		props.notifications.filter(item => {
			const itemDate = new Date(item.date);
			return itemDate >= currentDate;
		});

	const [isLoading, setIsLoading] = useState(false);

	const getCalls = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/clients`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const calls = res.data.data;
				setCalls(calls.data);
				setIsLoading(false);
			})

			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	// NOTIFICATION GET END
	const remainingValue = 300 - props.interestValue;

	// Dynamically generate the data for the chart based on interestValue
	const chartData = {
		datasets: [
			{
				data: [props.interestValue, remainingValue],
				backgroundColor: ['#ffc480', 'rgba(255, 91, 91, 0.15)'],
			},
		],
	};

	// NOTIFICATION GET END
	const remainingValue2 = 300 - props.finished;

	// Dynamically generate the data for the chart based on finished
	const chartData2 = {
		datasets: [
			{
				data: [props.finished, remainingValue2],
				backgroundColor: ['#00B074', 'rgba(0, 176, 116, 0.15)'],
			},
		],
	};

	const canvasWidth = 153; // Istalgan o'lchamni tanlang
	const canvasHeight = 153; // Istalgan o'lchamni tanlang

	return (
		<>
			<div className=''>
				<div className='stats'>
					<div className='stats__item'>
						<div className='stats__top'>
							<h3 className='text-[#464255] 2xl:text-2xl 2xl:font-bold text-xl font-semibold'>1 kunlik qo'ng'iroqlar rejasi</h3>
						</div>

						{isLoading && <Spinner />}
						<div className='starts__chart_flex'>
							<div className=' mr-auto starts__chars2 '>
								<div className='stats__graph'>
									<Doughnut
										data={chartData}
										width={canvasWidth}
										height={canvasHeight}
										options={{ responsive: true, borderWidth: false }}
									/>
									<div className='stats__per'>{props.statistics && props.statistics.unfinished}</div>
								</div>
								<div className='2xl:text-lg text-base 2xl:font-semibold font-medium text-[#464255]'>Tugallanmagan</div>
							</div>

							<div className='starts__chars2 ml-1'>
								<div className='stats__graph'>
									<Doughnut
										data={chartData2}
										width={canvasWidth}
										height={canvasHeight}
										options={{ responsive: true, borderWidth: false }}
									/>
									<div className='stats__per'>{props.statistics && props.statistics.finished}</div>
								</div>
								<div className='2xl:text-lg text-base 2xl:font-semibold font-medium text-[#464255]'>Tugallangan</div>
							</div>
						</div>
					</div>

					<div className='stats__item'>
						<div className='stats__top'>
							<h3 className='text-[#FF6501] 2xl:text-2xl text-xl 2xl:font-bold font-semibold '>Eslatma</h3>
							<div className='opacity-80'>
								<svg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M1.66667 20.3333H8.66667C8.66667 20.9522 8.9125 21.5457 9.35008 21.9833C9.78767 22.4208 10.3812 22.6667 11 22.6667C11.6188 22.6667 12.2123 22.4208 12.6499 21.9833C13.0875 21.5457 13.3333 20.9522 13.3333 20.3333H20.3333C20.6428 20.3333 20.9395 20.2104 21.1583 19.9916C21.3771 19.7728 21.5 19.4761 21.5 19.1667C21.5 18.8572 21.3771 18.5605 21.1583 18.3417C20.9395 18.1229 20.6428 18 20.3333 18V9.83333C20.3333 7.35798 19.35 4.98401 17.5997 3.23367C15.8493 1.48333 13.4754 0.5 11 0.5C8.52465 0.5 6.15068 1.48333 4.40034 3.23367C2.65 4.98401 1.66667 7.35798 1.66667 9.83333V18C1.35725 18 1.0605 18.1229 0.841709 18.3417C0.622916 18.5605 0.5 18.8572 0.5 19.1667C0.5 19.4761 0.622916 19.7728 0.841709 19.9916C1.0605 20.2104 1.35725 20.3333 1.66667 20.3333V20.3333ZM4 9.83333C4 7.97682 4.7375 6.19634 6.05025 4.88359C7.36301 3.57083 9.14348 2.83333 11 2.83333C12.8565 2.83333 14.637 3.57083 15.9497 4.88359C17.2625 6.19634 18 7.97682 18 9.83333V18H4V9.83333Z'
										fill='#FF6501'
									/>
								</svg>
							</div>
						</div>
						<div className='overflow-y-auto wrapper h-[180px] pr-2'>
							{filteredItems && filteredItems.length === 0 ? (
								<h2 className='text-center text-[#464255] text-xl font-bold'>Eslatma hozir mavjud emas!</h2>
							) : (
								filteredItems &&
								filteredItems.map(data => {
									return (
										<Link
											to={'/reminder'}
											key={data.id}
											className='flex justify-between cursor-pointer mb-[17px] pt-3 pl-4 pr-4 pb-3 rounded-xl text-white'
											style={{ backgroundColor: getItemBackgroundColor(data.date) }}>
											<h2 className='text-sm font-bold'>
												{data.client.name} ga qo'ng'iroq qiling uning id {data.client.id}
											</h2>
											<h2 className='text-sm font-bold ml-2'>{data.date}</h2>
										</Link>
									);
								})
							)}
						</div>
						<div className='flex justify-between '>
							<div></div>

							{/* MODAL FORM START */}
							<div>
								<button
									onClick={handleOpen}
									className='bg-[#FF6501] mt-2 rounded-xl text-white ml-auto pt-2 pl-6 pb-2 pr-6  border-[1px] border-transparent
										hover:text-[#ff6501] hover:bg-white hover:border-[1px] hover:border-[#ff6501] transition duration-150 ease-out hover:ease-in'>
									Qo'shmoq
								</button>

								<div>
									<Modal
										open={open}
										onClose={handleClose}
										aria-labelledby='modal-modal-title'
										aria-describedby='modal-modal-description'>
										<Box className='flex flex-col justify-center items-center modal-box' sx={style}>
											<img
												className='ml-auto cursor-pointer'
												onClick={() => {
													handleClose();
												}}
												src={close}
												alt='Close icon'
											/>
											<Typography className='modal-title' id='modal-modal-title' variant='h5' component='h5'>
												Eslatma yarating
											</Typography>
											<form onSubmit={notificationSubmit} className='w-full flex flex-col justify-center items-center'>
												<div className='mb-5 w-full'>
													<Box className='flex items-center '>
														<div>
															<Typography className='text-base font-medium text-[#6F6767]'>Eslatma sanasini tanlang:</Typography>
														</div>
														<div className='mr-auto text-base font-medium text-[#6F6767]'>
															<input
																onChange={e => {
																	setInfo(prev => {
																		return { ...prev, time: e.target.value };
																	});
																}}
																className='mx-2 cursor-pointer'
																type='time'
																required
																placeholder='soat'
															/>
														</div>

														<div>
															<input
																className='bg-[#F3F2F7] px-2 py-3 rounded-[10px]  cursor-pointer'
																min={new Date().toISOString().split('T')[0]}
																required
																onChange={e => {
																	setInfo(prev => {
																		return { ...prev, date: e.target.value };
																	});
																}}
																type='date'
																placeholder='	Tanlang'
															/>
														</div>
													</Box>
												</div>
												<label className='flex py-2  w-full bg-[#F3F2F7] px-5 rounded-[10px] mb-6'>
													<img className='mr-5' src={headerIcon} alt='header-icon' />
													<input
														onChange={e => {
															setInfo(prev => {
																return { ...prev, title: e.target.value };
															});
														}}
														className='w-full bg-[#F3F2F7] py-1 font-medium text-base'
														type='text'
														name=''
														id=''
														placeholder='Sarlavha'
														required
													/>
												</label>

												<div className='flex py-2 w-full bg-[#F3F2F7] px-5 rounded-[10px] mb-6'>
													<img className='mr-3' src={textIcon} alt='header-icon' />
													<Select
														className='w-full'
														defaultValue={selectedOptions}
														onChange={setSelectedOptions}
														options={formattedOptions}
													/>
												</div>
												<textarea
													onChange={e => {
														setInfo(prev => {
															return { ...prev, description: e.target.value };
														});
													}}
													className='bg-[#F3F2F7] px-9 py-6 w-full rounded-[10px] resize-none mb-6'
													name=''
													id=''
													rows='7'
													required
													placeholder="Ertaga qo'ng'iroq qiling va eslatish uchun gaplashing mijoz telefonni ko'tarmaganligi sababli to'lov"></textarea>

												<button
													className='bg-[#FF6501] py-4 px-16 rounded-[10px] cursor-pointer text-white font-semibold text-base  border-[1px] border-transparent
													hover:text-[#ff6501] hover:bg-white hover:border-[1px] hover:border-[#ff6501] transition duration-150 ease-out hover:ease-in'>
													Saqlash
												</button>
											</form>
										</Box>
									</Modal>
								</div>
							</div>
							{/* MODAL FORM END */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StatsTwo;
