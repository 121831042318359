import React, { useState } from 'react';
import authIcon2 from '../images/auth-2.svg';
import authIcon3 from '../images/eye-closed.svg';
import defaultIcon from '../images/user.png';
import { useRef } from 'react';
import authIcon4 from '../images/eye.svg';
import $ from 'jquery';
import axios from 'axios';
import Cookies from 'js-cookie';
import profile1 from '../images/setting1.svg';
import profile2 from '../images/setting2.svg';
import profile3 from '../images/setting3.svg';
import { Header, Spinner } from '../components';
import { IMaskInput } from 'react-imask';
import { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import { Stack } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ProfileSettings = () => {
	const navigate = useNavigate();
	const userInfo = JSON.parse(localStorage.getItem('user'));
	const imageUrl = 'https://crm.orzugrand.uz/images';
	const token = Cookies.get('token');
	$('.auth__eye').on({
		mousedown: function () {
			$(this).attr('src', authIcon4);
			$(this).siblings('input').attr('type', 'text');
		},

		mouseup: function () {
			$(this).attr('src', authIcon3);
			$(this).siblings('input').attr('type', 'password');
		},
	});
	const [isLoading, setIsLoading] = useState(false);
	const [prevImage, setPrevImage] = useState();

	const [user, setUser] = useState('');

	const getUser = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/user/${userInfo && userInfo.id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const user = res.data.data;

				setUser(user);
				setPrevImage(`${imageUrl}/${user && user.image}`);

				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	useEffect(() => {
		if (!token) {
			navigate('/auth');
		}
		getUser();
	}, []);

	const [photoSelected, setPhotoSelected] = useState(false);
	const uploadTrigger = useRef();
	const inputFile = useRef();
	const [isValidated, setIsValidated] = useState(false);

	const clearImage = () => {
		setPrevImage(defaultIcon);
		setPhotoSelected(false);
		formDatas.image = '';
	};

	const triggerUpload = e => {
		e.preventDefault();
		uploadTrigger.current.nextElementSibling.click();
	};

	const [formDatas, setFormDatas] = useState({
		name: '',
		surname: '',
		birthday: '',
		email: '',
		phone1: '',
		phone2: '',
		city: '',
		address: '',
		description: '',
		old_password: '',
		password: '',
		confirm_password: '',
		image: null,
	});

	const handleSubmit = async e => {
		const formData = new FormData();

		formData.append('name', formDatas.name ? formDatas.name : user.name);
		formData.append('surname', formDatas.surname ? formDatas.surname : user.surname);
		formData.append('birthday', formDatas.birthday ? formDatas.birthday : user.birthday);
		formData.append('email', formDatas.email ? formDatas.email : user.email);
		formData.append('phone1', formDatas.phone1 ? formDatas.phone1 : user.phone1);
		formData.append('phone2', formDatas.phone2 ? formDatas.phone2 : user.phone2);
		formData.append('city', formDatas.city ? formDatas.city : user.city);
		formData.append('image', formDatas.image ? formDatas.image : null);
		formData.append('address', formDatas.address ? formDatas.address : user.address);
		formData.append('description', formDatas.description ? formDatas.description : user.description);
		formData.append('password', formDatas.password ? formDatas.password : '');
		formData.append('confirm_password', formDatas.confirm_password ? formDatas.confirm_password : '');
		formData.append('old_password', formDatas.old_password ? formDatas.old_password : '');
		formData.append('_method', 'PUT');
		e.preventDefault();

		await axios
			.post(`https://crm.orzugrand.uz/api/user/${user.id}`, formData, {
				headers: {
					'Content-Type': 'multiple/formdata',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				if (res.status == 401) {
					// console.log(res.status);
				} else {
					const creatingUser = res.data.data;
					localStorage.setItem('user', JSON.stringify(creatingUser));
					handleClick();
					setFormDatas({
						old_password: '',
						password: '',
						confirm_password: '',
					});
					getUser();
				}
			})
			.catch(er => {
				if (er.response.status == 401) {
					handleClicks();
				} else {
					handleClickEmail();
				}
			});
	};

	// SNACKBAR START
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	// SNACKBAR END

	// SNACKBAR START
	const [opens, setOpens] = React.useState(false);

	const handleClicks = () => {
		setOpens(true);
	};

	const handleCloses = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setOpens(false);
	};
	// SNACKBAR END

	// SNACKBAR START
	const [openEmail, setOpenEmail] = React.useState(false);

	const handleClickEmail = () => {
		setOpenEmail(true);
	};

	const handleCloseEmail = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setOpenEmail(false);
	};
	// SNACKBAR END

	return (
		<>
			{/* O'ZGARISHLAR SAQLANDI */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
						O'zgarishlar saqlandi!
					</Alert>
				</Snackbar>
			</Stack>

			{/* OLD PASWWORD XATO */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={opens} autoHideDuration={3000} onClose={handleCloses}>
					<Alert severity='error'>Eski parolni to'g'ri kiriting!</Alert>
				</Snackbar>
			</Stack>

			{/* GMAIL XATO */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={openEmail} autoHideDuration={3000} onClose={handleCloseEmail}>
					<Alert severity='error'>Bu email mavjud boshqa kiriting!</Alert>
				</Snackbar>
			</Stack>

			{/* HEADER START */}
			<header className='header'>
				<div className='message-info'>
					<h2 className='font-bold text-3xl text-[#464255] mb-2'>Profilni sozlash</h2>
					<p className='text-[#A3A3A3] font-medium text-base'>
						Sizni tabriklaymiz <span className='text-[#2d9cdb]'>{userInfo && userInfo.name}</span>! Bu erda siz profilingizni
						o'rnatasiz.
					</p>
				</div>
				<Header />
			</header>
			{/* HEADER END */}

			{isLoading && <Spinner />}
			<div className='bg-white flex items-start justify-between py-11 px-9 rounded-[10px] border border-[#DCDCDC] profilSeting'>
				{/* ASOSIY MA'LUMOT FORM */}
				<div className='w-[40%]'>
					<div className='font-medium text-xl leading-7 text-[#678391] text-center mb-6 py-1'>
						<h2 className='bg-[#FAFAFA] text-center'>ASOSIY MA'LUMOT</h2>
					</div>

					<form onSubmit={handleSubmit}>
						<div className='flex items-center'>
							{/* left */}

							<div className='auth__wrapping mr-7'>
								<img
									className='auth__avatar'
									src={prevImage && prevImage != 'https://crm.orzugrand.uz/images/null' ? prevImage : defaultIcon}
									alt='user img'
								/>
							</div>
							{/* right */}
							<div>
								<h2 className='font-medium text-2xl mb-3 leading-5'>{user.name}</h2>
								<p className='font-medium text-lg mb-4 leading-5'>
									<span className='text-[#6F6767]'>Lavozimi:</span>
									<span className='text-[#464255]'>{user.is_admin == 0 ? 'Menejer' : 'ROP'}</span>
								</p>
								<button
									onClick={triggerUpload}
									ref={uploadTrigger}
									className='py-2 px-5 border-[1px] border-transparent bg-[#FF6501] text-white rounded-xl hover:text-[#FF6501] hover:bg-white hover:border-[#FF6501]  transition duration-150 ease-out hover:ease-in'>
									Rasm yuklash
								</button>

								<input
									className='hidden'
									ref={inputFile}
									onChange={e => {
										setFormDatas(prev => {
											return { ...prev, image: e.target.files[0] };
										});
										// setFormDatas({ ...user, image: e.target.files[0] });

										const files = e.target.files;
										if (files && files.length > 0) {
											const selectedFile = files[0];
											if (typeof window != 'undefined') {
												const url = URL.createObjectURL(selectedFile);
												setPrevImage(url);
												setPhotoSelected(true);
											}
										}
									}}
									type='file'
									accept='image/png, image/jpeg, image/svg'
									name='image'
								/>
							</div>
						</div>

						{/* 1 */}
						<span className='text-[#678391] text-base font-medium'>Ism:</span>
						<label>
							<input
								autoComplete='off'
								className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
								type='text'
								name='name'
								required
								defaultValue={user.name}
								onChange={e => {
									setFormDatas(prev => {
										return { ...prev, name: e.target.value };
									});
								}}
								placeholder='Ism:'
							/>
						</label>

						{/* 2 */}
						<span className='text-[#678391] text-base font-medium'>Familya:</span>
						<label>
							<input
								autoComplete='off'
								className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
								type='text'
								name='surname'
								defaultValue={user.surname == 'null' ? '' : user.surname}
								onChange={e => {
									setFormDatas(prev => {
										return { ...prev, surname: e.target.value };
									});
								}}
								placeholder='Familya:'
							/>
						</label>

						{/* 3 */}
						<span className='text-[#678391] text-base font-medium'>Tug'ilgan kun:</span>
						<label>
							<input
								autoComplete='off'
								className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
								type='date'
								name='birthday'
								defaultValue={user.birthday}
								onChange={e => {
									setFormDatas(prev => {
										return { ...prev, birthday: e.target.value };
									});
								}}
								placeholder='25.07.1998г'
							/>
						</label>

						{/* 4 */}
						<span className='text-[#678391] text-base font-medium'> Email:</span>
						<label>
							<input
								autoComplete='off'
								className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
								type='email'
								name='email'
								defaultValue={user.email}
								onChange={e => {
									setFormDatas(prev => {
										return { ...prev, email: e.target.value };
									});
								}}
								placeholder='Email:'
							/>
						</label>

						{/* 4 */}
						<span className='text-[#678391] text-base font-medium'>Telefon</span>

						<IMaskInput
							required
							autoComplete='off'
							name='phone1'
							defaultValue={user.phone1 ? user.phone1 : ''}
							onChange={e => {
								setFormDatas(prev => {
									return { ...prev, phone1: e.target.value };
								});
							}}
							className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
							mask='+998 00 000 00 00'
							radix='.'
							placeholder='+998'
						/>

						{/* 5 */}
						<span className='text-[#678391] text-base font-medium'>Telefon 2</span>

						<IMaskInput
							autoComplete='off'
							name='phone2'
							defaultValue={user.phone2 == 'null' ? '' : user.phone2}
							onChange={e => {
								setFormDatas(prev => {
									return { ...prev, phone2: e.target.value };
								});
							}}
							className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
							mask='+998 00 000 00 00'
							radix='.'
							placeholder='+998'
						/>

						{/* 6 */}
						<span className='text-[#678391] text-base font-medium'>Shahar</span>
						<label>
							<input
								autoComplete='off'
								className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
								type='text'
								name='city'
								required
								defaultValue={user.city}
								onChange={e => {
									setFormDatas(prev => {
										return { ...prev, city: e.target.value };
									});
								}}
								placeholder='Shahar'
							/>
						</label>

						{/* 7 */}
						<span className='text-[#678391] text-base font-medium'>Yashash manzili</span>
						<label>
							<input
								autoComplete='off'
								className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
								type='text'
								name='address'
								defaultValue={user.address == 'null' ? '' : user.address}
								onChange={e => {
									setFormDatas(prev => {
										return { ...prev, address: e.target.value };
									});
								}}
								placeholder='Yashash manzili'
							/>
						</label>

						{/* 8 */}
						<span className='text-[#678391] text-base font-medium'>Men haqimda</span>
						<label>
							<textarea
								className='w-full profileInput text-[#678391] text-base font-medium block border-[#CCD6DA] border rounded-md py-4 px-6 my-3'
								type='text'
								name='description'
								defaultValue={user.description == 'null' ? '' : user.description}
								onChange={e => {
									setFormDatas(prev => {
										return { ...prev, description: e.target.value };
									});
								}}
								placeholder="O'zingiz haqingizda bir oz gapirib bering"
							/>
						</label>

						<div className='font-medium text-xl leading-7 text-[#678391] text-center mb-6 mt-4 py-1'>
							<h2 className='bg-[#FAFAFA] text-center'>SHAXSIY HISOBIGA KIRISH UCHUN PAROL</h2>
						</div>
						{/* PAROl */}
						<div className='auth__box my-3'>
							<div className='auth__wrapper'>
								<img className='auth__icon' src={authIcon2} alt='auth icon' />
								<input
									// className={isValidated ? 'input-danger' : ''}
									onChange={e => {
										setFormDatas(prev => {
											return { ...prev, old_password: e.target.value };
										});
									}}
									type='password'
									name='old_password'
									placeholder='Eski parol'
									value={formDatas.old_password}
								/>
								<img className='auth__eye' src={authIcon3} alt='auth icon' />
							</div>
						</div>
						<div className='auth__box my-3'>
							<div className='auth__wrapper'>
								<img className='auth__icon' src={authIcon2} alt='auth icon' />
								<input
									className={isValidated ? 'input-danger' : ''}
									onChange={e => {
										setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
										formDatas.confirm_password == e.target.value ? setIsValidated(false) : setIsValidated(true);
									}}
									type='password'
									name='password'
									placeholder='Parol'
									value={formDatas.password}
								/>
								<img className='auth__eye' src={authIcon3} alt='auth icon' />
							</div>
						</div>
						<div className='auth__box my-3'>
							<div className='auth__wrapper'>
								<img className='auth__icon' src={authIcon2} alt='auth icon' />
								<input
									className={isValidated ? 'input-danger' : ''}
									onChange={e => {
										setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
										formDatas.password == e.target.value ? setIsValidated(false) : setIsValidated(true);
									}}
									type='password'
									name='confirm_password'
									value={formDatas.confirm_password}
									placeholder='Parolni takrorlang'
								/>
								<img className='auth__eye' src={authIcon3} alt='auth icon' />
							</div>
						</div>
						{isValidated ? <div className='auth__alert'>Parollar bir xil bo&apos;lishi kerak!</div> : ''}

						<div className='flex items-center mt-5'>
							<button
								className='text-white mr-3 font-semibold text-xl bg-[#FF6501] px-12 py-2 rounded-md gap-3 border border-transparent hover:text-[#ff6501] hover:bg-white hover:border-[#ff6501] transition duration-150 ease-out hover:ease-in'
								type='submit'>
								Saqlash
							</button>

							<button
								onClick={() => {
									setFormDatas('');
								}}
								className='text-white font-semibold text-xl bg-[#A3A3A3] px-12 py-2 rounded-md gap-3 border border-transparent hover:text-[#A3A3A3] hover:bg-white hover:border-[#A3A3A3] transition duration-150 ease-out hover:ease-in'
								type='reset'>
								Bekor qilish
							</button>
						</div>
					</form>
				</div>

				{/* Shaxsiy ko'rsatkichlar */}
				{user.is_admin == 0 ? (
					<div className='2xl:w-[56%] w-[58%] bg-[#F9F8FF] py-10 px-4 rounded-xl profil-shaxsiy'>
						<h2 className='text-center text-[22px] font-bold text-[#7684B6] mb-6'>Shaxsiy ko'rsatkichlar</h2>
						{/* TOP */}
						{/* 3 */}
						<div className='bg-[#F8F6FF] rounded-[20px] flex items-center justify-between mb-10'>
							<div>
								{/* 3.1 */}
								<div className='flex items-center mb-10'>
									<div className='bg-[#FF6501] text-white  2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
										<img src={profile1} alt='' width={25} height={25} />
									</div>
									<div>
										<h3 className=' font-semibold text-[#FF6501] flex-nowrap'>
											Sovrin 50 dan ortiq <br /> qo'ng'iroqlar uchun
										</h3>
									</div>
								</div>

								{/* 3.1 */}
								<div className='flex items-center'>
									<div className='bg-[#FF6501] text-white  2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
										<span className='2xl:text-[22px] 2xl:font-bold text-lg font-semibold'>{user.called}</span>
									</div>
									<div>
										<h3 className='font-semibold text-[#FF6501]'>
											Bajarildi <br /> qo'ng'iroqlar
										</h3>
									</div>
								</div>
							</div>

							<div>
								{/* 3.2 */}
								<div className='flex items-center mb-10'>
									<div className='bg-[#00B074] text-white  2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
										<img src={profile2} alt='' width={25} height={25} />
									</div>
									<div>
										<h3 className='font-semibold text-[#00B074]'>
											Sovrin 100 dan ortiq <br /> qo'ng'iroqlar uchun
										</h3>
									</div>
								</div>

								{/* 3.2 */}
								<div className='flex items-center'>
									<div className='bg-[#00B074] text-white  2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
										<span className='2xl:text-[22px] 2xl:font-bold text-lg font-semibold'>{user.bought}</span>
									</div>
									<div>
										<h3 className='font-semibold text-[#00B074]'>
											Movaqiyatli <br /> qo'ng'iroqlar
										</h3>
									</div>
								</div>
							</div>
							<div>
								{/* 3.3 */}
								<div className='flex items-center mb-10'>
									<div className='bg-[#2D9CDB] text-white 2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
										<img src={profile3} alt='' width={25} height={25} />
									</div>
									<div>
										<h3 className='font-semibold text-[#2D9CDB]'>
											Sovrin 500 dan ortiq <br /> qo'ng'iroqlar uchun
										</h3>
									</div>
								</div>

								{/* 3.3 */}
								<div className='flex items-center'>
									<div className='bg-[#2D9CDB] text-white 2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
										{user.short_date}
									</div>
									<div>
										<h3 className='	font-semibold text-[#2D9CDB]'>
											Ish tajribasi <br /> Orzu Grandda
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
};

export default ProfileSettings;
