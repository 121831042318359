import topSearch from '../images/top-search.svg';
import '../scss/YourComponent.css';
import { useRef, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useEffect } from 'react';
import Spinner from '../components/Spinner';
import defaultIcon from '../images/user.png';
import messageEmpty from '../images/messaga-empty.svg';
import messageIconText from '../images/message-icon-text.svg';
import { Header } from '../components';
import { useNavigate } from 'react-router-dom';

const Message = ({ pusher }) => {
	const navigate = useNavigate();
	const messagesEndRef = useRef(null);

	const [isLoading, setIsLoading] = useState(false);
	const token = Cookies.get('token');
	const imageUrl = 'https://crm.orzugrand.uz/images';

	// USER
	const userInfo = JSON.parse(localStorage.getItem('user'));
	// USER END

	const [messages, setMesages] = useState(null);
	const [id, setID] = useState('');
	const [setDate, setSetDate] = useState('');
	const dateMessage = [];

	// GET MESSAGES START
	const getSMS = async elemId => {
		setIsLoading(true);
		await axios
			.get(`https://chat.orzugrand.uz/api/chatid?id=${elemId}&auth=${userInfo.id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const chats = res.data;
				chats &&
					// eslint-disable-next-line array-callback-return
					chats.map(item => {
						dateMessage.push(item.created_at.slice(0, 10));
					});
				setMesages(chats);
				const setDate = new Set(dateMessage);
				setSetDate(setDate);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	// GET MESSAGES END

	useEffect(() => {
		const channel = pusher.subscribe(`clik${userInfo && userInfo.id}`);

		channel.bind('clik', function (data) {
			getChats();
			return () => {
				pusher.unsubscribe(`clik${userInfo && userInfo.id}`);
			};
		});
	}, []);

	// GETCHATS START

	const [chats, setChats] = useState('');
	const getChats = async () => {
		setIsLoading(true);
		await axios
			.get(`https://chat.orzugrand.uz/api/chats/${userInfo && userInfo.id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				// setIsLoading(true);
				const chats = res.data;
				setChats(chats);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	const [to, setTo] = useState(null);
	const [userId, setUserId] = useState(null);
	// GETCHATS EDN

	const [selectedId, setSelectedId] = useState(null);

	const handleItemClick = id => {
		setSelectedId(id);
	};

	const [active, setActive] = useState(null);

	const handleActive = id => {
		setActive(id);
	};

	const originalDate = messages && messages[0].created_at;
	const formattedDate = new Date(originalDate).toLocaleDateString('en-US', {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	});

	const formattedDates = new Date(originalDate).toLocaleDateString('en-US', {
		day: '2-digit',
		month: 'short',
	});

	// POST MESSAGA FORM START
	const [chatId, setChatId] = useState('');

	const [info, setInfo] = useState({ text: '' });
	const [loadingForm, setLoadingForm] = useState(false);

	const messageFormHendler = async e => {
		const formData = new FormData();
		formData.append('from', userInfo.id);
		formData.append('text', info.text.trim());
		formData.append('chat_id', chatId && chatId);
		formData.append('to', userId);

		e.preventDefault();

		setLoadingForm(true);
		await axios
			.post(`https://chat.orzugrand.uz/api/message`, formData, {
				headers: {
					'Content-Type': 'multiple/formdata',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const messageFormHendler = res.data.data;
				setInfo({ text: '' });
				info && info.text.trim();
				getSMS(id);
				// getChats();
			})
			.catch(er => {
				// console.log(er);
			});
		setTimeout(() => setLoadingForm(false), 5000);
	};
	// POST POST MESSAGA FORM  END

	// SEARCH POST MESSAGA FORM START
	const [correspondence, setCorrespondence] = useState(null);
	const [toId, setToId] = useState('');

	useEffect(() => {
		const channel = pusher.subscribe(`message${userInfo && userInfo.id}`);
		channel.bind('message', function (data) {
			getChats();
			{
				data[0].chat_id == id && getSMS(id);
			}
			return () => {
				// Komponent bekor qilinganda Pusher bilan aloqani to'xtatish
				pusher.unsubscribe(`message${userInfo && userInfo.id}`);
			};
		});
		messagesEndRef.current?.scrollIntoView();
	}, [messages]);

	const [down, setDown] = useState(false);
	const [text, setText] = useState({ text: '' });

	const messageFormHendlerSearch = async e => {
		const formData = new FormData();
		formData.append('from', userInfo.id);
		formData.append('text', text.text.trim());
		formData.append('to', toId);

		e.preventDefault();
		setLoadingForm(true);
		await axios
			.post(`https://chat.orzugrand.uz/api/message`, formData, {
				headers: {
					'Content-Type': 'multiple/formdata',
					Accept: 'application/json',
				},
			})
			.then(res => {
				const messageFormHendlerSearch = res.data.data;
				setText({ text: '' });
				text && text.text.trim();
				getChats();
				setLoadingForm(false);
				// getSMS(id);
			})
			.catch(er => {
				// console.log(er);
				// setLoadingForm(true);
			});
		setTimeout(() => setLoadingForm(false), 5000);
	};
	//SEARCH POST POST MESSAGA FORM  END

	// GET USER START
	// const [pending, setPending] = useState(false);
	const [operators, setOperators] = useState([]);

	const getOperators = async () => {
		// setPending(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/user`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res.data.data.data;
				setOperators(operators);
				// setPending(false);
			})
			.catch(er => {
				// setPending(true);
				// console.log(er);
			});
	};

	// GET USER END

	// INPUT SEARCH START
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredData, setFilteredData] = useState([]);

	const handleInputChange = event => {
		const inputValue = event.target.value;
		setSearchTerm(inputValue);

		if (inputValue.trim() == '') {
			setFilteredData([]); // Clear results if input is empty
		} else {
			const matchingData =
				operators &&
				operators.filter(item =>
					userInfo.name == item.name ? null : item.name.toLowerCase().includes(inputValue.toLowerCase())
				);
			setFilteredData(matchingData && matchingData);
		}
	};
	// INPUT SEARCH END

	// GETMESSAGES PARAMS START
	const [flases, setFalses] = useState(false);
	const [show, setShow] = useState(false);

	const [loaders, setLoaders] = useState(false);
	const [userName, setUserName] = useState('');
	const [userImage, setUserImage] = useState('');
	const [thereIs, setThereIS] = useState(false);

	const getMEssagesParam = async id => {
		setLoaders(true);
		await axios
			.get(`https://chat.orzugrand.uz/api/chat?from=${userInfo && userInfo.id}&to=${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			})
			.then(res => {
				const getMEssagesParam = res.data;
				getMEssagesParam ? setCorrespondence(getMEssagesParam) : setCorrespondence(null);
				setLoaders(false);
			})
			.catch(er => {
				setLoaders(true);
				console.log(er);
			});
	};

	// GETMESSAGES PARAMS END

	useEffect(() => {
		getOperators();
		getChats();
		// getMEssagesParam();
		if (!token) {
			navigate('/auth');
		}
	}, []);

	const [countShow, setCountShow] = useState(true);

	const useRedInputFocus = useRef();
	useRedInputFocus.current?.focus();

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='message-info'>
					<h2 className='font-bold text-3xl text-[#464255] mb-2'>Xabar</h2>
					<p className='text-[#A3A3A3] font-medium text-base'>
						Xush kelibsiz <span className='text-[#2d9cdb]'>{userInfo && userInfo.name}</span>! Bu erda xodimlar{' '}
						<br className='2xl:hidden block' /> va rahbarlar bilan suhbat savollar berishingiz va so'rashingiz mumkin.
					</p>
				</div>
				<Header />
			</header>
			{/* HEADER END */}

			<div className='mx-4 message pb-20'>
				<div className='message-wrapper'>
					{/* LEFT START */}
					<div className='bg-white border border-[#DCDCDC] rounded-xl overflow-y-auto wrapper px-[3px] py-5'>
						{isLoading && <Spinner />}
						{/* SEARCH START */}
						<div className='py-3 flex items-center justify-between bg-[#F3F2F7] rounded-xl mx-3 px-5 mb-3 '>
							<input
								className='text-black font-medium text-xs w-[90%] bg-transparent'
								type='search'
								value={searchTerm}
								onChange={handleInputChange}
								placeholder='Qidirmoq'
							/>
							<img src={topSearch} alt='search icon' />
						</div>
						{filteredData && filteredData.length == 0 ? (
							searchTerm && searchTerm ? (
								<p className='ml-3 text-[#8F8989]'>Foydalanuvchi topilmadi</p>
							) : null
						) : (
							<div className='py-3'>
								<>
									{filteredData &&
										filteredData.map(item => (
											<div
												key={item.id}
												className={`hover:bg-[#F6F6F6] hover:rounded-[15px] pt-5 ${
													active == item.id ? 'bg-[#f6f6f6] rounded-[15px]' : ''
												}`}
												onClick={() => {
													getMEssagesParam(item.id);
													setTo(null);
													setFalses(true);
													setDown(true);
													setUserName(item.name);
													setUserImage(item.image);
													setThereIS(true);
													setToId(item.id);
													setShow(false);
													handleActive(item.id);
													setShow(true);
												}}>
												<div className=' mx-3  cursor-pointer '>
													<div className='flex items-center mb-5'>
														<img
															className='rounded-full mr-4 2xl:w-[55px] 2xl:h-[55px] w-9 h-9 object-cover '
															src={item.image ? `${imageUrl}/${item.image}` : defaultIcon}
															alt='chats user img'
															width={55}
															height={55}
														/>
														<div className=' mr-auto'>
															<h2 className='text-[#464255] 2xl:text-base text-sm 2xl:font-semibold font-medium mb-2'>
																{item.name.length > 21 ? item.name.slice(0, 21) + '...' : item.name}
															</h2>
														</div>
													</div>
													<p className='text-[#F1F1F1] border border-solid  border-[#f6f6f6]'></p>
												</div>
											</div>
										))}
								</>
								<h2 className='text-center mt-3 text-[#464255] text-base font-bold'>Eski yozishmalar 👇</h2>
							</div>
						)}
						{/* SEARCH END */}

						{/* getCHarts MAP START */}
						{chats &&
							chats.map(chat => {
								return (
									<>
										<div
											key={chat.id}
											className={`hover:bg-[#F6F6F6] hover:rounded-[15px] pt-5 ${
												selectedId == chat.id ? 'bg-[#f6f6f6] rounded-[15px]' : ''
											}`}
											onClick={() => {
												handleItemClick(chat.id);
												setUserId(chat.user_id);
												setTo(chat);
												setID(chat.id);
												getSMS(chat.id);
												setChatId(chat.id);
												setFalses(true);
												setDown(false);
												setThereIS(true);
												setShow(true);
												setShow(false);
												setInfo({ text: '' });
											}}>
											<div className=' mx-3  cursor-pointer '>
												<div className='flex items-center mb-5'>
													<img
														className='rounded-full mr-4 2xl:w-[55px] 2xl:h-[55px] w-9 h-9 object-cover'
														src={chat.image ? `${imageUrl}/${chat.image}` : `${defaultIcon}`}
														alt='chats user img'
														width={55}
														height={55}
													/>
													<div className=' mr-auto'>
														<h2 className='text-[#464255] 2xl:text-base text-sm 2xl:font-semibold font-medium mb-2'>
															{chat.name}
														</h2>

														<p className='text-[#8F8989] 2xl:text-xs text-xs 2xl:font-medium font-normal'>
															{chat.text.length > 25 ? chat.text.slice(0, 25) + '...' : chat.text}
														</p>
													</div>
													<div className='flex flex-col justify-center items-center'>
														<h3 className='text-[#7684B6] 2xl:font-medium font-normal 2xl:text-sm text-xs'>
															{chat.time.slice(11, 16)}
															{/* {chat.messages[chat.messages.length - 1].created_at.slice(11, 16)} */}
														</h3>
														{chat.reader == userInfo.id && countShow
															? countShow && (
																	<p
																		className={`text-xs font-medium text-white bg-[#7684B6] w-5 h-5 rounded-full flex items-center justify-center `}>
																		{chat.count}
																	</p>
															  )
															: null}
													</div>
												</div>
												<p className='text-[#F1F1F1] border border-solid  border-[#f6f6f6]'></p>
											</div>
										</div>
									</>
								);
							})}
						{/* getCHarts MAP START */}
					</div>
					{/* LEFT END */}

					{/* RIGTH START */}
					<div
						className={`bg-white pb-4 pt-[3px] px-[3px] relative  border border-[#DCDCDC] rounded-xl  ${
							thereIs && thereIs ? 'block' : 'message-to'
						} relative`}>
						{/* MESAGGE TOP START */}
						{to && (
							<div className='bg-[#E9E6F2] rounded-xl py-4 pl-6 flex items-center '>
								<img
									className='header__img mr-4 2xl:w-[55px] 2xl:h-[55px] w-9 h-9 object-cover'
									src={to.image ? `${imageUrl}/${to.image}` : defaultIcon}
									alt='mijoz img'
									width={55}
									height={55}
								/>
								<div>
									<h2 className='text-[#464255] 2xl:text-base text-sm 2xl:font-semibold font-medium mb-1'>{to.name}</h2>
									<p className='text-[#4F619F] 2xl:font-medium font-normal text-xs'>yaqinda online edi</p>
								</div>
							</div>
						)}
						{/* MESAGGE TOP END */}

						{/* MESAGGE TOP START 2 */}
						{down && (
							<div className='bg-[#E9E6F2] rounded-xl py-4 pl-6 flex items-center '>
								<img
									className='header__img mr-4 2xl:w-[55px] 2xl:h-[55px] w-9 h-9 object-cover'
									src={userImage ? `${imageUrl}/${userImage}` : defaultIcon}
									alt='mijoz img'
									width={55}
									height={55}
								/>
								<div>
									<h2 className='text-[#464255] 2xl:text-base text-sm 2xl:font-semibold font-medium mb-1'>
										{userName && userName}
									</h2>
									<p className='text-[#4F619F] 2xl:font-medium font-normal text-xs'>yaqinda online edi</p>
								</div>
							</div>
						)}
						{/* MESAGGE TOP END 2 */}

						{/* BOSILMASIDAN OLDIN CHIQADIGON IMG START */}
						{flases ? null : (
							<div className=''>
								<h2 className='text-[#7684B6] 2xl:text-base text-sm 2xl:font-semibold font-medium  mb-14'>
									Kimga yozishni xohlayotganingizni tanlang
								</h2>
								<img src={messageEmpty} alt='' />
							</div>
						)}
						{/* BOSILMASIDAN OLDIN CHIQADIGON IMG END */}

						{/* BOSILMASIDAN OLDIN CHIQADIGON IMG START 2 */}
						{/* {isLoading || (correspondence && correspondence.length >= 0) ? null : (
							<div className='absolute top-[40%] left-[38%]'>
								<h2 className='text-[#7684B6] 2xl:text-base text-sm 2xl:font-semibold font-medium mb-14'>
									Bu yerda hali hech qanday xabar yo'q
								</h2>
								<img src={noPosts} alt='' />
							</div>
						)} */}
						{/* BOSILMASIDAN OLDIN CHIQADIGON IMG END  2*/}

						{/* BOSILGANIDA MESSAGE LAR START */}
						{to && (
							<div className='wrapper overflow-y-auto h-[631px] py-10 flex flex-col'>
								{Array.from(setDate).map((item, index) => (
									<>
										<h2
											key={index}
											className='text-center mb-2 text-[#7684B6] 2xl:text-base text-sm 2xl:font-semibold font-medium '>
											{item}
										</h2>
										{messages &&
											messages.map(message =>
												item == message.created_at.slice(0, 10) ? (
													<>
														<div
															key={message.id}
															id={message.id}
															className={
																userInfo.id != message.author
																	? 'block rounded-t-[20px] rounded-br-[20px] bg-[#D6E5F3] w-[55%] mb-5 ml-4 pt-4 pb-9 pl-7'
																	: 'ml-auto block rounded-t-[20px] rounded-bl-[20px] bg-[#E9E6F2] w-[55%] mb-5 mr-4 py-4 pl-7'
															}>
															<p className='mr-3 2xl:text-sm text-xs 2xl:font-medium font-normal '>{message.text}</p>

															<p className='float-right text-[#7684B6] 2xl:text-sm text-xs 2xl:font-medium font-normal mr-4'>
																{message.created_at.slice(11, 16)}
															</p>
														</div>
													</>
												) : null
											)}
									</>
								))}
								<div ref={messagesEndRef} />
							</div>
						)}
						{/* BOSILGANIDA MESSAGE LAR END */}

						{/* BOSILGANIDA MESSAGE LAR START 2 */}
						{/* {show && correspondence && correspondence.length >= 0 ? (
							<div className='wrapper overflow-y-auto h-[631px] py-10 flex flex-col'>
								{Array.from(setDate).map((item, index) => (
									<>
										<h2
											key={index}
											className='text-center mb-2 text-[#7684B6] 2xl:text-base text-sm 2xl:font-semibold font-medium '>
											{item}
										</h2>
										{messages &&
											messages.map(message =>
												item == message.created_at.slice(0, 10) ? (
													<>
														<div
															key={message.id}
															id={message.id}
															className={
																userInfo.id != message.author
																	? 'block rounded-t-[20px] rounded-br-[20px] bg-[#D6E5F3] w-[55%] mb-5 ml-4 pt-4 pb-9 pl-7'
																	: 'ml-auto block rounded-t-[20px] rounded-bl-[20px] bg-[#E9E6F2] w-[55%] mb-5 mr-4 py-4 pl-7'
															}>
															<p className='mr-3 2xl:text-sm text-xs 2xl:font-medium font-normal '>{message.text}</p>

															<p className='float-right text-[#7684B6] 2xl:text-sm text-xs 2xl:font-medium font-normal mr-4'>
																{message.created_at.slice(11, 16)}
															</p>
														</div>
													</>
												) : null
											)}
									</>
								))}
								<div ref={messagesEndRef} />
							</div>
						) : null} */}
						{/* BOSILGANIDA MESSAGE LAR END 2 */}

						{/* FORM TEXT START */}
						{to && (
							<form onSubmit={messageFormHendler} className='2xl:px-5 px-4  flex items-center justify-between' action=''>
								<input
									className='bg-[#F3F2F8] w-full 2xl:h-[60px] h-14 2xl:mr-4 mr-3 px-3 rounded-xl'
									type='text'
									placeholder='Xabar yozing...'
									value={info.text}
									disabled={loadingForm}
									ref={useRedInputFocus}
									onChange={e => {
										setInfo(prev => {
											return { ...prev, text: e.target.value };
										});
									}}
								/>
								<button
									type='submit'
									className='w-[67px] 2xl:h-[60px] h-14 rounded-xl flex justify-center items-center bg-[#7684B6]'>
									{loadingForm && <div className='custom-loader'></div>}
									{!loadingForm && <img src={messageIconText} alt='' />}
								</button>
							</form>
						)}
						{/* FORM TEXT END */}

						{/* FORM TEXT START 2*/}
						{down && (
							<form
								onSubmit={messageFormHendlerSearch}
								className={`2xl:px-5 px-4  flex items-center justify-between ${
									correspondence ? 'absolute bottom-4 left-0 right-0' : null
								} `}
								action=''>
								<input
									className='bg-[#F3F2F8] w-full 2xl:h-[60px] h-14 2xl:mr-4 mr-3 px-3 rounded-xl'
									type='text'
									placeholder='Xabar yozing...'
									value={text.text}
									onChange={e => {
										setText(prev => {
											return { ...prev, text: e.target.value };
										});
									}}
								/>
								<button
									type='submit'
									className='w-[67px] 2xl:h-[60px] h-14 rounded-xl flex justify-center items-center bg-[#7684B6]'>
									{loadingForm && <div className='custom-loader'></div>}
									{!loadingForm && <img src={messageIconText} alt='' />}
								</button>
							</form>
						)}
						{/* FORM TEXT END 2*/}
					</div>
					{/* RIGHT END */}
				</div>
			</div>
		</>
	);
};

export default Message;
