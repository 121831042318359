import Spinner from './Spinner';
import cound from '../images/coin_line.svg';
import coundTwo from '../images/cound_line2.svg';
import chart from '../images/chart_bar_line.svg';
import defaultIcon from '../images/user.png';
import playIcon from '../images/play.svg';
import pauseIcon from '../images/pause.svg';
import { useRef } from 'react';
import { useState } from 'react';
import dowland from '../images/dowland.svg';
import { Link } from 'react-router-dom';

const SalesHistory = ({ profile, filteredData, isLoading }) => {
	const imageUrl = 'https://crm.orzugrand.uz/images';
	const recordsUrl = 'https://crm.orzugrand.uz/records';

	const [isPlaying, setIsPlaying] = useState(-1);
	const videoTriggers = useRef({});
	const [activeAudio, setActiveAudio] = useState(null);

	const checkPlaying = (e, id) => {
		e.preventDefault();
		const videoTracker = videoTriggers.current[id];
		const isPaused = videoTracker.paused;
		setActiveAudio(id);
		pauseAll();
		if (isPaused) {
			videoTracker.play();
			setIsPlaying(id);
		} else {
			videoTracker.pause();
			setIsPlaying(-1);
		}

		if (activeAudio == id) {
			setActiveAudio(null);
		}
	};

	const handleVideoRefs = (id, videoRef) => {
		videoTriggers.current[id] = videoRef;
	};

	const pauseAll = () => {
		Object.values(videoTriggers.current).forEach(videoTracker => {
			videoTracker.pause();
			videoTracker.currentTime = 0;
		});
	};

	const [calls] = Object.values(profile && profile.calls ? profile.calls : []);

	const [showMoreMap, setShowMoreMap] = useState({});

	// "Ko'proq" tugmasi bosilganda ishlaydigan funksiya
	const handleShowMore = id => {
		setShowMoreMap(prevShowMoreMap => ({
			...prevShowMoreMap,
			[id]: true,
		}));
	};

	return (
		<div>
			{isLoading && <Spinner />}
			<div className='bg-white pt-5 pb-12 px-5 rounded-xl mb-11'>
				{profile && (
					<>
						<div className='flex items-center 2xl:font-semibold font-medium 2xl:text-[20px] text-sm 2xl:mb-10 mb-9'>
							<div className='flex items-center py-3 px-7 bg-[#F2EFFF] rounded-lg mr-8'>
								<h3 className='mr-2 text-[#464255] '>Mijoz:</h3>
								<h3 className='text-[#2D9CDB]'>{profile.name}</h3>
							</div>
							<div className='flex items-center py-3 px-7 bg-[#F2EFFF] rounded-lg mr-auto'>
								<h3 className='mr-2 text-[#464255] '>Mijoz ID:</h3>
								<h3 className='text-[#2D9CDB]'>{profile.id}</h3>
							</div>
							<div className='flex items-center py-3 px-7 bg-[#F2EFFF] rounded-lg '>
								<h3 className='mr-2 text-[#464255] '>Ro'yxatga olingan sana:</h3>
								<h3 className='text-[#2D9CDB]'>{profile.created_at.slice(0, 10)}</h3>
							</div>
						</div>
						{/* header end */}
						<div className='text-[#023047] 2xl:text-[22px] text-xl 2xl:font-bold font-semibold flex items-center 2xl:mb-5 mb-5'>
							<h3 className='mr-2'>Mijoz bilan aloqa tarixi:</h3>
							<h3>{profile.created_at.slice(0, 10)}</h3>
						</div>
					</>
				)}

				{/* MENEJER IMAGE START */}
				<div className='2xl:mb-7 mb-5 flex items-center justify-between'>
					<div className='flex items-center mr-auto'>
						<div className='2xl:mr-5 mr-5  mb-5'>
							<img
								className='rounded-full mb-2 object-cover 2xl:w-24 w-20 2xl:h-24 h-20'
								src={calls && calls[0].user.image != null ? `${imageUrl}/${calls && calls[0].user.image}` : defaultIcon}
								alt={defaultIcon}
								width={90}
								height={90}
							/>
						</div>

						<div className='mb-3'>
							<h3 className='text-[#464255] 2xl:font-semibold font-medium 2xl:text-[22px] text-xl mb-2'>
								{calls && calls[0].user.name}
							</h3>
							<h3 className='text-[#A3A3A3] 2xl:font-medium 2xl:text-lg mb-3'>Shahar: {calls && calls[0].user.city}</h3>
							{/* icon */}
							<div className='flex'>
								<div className='w-9 h-9 bg-[#00B074] rounded-full 2xl:mr-5 mr-5 flex justify-center items-center'>
									<img src={cound} alt='' />
								</div>
								<div className='w-9 h-9 bg-[#2D9CDB] rounded-full 2xl:mr-5 mr-5 flex justify-center items-center'>
									<img src={coundTwo} alt='' />
								</div>
								<div className='w-9 h-9 bg-[#FF8D8D] rounded-full flex justify-center items-center'>
									<img src={chart} alt='' />
								</div>
							</div>
							{/* icon end */}
						</div>
					</div>

					<div>
						<h3 className='text-[#464255] 2xl:font-semibold font-medium 2xl:text-[22px] text-2xl'>
							Lavozim: {calls && calls[0].user.is_admin == 1 ? 'Savdo Admini' : 'Savdo menejeri'}
						</h3>
					</div>
				</div>
				{/* MENEJER IMAGE END */}
				<div className='overflow-y-scroll max-h-96 h-full wrapper'>
					<div className='grid-container'>
						<div className='grid-item bg-[#F2EFFF] text-[#464255] 2xl:text-[22px] text-[18px] rounded-tl-xl'>
							Qo'ng'iroqni tinglash
						</div>
						<div className='grid-item bg-[#F2EFFF] text-[#464255] 2xl:text-[22px] text-[18px]'>Qo'ng'iroq sanasi</div>
						<div className='grid-item bg-[#F2EFFF] text-[#464255] 2xl:text-[22px] text-[18px]'>Holat</div>
						<div className='grid-item bg-[#F2EFFF] text-[#464255] 2xl:text-[22px] text-[18px] rounded-tr-xl'>Izoh</div>
					</div>
					{filteredData &&
						filteredData.map((data, index) => {
							return (
								<div key={data.id}>
									{/* INFO START */}
									<div className='grid-container'>
										<div className='grid-item'>
											<div className='flex'>
												<Link
													className='mr-3 cursor-pointer  2xl:w-[36px] w-[32px] 2xl:h-[36px] h-[32px] bg-[#D9D9D9] rounded-[10px] flex items-center justify-center'
													to={`https://crm.orzugrand.uz/api/download/${data.record}`}>
													<img className='w-5 h-5' src={dowland} alt='dowland' />
												</Link>

												<div className='mr-3'>
													<div
														className=' cursor-pointer 2xl:w-[36px] w-[32px] 2xl:h-[36px] h-[32px] bg-[#D9D9D9] rounded-full flex items-center justify-center mr-2'
														onClick={e => checkPlaying(e, data.id)}>
														{isPlaying && isPlaying == data.id ? (
															<img className='w-4 h-4' src={pauseIcon} alt='action icon' />
														) : (
															<img className='w-4 h-4' src={playIcon} alt='action icon' />
														)}
													</div>
													<audio ref={ref => handleVideoRefs(data.id, ref)} src={`${recordsUrl}/${data.record}`}></audio>
												</div>
												<div className='text-base 2xl:text-lg text-[#6F6767]'>Yozib olish {index + 1}</div>
											</div>
										</div>
										<div className='grid-item text-base text-[#6F6767]'>{data.date}</div>
										<div
											className={`grid-item text-base ${data.status_id == 1 && 'text-[#2D9CDB]'}  ${
												data.status_id == 2 && 'text-[#00B074]'
											}  ${data.status_id == 3 && 'text-[#EA2121]'}`}>
											{/* {data.status ? data.status : '-'} */}
											{data.status == 1
												? "O'ylab ko'rishini ayti"
												: null || data.status == 2
												? "Telefoni ko'tarmadi"
												: null || data.status == 3
												? 'Kelib sotib oling dedi'
												: null || data.status == 4
												? '10% chegirma'
												: null || data.status == 5
												? '15% chegirma'
												: null || data.status == 6
												? 'Bizdan mahsulot sotib oldik'
												: null || data.status == 7
												? 'Mijoz rad edi'
												: null}
										</div>

										<div className='grid-item text-base text-[#6F6767]'>
											{showMoreMap[data.id] ? (
												data.comment // Agar tugma bosilsa, barcha matn chiqadi
											) : (
												<div className='flex'>
													<p>
														{data.comment.slice(0, 50)} {data.comment.length > 51 ? '...' : null}{' '}
														{data.comment.length > 51 ? (
															<button className='text-xs text-teal-400' onClick={() => handleShowMore(data.id)}>
																More
															</button>
														) : null}
													</p>
												</div>
											)}
										</div>
									</div>
									{/* INFO END */}
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default SalesHistory;
