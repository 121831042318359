import 'normalize.css';
import './scss/fonts.scss';
import './scss/global.scss';
import './scss/style.scss';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
	AddClients,
	Auth,
	CallSchedule,
	Calls,
	ClientMoreInfo,
	CreateUser,
	Main,
	ManagerCard,
	Message,
	Operator,
	OperatorProfile,
	PageNotFound,
	ProfileSettings,
	Reminder,
	Stats,
} from './pages';
import Personals from './pages/personals';
import { CreateCustomers, SalesHistory } from './components';
import ClientInfo from './components/ClientInfo';
import Pusher from 'pusher-js';

function App() {
	const pusher = new Pusher('fd7742683dadae1ce63c', {
		cluster: 'ap2',
	});
	return (
		<Router>
			<div className='App'>
				<Sidebar />
				<div className='main'>
					<Routes>
						<Route path='/' exact element={<Main />} />
						<Route path='/stats' element={<Stats />} />
						<Route path='/operator' element={<Operator />} />
						<Route path='/auth' element={<Auth />} />
						<Route path='/add-clients' element={<AddClients />} />
						<Route path='/calls' element={<Calls />} />
						<Route path='/create-user' element={<CreateUser />} />
						<Route path='/operator-profile/:id' element={<OperatorProfile />} />
						<Route path='/clientmoreinfo/:id' element={<ClientMoreInfo />} />
						<Route path='/clientinfo/:id' element={<ClientInfo />} />
						<Route path='/calls-schedule' element={<CallSchedule />} />
						<Route path='/reminder' element={<Reminder />} />
						<Route path='/personals' element={<Personals />} />
						<Route path='/managerCard/:id' element={<ManagerCard />} />
						<Route path='/profile-settings' element={<ProfileSettings />} />
						<Route path='/salesHistory' element={<SalesHistory />} />
						<Route path='/createcustomers' element={<CreateCustomers />} />
						<Route path='/message' element={<Message pusher={pusher} />} />
						<Route path='*' element={<PageNotFound />} />
					</Routes>
				</div>
			</div>
		</Router>
	);
}

export default App;
