import Spinner from './Spinner';
import { Alert, Button, Snackbar, Stack } from '@mui/material';
import { useState } from 'react';

const ClientCard = ({ profile, isLoading }) => {
	const handleCopyClick = text => {
		const textarea = document.createElement('textarea');
		textarea.value = text;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
	};

	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<div>
				{isLoading && <Spinner />}
				{profile && (
					<div className='bg-white rounded-xl 2xl:py-12 py-10 2xl:px-14 px-12 2xl:mb-14 mb-12'>
						{/* 1 */}
						<div className='flex justify-between mb-5 px-7'>
							<h2 className='text-xl font-medium text-[#464255]'>Mijoz:</h2>
							<div className='flex items-center justify-between'>
								<div className='text-[#20719A] text-base font-medium value mr-6'>{profile.name}</div>
								<div className='rounded-md cursor-pointer'>
									<Stack>
										<Button
											style={{
												fontWeight: '600',
												fontSize: '16px',
												color: '#0F845C',
												padding: '0px 14px',
												backgroundColor: '#BCEED6',
											}}
											onClick={() => {
												handleClick();
												handleCopyClick(profile.name);
											}}>
											Copy
										</Button>
										<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
											<Alert onClose={handleClose}>Nusxa olindi!</Alert>
										</Snackbar>
									</Stack>
								</div>
							</div>
						</div>

						{/* 2 */}
						<div className='bg-[#F3F2F7] rounded-lg py-6 px-7 flex mb-6'>
							<h2 className='text-xl font-medium text-[#464255] mr-auto'>Telefon raqami:</h2>
							<div className='flex items-center justify-between'>
								<div className='mr-8 flex items-center'>
									<div className='text-[#20719A] text-base font-medium value mr-6'>{profile.phone1}</div>
									<div className='rounded-md cursor-pointer'>
										<Stack>
											<Button
												style={{
													fontWeight: '600',
													fontSize: '16px',
													color: '#0F845C',
													padding: '0px 14px',
													backgroundColor: '#BCEED6',
												}}
												onClick={() => {
													handleClick();
													handleCopyClick(profile.phone1);
												}}>
												Copy
											</Button>
											<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
												<Alert onClose={handleClose}>Nusxa olindi!</Alert>
											</Snackbar>
										</Stack>
									</div>
								</div>
							</div>
							<div className='flex items-center'>
								<div className='text-[#20719A] text-base font-medium value mr-6'>{profile.phone2}</div>
								<div className='rounded-md cursor-pointer'>
									<Stack>
										<Button
											style={{
												fontWeight: '600',
												fontSize: '16px',
												color: '#0F845C',
												padding: '0px 14px',
												backgroundColor: '#BCEED6',
											}}
											onClick={() => {
												handleClick();
												handleCopyClick(profile.phone2);
											}}>
											Copy
										</Button>
										<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
											<Alert onClose={handleClose}>Nusxa olindi!</Alert>
										</Snackbar>
									</Stack>
								</div>
							</div>
						</div>

						{/* 3 */}
						<div className='px-7 mb-6 flex items-center justify-between'>
							<h2 className='text-xl font-medium text-[#464255]'>Mijoz ID:</h2>
							<div className='flex items-center'>
								<div className='text-[#20719A] text-base font-medium value mr-6'>{profile.id}</div>
								<div className='rounded-md cursor-pointer'>
									<Stack>
										<Button
											style={{
												fontWeight: '600',
												fontSize: '16px',
												color: '#0F845C',
												padding: '0px 14px',
												backgroundColor: '#BCEED6',
											}}
											onClick={() => {
												handleClick();
												handleCopyClick(profile.id);
											}}>
											Copy
										</Button>
										<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
											<Alert onClose={handleClose}>Nusxa olindi!</Alert>
										</Snackbar>
									</Stack>
								</div>
							</div>
						</div>

						{/* 4 */}
						<div className='bg-[#F3F2F7] rounded-lg py-6 px-7 flex mb-6'>
							<h2 className='text-xl font-medium text-[#464255] mr-auto'>Mijozning shahri va manzili:</h2>
							<div className='flex items-center justify-between'>
								<div className='mr-8 flex items-center'>
									<div className='text-[#20719A] text-base font-medium'>{profile && profile.address ? profile.address : '-'}</div>
									<div className='visited:hidden opacity-0 text-[#0F845C] text-lg font-semibold py-1 px-5 bg-[#BCEED6] rounded-md '>
										Copy
									</div>
								</div>
							</div>
						</div>

						{/* 4 */}
						<div className=' rounded-lg py-6 px-7 flex mb-6'>
							<h2 className='text-xl font-medium text-[#464255] mr-auto'>Ro'yxatga olingan sana:</h2>
							<div className='flex items-center justify-between'>
								<div className='mr-8 flex items-center'>
									<div className='text-[#20719A] text-base value font-medium'>{profile && profile.created_at.slice(0, 10)}</div>
									<div className='visited:hidden opacity-0 text-[#0F845C] text-lg font-semibold py-1 px-5 bg-[#BCEED6] rounded-md '>
										Copy
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className='copied'>Bu yerga saqlandi!</div>
			</div>
		</>
	);
};

export default ClientCard;
