import storage from 'redux-persist/lib/storage';

const initialState = {
	userInfo: null,
	key: 'root',
	storage,
};

const userChanger = (state = initialState, action) => {
	switch (action.type) {
		case 'SEND_USER':
			return { ...state, userInfo: action.payload };
		case 'UPDATE_USER':
			return { ...state, userInfo: { ...action.payload } };
		default:
			return state;
	}
};

export default userChanger;
