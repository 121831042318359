import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import defaultIcon from '../images/user.png';
import topSearch from '../images/top-search.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Header } from '../components';
import searchIcon from '../images/search-icon.svg';

const Personals = () => {
	const navigateAuth = useNavigate();
	const token = Cookies.get('token');
	const [operators, setOperators] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [filteredContacts, setFilteredContacts] = useState([]);
	const [searchById, setSearchById] = useState('');
	const [searchByNameNum, setSearchByNameNum] = useState('');

	useEffect(() => {
		if (!token) {
			navigateAuth('/auth');
		}
		getOperators();
	}, []);

	useEffect(() => {
		const filteredById = operators.filter(contact => contact.city.toLowerCase().includes(searchById));
		const filteredByNameNum = operators.filter(
			contact =>
				contact.name.toLowerCase().includes(searchByNameNum.toLowerCase()) || contact.id.toString().includes(searchByNameNum)
		);

		if (searchById) {
			setFilteredContacts(filteredById);
		} else {
			setFilteredContacts(filteredByNameNum);
		}
	}, [operators, searchById, searchByNameNum]);

	const imageUrl = 'https://crm.orzugrand.uz/images';

	const getOperators = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/user`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res.data.data.data;
				setOperators(operators);
				setFilteredContacts(operators);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input
						type='search'
						name='search'
						value={searchById}
						onChange={e => setSearchById(e.target.value)}
						placeholder="Xodimlarni joylashuvi bo'yicha qidirish"
					/>
					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<div className='2xl:pb-36 pb-28 2xl:pt-9 pt-7'>
				<div className='mb-12 flex justify-between items-center'>
					<div>
						<h2 className='text-[#464255] 2xl:text-3xl text-2xl font-bold mb-2'>Xodimlar</h2>
						<p className='text-[#A3A3A3] 2xl:text-lg text-base font-medium'>Xodimlarning umumiy ro'yxati</p>
					</div>
					<div className='flex items-center'>
						<div className='mr-4'>
							<h2 className='tetx-[#000000] font-semibold'>Filtr:</h2>
						</div>
						<div className='top__wrapper'>
							<input
								className='font-semibold text-xs w-64 bg-white  py-3 pl-5 pr-10 rounded-xl'
								type='search'
								value={searchByNameNum}
								onChange={e => setSearchByNameNum(e.target.value)}
								placeholder="Xodimlar bo'yicha qidiruv"
							/>
							<img className='w-4 h-4 object-cover' src={topSearch} alt='search icon' />
						</div>
					</div>
				</div>
				<div className='operator'>
					{isLoading && <Spinner />}
					{filteredContacts &&
						filteredContacts.map((data, index) => (
							<Link to={`/managerCard/${data.id}`} className='operator__item' key={index}>
								{' '}
								<div className='operator__head'>
									<img
										className='operator__avi'
										src={data.image != null ? `${imageUrl}/${data.image}` : defaultIcon}
										alt={defaultIcon}
									/>
									<div className='operator__info'>
										<div className='operator__name'>{data.name}</div>
										<div className='operator__collector '>Joylashuv: {data.city}</div>
										<div className='operator__stats'>Qo'ng'iroqlar statistikasi</div>
									</div>
									<div className='operator__rank'>
										{data.is_admin == 1 ? <h2 className='text-[#2D9CDB] '>ROP</h2> : <h2 className='text-[#A3A3A3] '>Menejer</h2>}
									</div>
								</div>
								<div className='operator__plan'>
									<div className='operator__box orange'>
										<div className='operator__circle'>{data.limit}</div>
										<div className='operator__completed'>Bugunga rejalashtirilgan qo&apos;ng'iroqlar</div>
									</div>

									<div className='operator__box green'>
										<div className='operator__circle'>{data.called}</div>
										<div className='operator__completed'>Bugungi qo&apos;ng'roqlar soni</div>
									</div>
								</div>
							</Link>
						))}
				</div>
			</div>
		</>
	);
};

export default Personals;
