import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Return from '../components/Return';
import ClientCard from '../components/ClientCard';
import { Header } from '../components';

const ClientMoreInfo = () => {
	const navigateAuth = useNavigate();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const token = Cookies.get('token');
	const [down, setDown] = useState(1);

	useEffect(() => {
		if (!token) {
			navigateAuth('/auth');
		}
		getProfile();
	}, []);

	const [profile, setProfile] = useState();
	const getProfile = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/client/${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const profile = res.data.data;
				setProfile(profile);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'></div>
				<Header />
			</header>
			{/* HEADER END */}
			<Return title='Jadvalga qaytish' navigate={-1} />
			<div className='max-w-5xl w-full mb-14'>
				<h2 className='text-[#464255] text-[28px] font-bold'>Mijoz kartasi</h2>
				<p className='greytitle'>
					Bu erda siz mijoz haqida batafsil ma'lumot olishingiz va menejerning ishi bilan batafsil tanishishingiz va butun voqeani
					o'qishingiz mumkin. ushbu mijoz uchun va qo'ng'iroq yozuvlari va sharhlarini tinglang va mijoz bilan aloqaning butun
					tarixini kuzatib boring.
				</p>
			</div>
			<div className=''>
				<div className='flex items-center mb-12'>
					<div
						onClick={() => {
							setDown(1);
						}}
						className={
							down == 1
								? ' mr-4 py-2 px-5 bg-[#FB8500] text-white rounded-[10px] font-medium text-lg leading-[21.6px] border-[1px] border-transparent  '
								: 'mr-4 py-2 px-5 bg-white text-black rounded-[10px] font-medium text-lg leading-[21.6px] hover:opacity-80'
						}>
						<p>Mijoz kartasi</p>
					</div>
				</div>

				{down == 1 ? <ClientCard profile={profile} isLoading={isLoading} /> : null}

				<Return title='Jadvalga qaytish' classes='second-view' navigate={-1} />
			</div>
		</>
	);
};

export default ClientMoreInfo;
