import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import OperatorBox from '../components/OperatorBox';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import dowlandIcon from '../images/dowland-icon.svg';
import { Header, Spinner } from '../components';
import searchIcon from '../images/search-icon.svg';
import topArrow from '../images/top-arrow.svg';

ChartJS.register(ArcElement, Tooltip, Legend, PointElement, CategoryScale, LinearScale, PointElement, LineElement, Title);

const selectMonths = [
	{ id: 1, name: '1 kunlikni yuklash' },
	{ id: 2, name: '1 oylikni yuklash' },
];

const Main = () => {
	const userInfo = JSON.parse(localStorage.getItem('user'));
	const token = Cookies.get('token');
	const navigate = useNavigate();

	const [stats, setStats] = useState();
	useEffect(() => {
		getStats();
		if (!token) {
			navigate('/auth');
		}
	}, []);

	const [totalCall, setTotalCall] = useState();
	const [analitic, setAnalitic] = useState({});
	const [statsMonth, setStatsMonth] = useState([]);
	const [chartMonth, setChartMonth] = useState('');
	const [monthlyValue, setMonthlyValue] = useState(0);
	const [weeklyValue, setWeeklyValue] = useState(0);
	const [dailyValue, setDailyValue] = useState(0);

	const getStats = async () => {
		await axios
			.post('https://crm.orzugrand.uz/api/home', [], {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const user = res.data.data;
				setAnalitic(user && user);
				setChartMonth(user.doughnut_chart);
				setMonthlyValue(Math.min(user.doughnut_chart.monthly, user.doughnut_chart.monthly * 300));
				setWeeklyValue(Math.min(user.doughnut_chart.weekly, user.doughnut_chart.monthly * 300));
				setDailyValue(Math.min(user.doughnut_chart.day, user.doughnut_chart.monthly * 300));

				setStats(user);
				const lineArray = [];
				const lineArrayCalls = [];

				user.line_chart.map(line => {
					lineArray.push(line.month);
					lineArrayCalls.push(line.total_call);
					setStatsMonth(lineArray);
					setTotalCall(lineArrayCalls);
				});
			})
			.catch(er => {
				// console.log(er);
			});
	};

	const lineData = {
		labels: statsMonth.reverse(),
		datasets: [
			{
				data: totalCall,
				borderColor: '#2D9CDB',

				fill: false,
				options: {
					responsive: false,
					borderWidth: false,
					legend: {
						display: false,
					},
					tooltips: {
						enabled: false,
					},
				},
			},
		],
	};

	const [selectMonth, setSelectMonths] = useState('');
	const [selectMontDown, setSelectMontDown] = useState(false);
	const [selectXLS, setSelectXLS] = useState(1);

	const onDays = (e, id) => {
		setSelectMonths(e);
		setSelectXLS(id);
	};

	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState();
	const [perPage, setPerPage] = useState();

	const change = (event, value) => {
		setPage(value);
	};

	useEffect(() => {
		getOperators();
	}, [page]);
	const [operators, setOperators] = useState('');

	const getOperators = async () => {
		setIsLoading(true);
		await axios
			.post(`https://crm.orzugrand.uz/api/home?page=${page}`, [], {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res.data.data;
				const operatorss = operators.operators.data;
				const b = operators.operators;
				setTotal(b.total);
				setPerPage(b.per_page);
				setOperators(operatorss);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	const [inputValue, setInputValue] = useState('');
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		// Filter the data based on the input value whenever the input value changes
		const filtered =
			operators &&
			operators.filter(
				item =>
					item.name.toLowerCase().includes(inputValue.toLowerCase()) || item.city.toLowerCase().includes(inputValue.toLowerCase())
			);

		setFilteredData(operators && filtered);
	}, [inputValue, operators]);

	const handleInputChange = event => {
		const { value } = event.target;
		setInputValue(value);
	};

	// -------------- 1 -------------------
	const remainingValue = 1 * 300 - monthlyValue;
	const chartData = {
		datasets: [
			{
				data: [monthlyValue, remainingValue],
				backgroundColor: ['#ffc480', 'rgba(255, 91, 91, 0.15)'],
			},
		],
	};

	// ----------------- 2 ------------------
	const remainingValue2 = 1 * 300 - weeklyValue;
	const chartData2 = {
		datasets: [
			{
				data: [weeklyValue, remainingValue2],
				backgroundColor: ['#00B074', 'rgba(0, 176, 116, 0.15)'],
			},
		],
	};

	// ----------------- 3 ------------------
	const remainingValue3 = 1 * 300 - dailyValue;
	const chartData3 = {
		datasets: [
			{
				data: [dailyValue, remainingValue3],
				backgroundColor: ['#2D9CDB', 'rgba(45, 156, 219, 0.15)'],
			},
		],
	};

	const canvasWidth = 153; // Istalgan o'lchamni tanlang
	const canvasHeight = 153; // Istalgan o'lchamni tanlang

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input
						type='search'
						name='search'
						value={inputValue}
						onChange={handleInputChange}
						placeholder="Shahar bo'yicha ishchilar qidirish"
					/>
					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<div className='content'>
				<div className='head'>
					<h2 className='title'>Asosiy sahifa</h2>
					<div className='2xl:font-medium font-normal 2xl:text-lg text-base'>
						<span className='text-[#A3A3A3]'>Xush Kelibsiz,</span>{' '}
						<span className='text-[#2D9CDB]'>{userInfo && userInfo.name}</span>
					</div>
				</div>

				{isLoading && <Spinner />}
				<div className='stats'>
					<div className='stats__item'>
						<div className='stats__top'>
							<h3 className='minititle'>Qo&apos;ng'iroqlarning umumiy soni</h3>
							{/* <div className='more'>
								<img className='more__trigger' src={dotsIcon} alt='dots icon' />
								<div className='more__dropdown'>dsadasdasdas</div>
							</div> */}
						</div>

						<div className=' flex flex-col justify-around'>
							<div className='mb-5 opacity-0'>eferwfirjherijn1</div>

							<div className='stats__content stats__content_two stats__content_two2 '>
								<div className='stats__chars '>
									<div className='stats__graph' style={{ width: '153px', height: '153px' }}>
										<Doughnut
											data={chartData}
											width={canvasWidth}
											height={canvasHeight}
											options={{ responsive: true, borderWidth: false }}
										/>
										<div className='stats__per'>{chartMonth.monthly}</div>
									</div>
									<div className='stats__name'>Oy</div>
								</div>

								<div className='stats__chars'>
									<div className='stats__graph' style={{ width: '153px', height: '153px' }}>
										<Doughnut
											data={chartData2}
											width={canvasWidth}
											height={canvasHeight}
											options={{ responsive: true, borderWidth: false }}
										/>
										<div className='stats__per'>{chartMonth.weekly}</div>
									</div>
									<div className='stats__name'>7 Kun</div>
								</div>

								<div className='stats__chars '>
									<div className='stats__graph' style={{ width: '153px', height: '153px' }}>
										<Doughnut
											data={chartData3}
											width={canvasWidth}
											height={canvasHeight}
											options={{ responsive: true, borderWidth: false }}
										/>
										<div className='stats__per'>{chartMonth.day}</div>
									</div>
									<div className='stats__name'>1 Kun</div>
								</div>
							</div>
						</div>
					</div>

					<div className='stats__item'>
						<div className='stats__top'>
							<h3 className='minititle'>Butun jamoaning oylar bo'yicha ko'rsatkichlari</h3>
							<div className='flex flex-col'>
								{/* DAYS start */}
								<div
									className=' relative top__box analitica-calendar cursor-pointer mt-2  flex justify-between mb-3'
									style={{ paddingTop: '15px', paddingBottom: '15px' }}
									onClick={() => setSelectMontDown(!selectMontDown)}>
									<div className=' mr-3 '>{selectMonth && selectMonth ? selectMonth : 'Kunlik yoki Oylik'}</div>

									<img className='2xl:w-5 w-3 2xl:h-5 h-3 object-cover' src={topArrow} alt='arrow icon' />
									{selectMontDown && (
										<div className='w-full text-center absolute top-16 right-0 z-50 shadow g-cyan-500  shadow-cyan-500/50'>
											{selectMonths &&
												selectMonths.map(days => {
													return (
														<div className='border-b border-neutral-600 mb-4' key={days.id}>
															<h2
																onClick={() => {
																	onDays(days.name, days.id);
																}}
																className='mb-1 text-center 2xl:mr-3 mr-2 2xl:text-base text-sm font-medium cursor-pointer'>
																{days.name}
															</h2>
														</div>
													);
												})}
										</div>
									)}
								</div>
								{/* DAYS end */}
								<Link to={`https://crm.orzugrand.uz/api/downloadExcel/${selectXLS}`}>
									<button className='2xl:font-bold font-semibold 2xl:text-lg text-base cursor-pointer flex justify-between items-center text-[#2D9CDB] border-[1px] border-[#2D9CDB] rounded-xl py-3 px-5 hover:text-[#2d9bdbc5]'>
										<img className='2xl:mr-3 mr-2  2xl:w-6 w-5 2xl:h-6 h-5 object-cover' src={dowlandIcon} alt='' /> Yuklab olish
									</button>
								</Link>
							</div>
						</div>

						<div className='stats__chars'>
							<div className='stats__graph2'>
								<Line data={lineData} />
							</div>
						</div>
					</div>
				</div>

				<div className='flex justify-between items-center mb-6'>
					<div>
						<h2 className='title'>Menejerlar</h2>
						<div className='greytitle'>Har bir shahar uchun xodimlarning umumiy soni</div>
					</div>
					<div>
						<Link
							to={'/personals'}
							className='bg-[#FB8500] px-7 py-2 border-[1px] border-transparent text-white rounded-[10px] hover:bg-white hover:text-[#fb8500] hover:border-[#fb8500] transition duration-150 ease-out hover:ease-in'>
							Barcha xodimlar
						</Link>
					</div>
				</div>

				<div className=''>
					<OperatorBox filteredData={filteredData} total={total} perPage={perPage} page={page} change={change} />
				</div>
			</div>
		</>
	);
};

export default Main;
