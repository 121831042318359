import axios from 'axios';
import React, { useEffect, useState } from 'react';
import menejerIcon from '../images/menejer-select.svg';
import topIcon3 from '../images/calendar-icon.svg';
import topArrow from '../images/top-arrow.svg';
import dowland from '../images/yuklash.svg';
import { useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Stack } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const TopMini = props => {
	const token = Cookies.get('token');

	const navigate = useNavigate();
	const sessionStorage = window.sessionStorage;
	const [operatorId, setOperatorId] = useState(null);
	const [isCalenderActive, SetIsCalenderActive] = useState(false);
	const [calenderValues, setCalenderValues] = useState('');

	const [operators, setOperators] = useState();

	const calendarRef = useRef();

	const getOperators = async () => {
		await axios
			.get(`https://crm.orzugrand.uz/api/user`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: props.token,
				},
			})
			.then(res => {
				const operators = res.data.data;
				const menejersData = operators.data;
				setOperators(menejersData);
			})
			.catch(er => {
				// console.log(er);
			});
	};

	const [menejer, setMenejer] = useState();

	const arr = [];
	const menejers = () => {
		operators &&
			operators.forEach(item => {
				return item.is_admin == 0 ? item.is_admin == 0 && (arr.push({ id: item.id, name: item.name }), setMenejer(arr)) : null;
			});
	};

	useEffect(() => {
		getOperators();
		const handleClickOutside = event => {
			if (calendarRef.current && !calendarRef.current.contains(event.target)) {
				SetIsCalenderActive(false);
			}
		};

		window.addEventListener('click', handleClickOutside);

		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const clearItems = () => {
		props.clearCheckedItems();
	};

	const clearSelDate = () => {
		setMenejer([]);
		setCalenderValues(new Date());
	};
	const dateNew = new Date(calenderValues).toLocaleDateString();
	const formatted = dateNew.split('.').reverse().join('-');

	const handleSubmit = async () => {
		const data = {
			clients: props.checkedItems,
			date: formatted,
			user_id: operatorId,
		};
		await axios
			.put(`https://crm.orzugrand.uz/api/client/1`, data, {
				headers: {
					'Content-Type': 'application/json',

					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const creatingUser = res.data.data;
				clearItems();
				clearSelDate();
				props.setChecketCount(0);
				props.checketCount = 0;
			})
			.catch(er => {
				// console.log(er);
			});
	};
	const location = useLocation();

	// SNACKBAR START
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const handleButtonClicks = () => {
		if (!operatorId) {
			setOpenSnackbar(true);
		}

		if (!calenderValues) {
			setOpenSnackbar(true);
		}
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};
	// SNACKBAR END

	return (
		<>
			<div className='2xl:mt-10 mt-9'>
				<div>
					{/* CHECKET XATO */}
					<Stack spacing={2} sx={{ width: '100%' }}>
						<Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
							<Alert severity='error'>Menejer tanlang va Sana kiriting!</Alert>
						</Snackbar>
					</Stack>
				</div>
			</div>
			<div className='flex justify-between items-start 2xl:mb-10 mb-9 topMin'>
				<div className='w-[15%]'>
					<h2 className='title'>Mijozlar</h2>
					<div className='greytitle'>Yuklab olish mijozlar bazasi</div>
				</div>

				<div className='w-[89%] ml-2 flex justify-between items-start'>
					<div className='max-w-[320px] w-full flex flex-col mr-4'>
						{/* MENEJERLARNI TANLASH START */}
						{location.pathname == '/add-clients' ? (
							<div className='flex items-center justify-between mb-3 bg-white text-[#3E4954] p-2 rounded-xl max-w-[320px] w-full'>
								<div className='2xl:w-[34px] w-7  2xl:h-[34px] h-7 bg-[#00B074] rounded-xl flex items-center justify-center 2xl:mr-3 mr-2'>
									<img className='2xl:w-4 w-3 2xl:h-4 h-3 object-cover' src={menejerIcon} alt='' width={16} height={16} />
								</div>
								<div className='top__row'>
									<select
										className='cursor-pointer'
										defaultValue='Выберите'
										onClick={() => menejers()}
										onChange={e => {
											setOperatorId(e.target.value);
											sessionStorage.setItem('operatorId', e.target.value);
										}}>
										<option value=''>Menejerni tanlang</option>
										{menejer &&
											menejer.map((operator, index) => {
												return (
													<option key={index} value={operator.id}>
														{operator.name.slice(0, 20)}
													</option>
												);
											})}
									</select>
								</div>

								<img className='top__arrow 2xl:ml-4 ml-3x' src={topArrow} alt='arrow icon' />
							</div>
						) : null}
						{/* MENEJERLARNI TANLASH END */}
						{location.pathname == '/add-clients' ? (
							<div>
								<h2 className='font-medium text-base text-[#343434]'>Tanlangan: {props.checketCount}</h2>
							</div>
						) : null}

						{/* KO"CHIRIB OLISH START */}
						{location.pathname == '/createcustomers' ? (
							<div className='flex flex-col max-w-[320px] w-full'>
								<div
									onClick={props.handleButtonClick}
									className='flex items-center mb-3 justify-between cursor-pointer bg-white text-[#3E4954] p-2 rounded-xl'>
									{/* <input type='file' className='2xl:py-3 2xl:px-5 py-2 px-4 rounded-[10px] mb-2 top__row'></input> ------------------   */}
									<input
										className='hidden'
										ref={props.fileInputRef}
										type='file'
										onChange={props.handleFileChanges}
										accept='.xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
									/>
									<p className='whitespace-nowrap mr-6'>Import qilish</p>
									<div className='w-[34px] h-[34px] bg-[#00B074] rounded-xl flex items-center justify-center'>
										<img className='2xl:w-6 w-5 2xl:h-6 h-5 object-cover' src={dowland} alt='' width={24} height={24} />
									</div>
								</div>
								<p className='text-[#343434] font-medium text-sm'>{props.excelFile && props.excelFile.name}</p>
							</div>
						) : null}
						{/* KO"CHIRIB OLISH END */}

						{location.pathname == '/createcustomers' ? (
							<div>
								<h2 className='font-medium text-base text-[#343434]'>Yaratilingan: {props.elements}</h2>
							</div>
						) : null}
					</div>

					{/* CALENDAR START */}
					<div
						className='flex items-center bg-white text-[#3E4954] p-2 rounded-xl max-w-[320px] w-full cursor-pointer'
						onClick={() => SetIsCalenderActive(true)}
						ref={calendarRef}>
						<img
							className='top__icon 2xl:mr-6 mr-5 2xl:w-8 w-7 2xl:h-8 h-7 object-cover'
							src={topIcon3}
							alt='top icon'
							width={34}
							height={34}
						/>

						<div className='top__row'>
							{isCalenderActive && (
								<div className='top__calendar'>
									<Calendar onChange={setCalenderValues} value={calenderValues} />
								</div>
							)}
							<div className='top__selected'>
								{new Date(calenderValues).toLocaleDateString() == 'Invalid Date'
									? 'Sanani tanlang'
									: new Date(calenderValues).toLocaleDateString()}
							</div>
						</div>
					</div>
					{/* CALENDAR END */}

					{/* 3 lik */}
					<div className='2xl:ml-6 ml-5 flex justify-center items-center'>
						{location.pathname == '/createcustomers' ? (
							<button
								onClick={() => {
									props.createCustomer();
									props.createCustomerF();
									location.pathname = '/createcustomers';
								}}
								className=' p-2 mr-2 bg-[#FF6501] text-white rounded-xl font-medium 2xl:text-lg text-base'>
								Saqlash
							</button>
						) : (
							<button
								onClick={() => {
									handleSubmit();
									props.handleSnackbarClick();
									handleButtonClicks();
									location.pathname = '/add-clients';
								}}
								className=' p-2 mr-2 bg-[#FF6501] text-white rounded-xl font-medium 2xl:text-lg text-base'>
								Saqlash
							</button>
						)}
						{location.pathname == '/createcustomers' ? (
							<button
								onClick={() => {
									navigate('/add-clients');
								}}
								className=' p-2 mr-2 bg-[#A3A3A3] text-white rounded-xl whitespace-nowrap font-medium 2xl:text-lg text-base'>
								Bekor qilish
							</button>
						) : (
							<button
								onClick={() => {
									clearItems();
									clearSelDate();
									setCalenderValues('');
								}}
								className=' p-2 mr-2 bg-[#A3A3A3] text-white rounded-xl whitespace-nowrap font-medium 2xl:text-lg text-base'>
								Bekor qilish
							</button>
						)}
						{location.pathname == '/createcustomers' ? null : (
							<Link to={'/createcustomers'} className=' p-2 bg-[#00B074] text-white rounded-xl font-medium 2xl:text-lg text-base'>
								Yaratish
							</Link>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TopMini;
