import defaultIcon from '../images/user.png';
import cound from '../images/coin_line.svg';
import coundTwo from '../images/cound_line2.svg';
import chart from '../images/chart_bar_line.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import Return from '../components/Return';
import { Header, Spinner } from '../components';
import searchIcon from '../images/search-icon.svg';

const ManagerCard = () => {
	const navigateAuth = useNavigate();
	const { id } = useParams();

	const token = Cookies.get('token');
	const [operators, setOperators] = useState();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!token) {
			navigateAuth('/auth');
		}
		getOperators();
	}, []);
	const imageUrl = 'https://crm.orzugrand.uz/images';

	const getOperators = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/user/${id}}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res.data.data;
				setOperators(operators);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input type='search' name='search' placeholder='Qidirish' />
					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			{isLoading && <Spinner />}
			<div className='mb-24'>
				<div className='mb-10'>
					<h2 className='text-[#464255] 2xl:text-3xl text-2xl font-bold mb-2'>Menejer kartasi</h2>
					<p className='text-[#A3A3A3] 2xl:text-lg text-base font-medium'>
						{operators && operators.is_admin == 1 ? 'Admin' : 'Menejer'}: {operators && operators.name}
					</p>
				</div>

				{/* manager wrapper */}
				<div className='bg-white 2xl:pt-12 pt-10 2xl:pb-32 pb-24 2xl:px-14 px-11 rounded-lg flex mb-11'>
					{/* portfolio */}
					<div className='w-[20%]'>
						<img
							className='2xl:w-36 2xl:h-36 w-32 h-32 object-cover 2xl:rounded-[50px] rounded-[36px] mb-2'
							src={operators && operators.image ? `${imageUrl}/${operators.image}` : defaultIcon}
							alt={defaultIcon}
							width={150}
							height={150}
						/>
						{operators && operators.is_admin == 0 ? (
							<div className='flex'>
								{operators && operators.called > 50 ? (
									<div className='w-9 h-9 bg-[#00B074] rounded-full 2xl:mr-6 mr-4 flex justify-center items-center'>
										<img src={cound} alt='' />
									</div>
								) : null}
								{operators && operators.called > 100 ? (
									<div className='w-9 h-9 bg-[#2D9CDB] rounded-full 2xl:mr-6 mr-4 flex justify-center items-center'>
										<img src={coundTwo} alt='' />
									</div>
								) : null}
								{operators && operators.called > 500 ? (
									<div className='w-9 h-9 bg-[#FF8D8D] rounded-full flex justify-center items-center'>
										<img src={chart} alt='' />
									</div>
								) : null}
							</div>
						) : null}
					</div>

					{/* manager top */}
					<div className='flex flex-col w-[80%]'>
						<div className='flex justify-between mb-11'>
							{/* 2 */}
							<div className='w-[60%]'>
								<h2 className='2xl:text-2xl text-xl font-bold text-[#464255] 2xl:mb-7 mb-6'>{operators && operators.name}</h2>
								<div className='flex border-b-2 border-[#EBEBEB] pb-3 mb-4'>
									<h3 className='text-[#696380] 2xl:text-xl text-base font-semibold w-[50%]'>Shahar:</h3>
									<h3 className='text-[#3A87B4] 2xl:text-xl text-base 2xl:font-bold font-semibold w-[50%]'>
										{operators && operators.city ? operators.city : '-'}
									</h3>
								</div>

								<div className='flex border-b-2 border-[#EBEBEB] pb-3 mb-4'>
									<h3 className='text-[#696380] 2xl:text-xl text-base font-semibold w-[50%]'>Tug'ilgan kun:</h3>
									<h3 className='text-[#3A87B4] 2xl:text-xl text-base 2xl:font-bold font-semibold w-[50%]'>
										{operators && operators.birthday == 'null' ? '-' : operators && operators.birthday}
									</h3>
								</div>

								<div className='flex border-b-2 border-[#EBEBEB] pb-3 mb-4'>
									<h3 className='text-[#696380] 2xl:text-xl text-base font-semibold w-[50%]'>Lavozim:</h3>
									<h3 className='text-[#3A87B4] 2xl:text-xl text-base 2xl:font-bold font-semibold w-[50%]'>
										{operators && operators.is_admin == 0 ? 'Menejer' : 'ROP'}
									</h3>
								</div>

								<div className='flex border-b-2 border-[#EBEBEB] pb-3 mb-4'>
									<h3 className='text-[#696380] 2xl:text-xl text-base font-semibold w-[50%]'>Manzil:</h3>
									<h3 className='text-[#3A87B4] 2xl:text-xl text-base 2xl:font-bold font-semibold w-[50%]'>
										{operators && operators.address != 'null' ? operators.address : '-'}
									</h3>
								</div>

								<div className='flex border-b-2 border-[#EBEBEB] pb-3 mb-4'>
									<h3 className='text-[#696380] 2xl:text-xl text-base font-semibold w-[50%]'>Orzu Grandda ish tajribasi:</h3>
									<h3 className='text-[#3A87B4] 2xl:text-xl text-base font-semibold w-[50%]'>
										{operators && operators.tajriba ? operators.tajriba : '0'}
									</h3>
								</div>

								{console.log(operators && operators.phone1)}

								<div className='flex border-b-2 border-[#EBEBEB] pb-3 mb-4'>
									<h3 className='text-[#696380] 2xl:text-xl text-base font-semibold w-[50%]'>Telefon raqami:</h3>
									<h3 className='text-[#3A87B4] 2xl:text-xl text-base 2xl:font-bold font-semibold w-[50%]'>
										{operators && operators.phone1 == 'null' ? '-' : operators && operators.phone1}
										<br />
										{operators && operators.phone2 == 'null' ? '-' : operators && operators.phone2}
									</h3>
								</div>

								<div className='flex border-b-2 border-[#EBEBEB] pb-3 '>
									<h3 className='text-[#696380] 2xl:text-xl text-base font-semibold mr-auto'>Elektron manzil:</h3>
									<h3 className='text-[#3A87B4] 2xl:text-xl text-base 2xl:font-bold font-semibold '>
										{operators && operators.email ? operators.email : '-'}
									</h3>
								</div>
							</div>

							{/* 3 */}
							<div className='bg-[#F8F6FF] rounded-[20px]  py-5 2xl:px-8 px-7 w-[35%]'>
								<h2 className='text-[#464255] 2xl:text-[26px] text-xl font-bold text-center mb-7'>Statistika</h2>
								{/* 3.1 */}
								{operators && operators.is_admin == 1 ? null : (
									<div className='flex items-center mb-3'>
										<div className='bg-[#FF6501] text-white  2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
											<span className='2xl:text-[20px] 2xl:font-bold text-base font-semibold'>
												{operators && operators.called}
											</span>
										</div>
										<div>
											<h3 className='2xl:font-bold 2xl:text-xl text-base font-semibold text-[#FF6501]'>
												Bajarildi <br /> qo'ng'iroqlar
											</h3>
										</div>
									</div>
								)}

								{/* 3.2 */}
								{operators && operators.is_admin == 0 ? (
									<div className='flex items-center mb-3'>
										<div className='bg-[#00B074] text-white 2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
											<span className='2xl:text-[20px] 2xl:font-bold text-base font-semibold'>
												{operators && operators.bought}
											</span>
										</div>
										<div>
											<h3 className='2xl:font-bold 2xl:text-xl text-base font-semibold text-[#00B074]'>
												Sotish boshiga <br /> Hamma vaqt
											</h3>
										</div>
									</div>
								) : (
									<div className='flex items-center mb-3'>
										<div className='bg-[#00B074] text-white 2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
											<span className='2xl:text-[20px] 2xl:font-bold text-base font-semibold'>
												{operators && operators.menedgers}
											</span>
										</div>
										<div>
											<h3 className='2xl:font-bold 2xl:text-xl text-base font-semibold text-[#00B074]'>
												Barcha <br /> menedgers soni
											</h3>
										</div>
									</div>
								)}

								{/* 3.3 */}
								<div className='flex items-center '>
									<div className='bg-[#2D9CDB] text-white 2xl:w-20 2xl:h-20 w-16 h-16 rounded-full flex items-center justify-center mr-2'>
										{/* {operators && operators.tajriba} */}
										<span className='2xl:text-[20px] 2xl:font-bold text-base font-semibold'>
											{operators && operators.short_date}
										</span>
									</div>
									<div>
										<h3 className='2xl:font-bold 2xl:text-xl text-base font-semibold text-[#2D9CDB]'>
											Ish tajribasi <br /> Orzu Grandda
										</h3>
									</div>
								</div>
							</div>
						</div>

						{/* manager bottom */}
						<div className='bg-[#F3F8FF] py-9 px-11 rounded-[20px]'>
							<h2 className='text-[#696380] 2xl:text-2xl text-xl font-semibold mb-4'>Men haqimda:</h2>
							<p className='text-[#3A87B4] 2xl:text-xl text-base font-medium'>
								{operators && operators.description != 'null' ? operators.description : '-'}
							</p>
						</div>
					</div>
				</div>

				<Return title='Jadvalga qaytish' classes='second-view' navigate={-1} />
			</div>
		</>
	);
};

export default ManagerCard;
