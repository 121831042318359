const initialState = '';

const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SEND_ADMIN':
			return action.value;

		default:
			return state;
	}
};

export default adminReducer;
