import React, { useEffect } from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import headerIcon from '../images/header-icon.svg';
import textIcon from '../images/text-icon.svg';
import close from '../images/close.svg';
import axios from 'axios';
import Cookies from 'js-cookie';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};
const token = Cookies.get('token');

const ModalForm = props => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// POST NOTIFICATION START
	const [info, setInfo] = useState({ title: '', description: '', time: '', date: '' });

	const notificationSubmit = async e => {
		const formData = new FormData();
		formData.append('title', info.title);
		formData.append('description', info.description);
		formData.append('date', info.date + ' ' + info.time);
		formData.append('client_id', props.clientId);

		e.preventDefault();
		setOpen(false);
		await axios
			.post(`https://crm.orzugrand.uz/api/notification`, formData, {
				headers: {
					'Content-Type': 'multiple/formdata',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const notificationSubmit = res.data.data;
				props.notification();
			})
			.catch(er => {
				// console.log(er);
			});
	};
	// POST NOTIFICATION END

	// NOTIFICATION GET END
	return (
		<div>
			<button
				onClick={handleOpen}
				className={`py-2 px-3 cursor-pointer font-normal text-xs border-[1px] border-transparent bg-[#2D9CDB] text-white rounded-xl ${
					props.disablet == 0
						? 'hover:text-[#2D9CDB] hover:bg-white hover:border-[#2D9CDB]  transition duration-150 ease-out hover:ease-in'
						: null
				} ${props.disablet == 1 ? 'opacity-50' : 'opacity-100'}`}
				disabled={props.disablet == 1}>
				Qo'shmoq
			</button>

			{/* MODAL FORM START */}
			<div>
				<div>
					<Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
						<Box className='flex flex-col justify-center items-center modal-box' sx={style}>
							<img
								className='ml-auto cursor-pointer'
								onClick={() => {
									handleClose();
								}}
								src={close}
								alt='Close icon'
							/>
							<Typography className='modal-title' id='modal-modal-title' variant='h5' component='h5'>
								Eslatma yarating
							</Typography>
							<form onSubmit={notificationSubmit} className='w-full flex flex-col justify-center items-center'>
								<div className='mb-5 w-full'>
									<Box className='flex items-center '>
										<div>
											<Typography className='text-base font-medium text-[#6F6767]'>Eslatma sanasini tanlang:</Typography>
										</div>
										<div className='mr-auto text-base font-medium text-[#6F6767]'>
											<input
												onChange={e => {
													setInfo(prev => {
														return { ...prev, time: e.target.value };
													});
												}}
												className='mx-2 cursor-pointer'
												type='time'
												required
												placeholder='soat'
											/>
										</div>

										<div>
											<input
												className='bg-[#F3F2F7] px-2 py-3 rounded-[10px]  cursor-pointer'
												min={new Date().toISOString().split('T')[0]}
												required
												onChange={e => {
													setInfo(prev => {
														return { ...prev, date: e.target.value };
													});
												}}
												type='date'
												placeholder='Date'
											/>
										</div>
									</Box>
								</div>
								<label className='flex py-2  w-full bg-[#F3F2F7] px-5 rounded-[10px] mb-6'>
									<img className='mr-5' src={headerIcon} alt='header-icon' />
									<input
										onChange={e => {
											setInfo(prev => {
												return { ...prev, title: e.target.value };
											});
										}}
										className='w-full bg-[#F3F2F7] py-1 font-medium text-base'
										type='text'
										name=''
										id=''
										placeholder='Sarlavha'
										required
									/>
								</label>

								<label className='flex py-2  w-full bg-[#F3F2F7] px-5 rounded-[10px] mb-6'>
									<img className='mr-3' src={textIcon} alt='header-icon' />
									<h2>{props.nameCLient}</h2>
								</label>

								<textarea
									onChange={e => {
										setInfo(prev => {
											return { ...prev, description: e.target.value };
										});
									}}
									className='bg-[#F3F2F7] px-9 py-6 w-full rounded-[10px] resize-none mb-6'
									name=''
									id=''
									rows='7'
									required
									placeholder="Ertaga qo'ng'iroq qiling va eslatish uchun gaplashing mijoz telefonni ko'tarmaganligi sababli to'lov"></textarea>

								<button
									className='bg-[#FF6501] py-4 px-16 rounded-[10px] cursor-pointer text-white font-semibold text-base  border-[1px] border-transparent
													hover:text-[#ff6501] hover:bg-white hover:border-[1px] hover:border-[#ff6501] transition duration-150 ease-out hover:ease-in'>
									Saqlash
								</button>
							</form>
						</Box>
					</Modal>
				</div>
			</div>
			{/* MODAL FORM END */}
		</div>
	);
};

export default ModalForm;
