import TopMini from './TopMini';
import { useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import searchIcon from '../images/search-icon.svg';
import { useEffect } from 'react';

const CreateCustomers = () => {
	const navigateAuth = useNavigate();
	useEffect(() => {
		if (!token) {
			navigateAuth('/auth');
		}
	}, []);
	const inputRef = useRef(null);
	const token = Cookies.get('token');
	const navigate = useNavigate();
	// HTML elemen yaratish
	const [elements, setElements] = useState([]);
	const [counter, setCounter] = useState(0);
	const elementsPerPage = 10;
	const [currentPage, setCurrentPage] = useState(1);

	const handleChangePage = (event, newPage) => {
		setCurrentPage(newPage);
	};

	const handleChange = (id, e) => {
		const { name, value } = e.target;
		setElements(prevElements => prevElements.map(el => (el.id === id ? { ...el, data: { ...el.data, [name]: value } } : el)));
	};

	const handleCreateElement = () => {
		setElements(prevElements => [...prevElements, { id: counter + 1, data: { name: '', phone1: '', phone2: '', address: '' } }]);
		setCounter(prevCounter => prevCounter + 1);
	};

	const handleRemoveElement = id => {
		setElements(prevElements => prevElements.filter(el => el.id !== id));
	};

	// FILE YUKLASH START --------------------
	const fileInputRef = useRef(null);
	const [excelFile, setExcelFile] = useState('');

	const handleFileChanges = event => {
		const selectedFile = event.target.files[0];
		setExcelFile(selectedFile && selectedFile);
	};

	const handleButtonClick = () => {
		fileInputRef.current?.click();
	};
	// FILE YUKLASH END ------------------
	const createCustomer = async e => {
		const arr = [];
		elements &&
			elements.map(elem => {
				if (elem.data.name && elem.data.phone1 && elem.data.phone2 && elem.data.address) {
					arr.push(elem.data);
				}
			});

		const data = arr;

		await axios
			.post(
				`https://crm.orzugrand.uz/api/client`,
				{
					clients: data,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						// 'Content-Type': 'multiple/formData',
						Accept: 'application/json',
						Authorization: token,
					},
				}
			)
			.then(res => {
				const createCustomer = res.data.data;
				navigate('/add-clients');
			})
			.catch(er => {
				// console.log(er);
			});
	};

	// FORM DATA API START ----------------------------
	const createCustomerF = async e => {
		const formData = new FormData();
		formData.append('excel_file', excelFile && excelFile ? excelFile : '');

		await axios
			.post(`https://crm.orzugrand.uz/api/client`, formData, {
				headers: {
					'Content-Type': 'multiple/formData',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const createCustomer = res.data.data;
				navigate('/add-clients');
			})
			.catch(er => {
				// console.log(er);
			});
	};
	// FORM DATA API END --------------------

	const paginatedElements = elements.slice((currentPage - 1) * elementsPerPage, currentPage * elementsPerPage);

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input type='search' name='search' placeholder='Qidirish' />

					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<div>
				<TopMini
					elements={elements.length}
					createCustomer={createCustomer}
					createCustomerF={createCustomerF}
					excelFile={excelFile}
					fileInputRef={fileInputRef}
					handleFileChanges={handleFileChanges}
					handleButtonClick={handleButtonClick}
				/>
				<div className='table table__row-wrapper'>
					<div className='table__row table__row4 table__head'>
						<div className='table__col'>ID</div>
						<div className='table__col'>Mijoz</div>
						<div className='table__col'>Telefon №1</div>
						<div className='table__col'>Telefon №2</div>
						<div className='table__col'>
							Mijoz <br /> manzili
						</div>
						<div className='table__col'>Izoh</div>
						<div className='table__col'>Qo'ng'iroqlar</div>
						{/* <div className='table__col'>
							Izoh <br /> qo'ng'iroqlarga
						</div> */}
						{/* <div className='table__col'>Eslatma</div> */}
						<div className='table__col'>Qarang</div>
					</div>

					{paginatedElements.map(element => (
						<div key={element.id}>
							<form className='table__row table__row4'>
								<div className='table__col'>{element.id}</div>
								<div className='table__col'>
									<input
										autoComplete='off'
										className='border-[1px] rounded-[4px] border-[#9F9F9F] border-solid bg-[#F6F6F6] py-2 pl-3
										2xl:w-[170px] w-[140px]'
										type='text'
										name='name'
										placeholder='Ism Familiya'
										value={element.data.name}
										onChange={e => handleChange(element.id, e)}
									/>
								</div>
								<div className='table__col'>
									<IMaskInput
										autoComplete='off'
										name='phone1'
										value={element.data.phone1}
										onChange={e => handleChange(element.id, e)}
										className='2xl:w-32 w-28 border-[1px] rounded-[4px] border-[#9F9F9F] border-solid bg-[#F6F6F6] py-2 pl-3'
										mask='+998 00 000 00 00'
										radix='.'
										// value='+998'
										inputRef={inputRef}
										placeholder='+998'
									/>
								</div>
								<div className='table__col'>
									<IMaskInput
										autoComplete='off'
										name='phone2'
										value={element.data.phone2}
										onChange={e => handleChange(element.id, e)}
										className='2xl:w-32 w-28 border-[1px] rounded-[4px] border-[#9F9F9F] border-solid bg-[#F6F6F6] py-2 pl-3'
										mask='+998 00 000 00 00'
										radix='.'
										// value='+998'
										inputRef={inputRef}
										placeholder='+998'
									/>
								</div>
								<div className='table__col'>
									<input
										autoComplete='off'
										name='address'
										value={element.data.address}
										onChange={e => handleChange(element.id, e)}
										className='2xl:w-32 w-28 border-[1px] rounded-[4px] border-[#9F9F9F] border-solid bg-[#F6F6F6] py-2 pl-3'
										type='text'
										placeholder='Mijoz manzili'
									/>
								</div>
								<div className='table__col'>
									<input
										autoComplete='off'
										className='2xl:w-32 w-28 border-[1px] rounded-[4px] border-[#9F9F9F] border-solid bg-[#F6F6F6] py-2 pl-3'
										type='text'
										name='comment'
										placeholder='Izoh yozing'
										value={element.data.comment}
										onChange={e => handleChange(element.id, e)}
									/>
								</div>
								<div className='table__col'>Yo'q</div>

								<div className='table__col'>
									<button
										type='button'
										onClick={() => handleRemoveElement(element.id)}
										className='max-w-[125px] text-center hover:opacity-75 w-full py-2 px-4 bg-[#FF5B5B] text-white rounded-xl cursor-pointer'>
										O'chirish
									</button>
								</div>
							</form>
						</div>
					))}

					<div
						onClick={() => {
							handleCreateElement();
						}}
						className='text-sm  max-w-[125px] py-2 px-4 2xl:mr-8 mr-4 w-full mb-6  text-center hover:opacity-75 float-right bg-[#00B074] text-white rounded-xl cursor-pointer mt-6 addButton'>
						Qoʻshing +
					</div>
					{/* </div> */}
				</div>

				{elements.length / elementsPerPage > 0 ? (
					<div className='flex items-center justify-center'>
						<Stack style={{ marginTop: '54px', marginBottom: '54px' }} spacing={2} sx={{ mt: 2 }}>
							<Pagination count={Math.ceil(elements.length / elementsPerPage)} page={currentPage} onChange={handleChangePage} />
						</Stack>
					</div>
				) : null}
			</div>
		</>
	);
};

export default CreateCustomers;
