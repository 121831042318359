import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Return from './Return';
import Header from './Header';
import searchIcon from '../images/search-icon.svg';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { Alert, Button, Snackbar, Stack } from '@mui/material';

const ClientInfo = () => {
	const { id } = useParams();
	const token = Cookies.get('token');
	const [isLoading, setIsLoading] = useState(false);
	const [down, setDown] = useState(1);
	const [client, setClient] = useState('');

	const getCalls = async () => {
		await axios
			.get(`https://crm.orzugrand.uz/api/client/${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				setIsLoading(true);
				const calls = res.data.data;
				setClient(calls);
				setIsLoading(false);
			})

			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	useEffect(() => {
		getCalls();
	}, []);

	const handleCopyClick = text => {
		const textarea = document.createElement('textarea');
		textarea.value = text;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
	};

	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input
						type='search'
						name='search'
						// value={inputValue}
						// onChange={handleInputChange}
						placeholder='Qidirish'
					/>

					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<Return title='Jadvalga qaytish' navigate={-1} />
			<div className='max-w-5xl w-full mb-14'>
				<h2 className='text-[#464255] text-[28px] font-bold'>Mijoz kartasi</h2>
				<p className='greytitle'>
					Bu erda siz mijoz haqida batafsil ma'lumot olishingiz va menejerning ishi bilan batafsil tanishishingiz va butun voqeani
					o'qishingiz mumkin. ushbu mijoz uchun va qo'ng'iroq yozuvlari va sharhlarini tinglang va mijoz bilan aloqaning butun
					tarixini kuzatib boring.
				</p>
			</div>
			<div className=''>
				<div className='flex items-center mb-12'>
					<div
						onClick={() => {
							setDown(1);
						}}
						className={
							down == 1
								? ' mr-4 py-2 px-5 bg-[#FB8500] text-white rounded-[10px] font-medium text-lg leading-[21.6px] border-[1px] border-transparent  '
								: 'mr-4 py-2 px-5 bg-white text-black rounded-[10px] font-medium text-lg leading-[21.6px] hover:opacity-80'
						}>
						<p>Mijoz kartasi</p>
					</div>
				</div>

				<div>
					{isLoading && <Spinner />}
					{client && (
						<div className='bg-white rounded-xl py-12 px-14 mb-14'>
							{/* 1 */}
							<div className='flex justify-between mb-5 px-7'>
								<h2 className='text-xl font-medium text-[#464255]'>Mijoz:</h2>
								<div className='flex items-center justify-between'>
									<div className='text-[#20719A] text-base font-medium value mr-6'>{client.name}</div>
									<div className='rounded-md cursor-pointer'>
										<Stack>
											<Button
												style={{
													fontWeight: '600',
													fontSize: '16px',
													color: '#0F845C',
													padding: '4px 20px',
													backgroundColor: '#BCEED6',
												}}
												onClick={() => {
													handleClick();
													handleCopyClick(client.name);
												}}>
												Copy
											</Button>
											<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
												<Alert onClose={handleClose}>Nusxa olindi!</Alert>
											</Snackbar>
										</Stack>
									</div>
								</div>
							</div>

							{/* 2 */}
							<div className='bg-[#F3F2F7] rounded-lg py-6 px-7 flex mb-6'>
								<h2 className='text-xl font-medium text-[#464255] mr-auto'>Telefon raqami:</h2>
								<div className='flex items-center justify-between'>
									<div className='mr-8 flex items-center'>
										<div className='text-[#20719A] text-base font-medium value mr-6'>{client.phone1}</div>
										<div className='rounded-md cursor-pointer'>
											<Stack>
												<Button
													style={{
														fontWeight: '600',
														fontSize: '16px',
														color: '#0F845C',
														padding: '4px 20px',
														backgroundColor: '#BCEED6',
													}}
													onClick={() => {
														handleClick();
														handleCopyClick(client.phone1);
													}}>
													Copy
												</Button>
												<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
													<Alert onClose={handleClose}>Nusxa olindi!</Alert>
												</Snackbar>
											</Stack>
										</div>
									</div>
								</div>
								<div className='flex items-center'>
									<div className='text-[#20719A] text-base font-medium value mr-6'>{client.phone2}</div>
									<div className='rounded-md cursor-pointer'>
										<Stack>
											<Button
												style={{
													fontWeight: '600',
													fontSize: '16px',
													color: '#0F845C',
													padding: '4px 20px',
													backgroundColor: '#BCEED6',
												}}
												onClick={() => {
													handleClick();
													handleCopyClick(client.phone2);
												}}>
												Copy
											</Button>
											<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
												<Alert onClose={handleClose}>Nusxa olindi!</Alert>
											</Snackbar>
										</Stack>
									</div>
								</div>
							</div>

							{/* 3 */}
							<div className='px-7 mb-6 flex items-center justify-between'>
								<h2 className='ttext-xl font-medium text-[#464255] mr-auto'>Mijoz ID:</h2>
								<div className='flex items-center'>
									<div className='text-[#20719A] text-base font-medium value mr-6'>{client.id}</div>
									<div className='rounded-md cursor-pointer'>
										<Stack>
											<Button
												style={{
													fontWeight: '600',
													fontSize: '16px',
													color: '#0F845C',
													padding: '4px 20px',
													backgroundColor: '#BCEED6',
												}}
												onClick={() => {
													handleClick();
													handleCopyClick(client.id);
												}}>
												Copy
											</Button>
											<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
												<Alert onClose={handleClose}>Nusxa olindi!</Alert>
											</Snackbar>
										</Stack>
									</div>
								</div>
							</div>

							{/* 4 */}
							<div className='bg-[#F3F2F7] rounded-lg py-6 px-7 flex mb-6'>
								<h2 className='text-xl font-medium text-[#464255] mr-auto'>Mijozning shahri va manzili:</h2>
								<div className='flex items-center justify-between'>
									<div className='mr-8 flex items-center'>
										<div className='text-[#20719A] text-base font-medium value '>{client.address}</div>
										<div className='visited:hidden opacity-0 text-[#0F845C] text-lg font-semibold py-1 px-5 bg-[#BCEED6] rounded-md '>
											Copy
										</div>
									</div>
								</div>
							</div>

							{/* 4 */}
							<div className='bg-[#F3F2F7] rounded-lg py-6 px-7 flex mb-6'>
								<h2 className='text-xl font-medium text-[#464255] mr-auto'>Ro'yxatga olingan sana:</h2>
								<div className='flex items-center justify-between'>
									<div className='mr-8 flex items-center'>
										<div className='text-[#20719A] text-base font-medium value'>{client && client.created_at.slice(0, 10)}</div>
										<div className='visited:hidden opacity-0 text-[#0F845C] text-lg font-semibold py-1 px-5 bg-[#BCEED6] rounded-md '>
											Copy
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className='copied'>Nusxa olindi!</div>
				</div>

				<Return title='Jadvalga qaytish' classes='second-view' navigate={-1} />
			</div>
		</>
	);
};

export default ClientInfo;
