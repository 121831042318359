import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import Spinner from '../components/Spinner';
import defaultIcon from '../images/user.png';
import Cookies from 'js-cookie';
import { Box, Button, Modal, Pagination } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import playIcon from '../images/play.svg';
import pauseIcon from '../images/pause.svg';
import searchIcon from '../images/search-icon.svg';
import { Header } from '../components';
import topIcon1 from '../images/status-icon.svg';
import topIcon2 from '../images/operator-icon.svg';
import topIcon3 from '../images/calendar-icon.svg';
import topArrow from '../images/top-arrow.svg';
import topSearch from '../images/top-search.svg';
import Calendar from 'react-calendar';
import moment from 'moment';
import closeIcon from '../images/close.svg';

const headTitle = "Qo'ng'iroqlarning umumiy jadvali";
const greyText = `Bu yerda siz barcha operatorlar uchun barcha qo'ng'iroqlarni
ko'rishingiz yoki kerakli operator bo'yicha tartiblashingiz mumkin`;

const styles = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	height: 400,
	bgcolor: 'background.paper',
	border: '2px solid #FB8500',
	boxShadow: 24,
	p: 4,
};

const Calls = ({ pusher }) => {
	const navigate = useNavigate();
	const token = Cookies.get('token');
	const imageUrl = 'https://crm.orzugrand.uz/images';
	const recordsUrl = 'https://crm.orzugrand.uz/records';
	const [calenderValues, setCalenderValues] = useState(new Date());
	const from = calenderValues && calenderValues.length > 0 ? moment(calenderValues[0]).format('YYYY-MM-DD') : '';
	const to = calenderValues && calenderValues.length > 0 ? moment(calenderValues[1]).format('YYYY-MM-DD') : '';

	useEffect(() => {
		if (!token) {
			navigate('/auth');
		}
	}, []);

	// FILTER START
	const [tableInfo, setTableInfo] = useState([]);
	const [filteredContacts, setFilteredContacts] = useState([]);
	const [searchById, setSearchById] = useState('');
	const [searchByNameNum, setSearchByNameNum] = useState('');

	useEffect(() => {
		const filteredById =
			tableInfo &&
			tableInfo.filter(contact => contact.id.toString().includes(searchById) || contact.name.toString().includes(searchById));
		const filteredByNameNum =
			tableInfo &&
			tableInfo.filter(
				contact =>
					contact.user.name.toLowerCase().includes(searchByNameNum.toLowerCase()) ||
					contact.phone1.toString().includes(searchByNameNum) ||
					contact.phone2.toString().includes(searchByNameNum)
			);

		if (searchById) {
			setFilteredContacts(filteredById);
		} else {
			setFilteredContacts(filteredByNameNum);
		}
	}, [tableInfo, searchById, searchByNameNum]);
	// FILTER END

	// MODAL START
	const [calls, setCalls] = useState();
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const handleOpen = elem => {
		setCalls(elem.calls);
		setOpen(true);
	};

	const [modalOpen, setModalOpen] = useState(false);
	const openModal = elem => {
		setCalls(elem.calls);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	// MODAL END

	const sessionStorage = window.sessionStorage;
	const [isLoading, setIsLoading] = useState(false);

	const [page, setPage] = useState(1);
	const [total, setTotal] = useState();
	const [perPage, setPerPage] = useState();

	const [isPlaying, setIsPlaying] = useState(-1);
	const videoTriggers = useRef({});
	const [activeAudio, setActiveAudio] = useState(null);

	const change = (event, value) => {
		setPage(value);
	};

	const checkPlaying = (e, id) => {
		e.preventDefault();
		const videoTracker = videoTriggers.current[id];
		const isPaused = videoTracker.paused;
		setActiveAudio(id);
		pauseAll();
		if (isPaused) {
			videoTracker.play();
			setIsPlaying(id);
		} else {
			videoTracker.pause();
			setIsPlaying(-1);
		}

		if (activeAudio == id) {
			setActiveAudio(null);
		}
	};

	const handleVideoRefs = (id, videoRef) => {
		videoTriggers.current[id] = videoRef;
	};

	const pauseAll = () => {
		Object.values(videoTriggers.current).forEach(videoTracker => {
			if (videoTracker) {
				videoTracker.pause();
				videoTracker.currentTime = 0;
			}
		});
	};

	const [sendFromDate, setSendFromDate] = useState('');
	const [sendToDate, setSendToDate] = useState('');

	const fromChild = (el, el2) => {
		setSendFromDate(el);
		setSendToDate(el2);
	};

	// TOP START -----------------------------------------------
	// DATEPICKER
	const [isCalenderActive, setIsCalenderActive] = useState(false);
	const calendarRef = useRef();
	useEffect(() => {
		const handleClickOutside = event => {
			if (calendarRef.current && !calendarRef.current.contains(event.target)) {
				setIsCalenderActive(false);
			}
		};

		window.addEventListener('click', handleClickOutside);

		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);
	// TOP END---------------------------------------------------

	// PARAMS START
	const [selectedValue, setSelectedValue] = useState('');
	const [selectedId, setSelectedId] = useState('');

	// ?status_id=${selectedValue}&user_id=${selectedId}&page=${page}&from=${sendFromDate}&to=${sendToDate}

	const getTableInfo = async () => {
		setIsLoading(true);

		await axios
			.get(
				`https://crm.orzugrand.uz/api/calls?page=${page}&status_id=${selectedValue}&user_id=${selectedId}&from=${from}&to=${to}`,
				{
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: token,
					},
				}
			)
			.then(res => {
				const tableInfo = res.data.data;
				setTableInfo(tableInfo.data);
				setFilteredContacts(tableInfo.data);
				setTotal(tableInfo.total);
				setPerPage(tableInfo.per_page);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	useEffect(() => {
		getTableInfo();
		fromChild();
		getOperators();
		sessionStorage.setItem('statusId', '');
		sessionStorage.setItem('operatorId', '');

		window.addEventListener('beforeunload', function () {
			sessionStorage.setItem('statusId', '');
			sessionStorage.setItem('operatorId', '');
		});
	}, [page, selectedValue, selectedId, from, to]);

	const [operators, setOperators] = useState();

	const getOperators = async () => {
		await axios
			.get(`https://crm.orzugrand.uz/api/user`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res.data.data;
				setOperators(operators.data);
			})
			.catch(er => {
				// console.log(er);
			});
	};

	// SELECT STATUS_ID START
	const handleSelectChange = e => {
		const value = e.target.value;
		setSelectedValue(value);
		getTableInfo();
	};
	// SELECT STATUS_ID END

	// SELECT USE_ID START
	const handleChange = e => {
		setSelectedId(e.target.value);
		getTableInfo();
	};
	// SELECT USER_ID END

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input
						type='search'
						name='search'
						value={searchByNameNum}
						onChange={e => setSearchByNameNum(e.target.value)}
						placeholder="Xodimlarni ismi va telefon raqami bo'yicha qidirish"
					/>
					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			{isLoading && <Spinner />}
			{tableInfo && (
				<div className='wrapper'>
					{/* TOP START */}
					<div className='top'>
						<div className='top__info w-1/3 mr-2'>
							<h2 className='title'>{headTitle}</h2>
							<div className='greytitle'>{greyText}</div>
						</div>
						<div className='top__content'>
							<div className='top__selects'>
								<div className='top__box top__box-select'>
									<img className='2xl:w-12 w-9 2xl:h-12 h-9 object-cover cursor-pointer mr-3' src={topIcon1} alt='top icon' />
									<div className='top__row'>
										<select className='cursor-pointer w-[90%]' value={selectedValue} onChange={handleSelectChange}>
											<option value=''>Barchasi</option>
											<option value={1}>10% chegirma</option>
											<option value={2}>15% chegirma</option>
											<option value={3}>Bizdan mahsulot sotib oldik</option>
											<option value={4}> O&apos;ylashini aytdi</option>
											<option value={5}>Telefonini ko&apos;tarmadi</option>
											<option value={6}>Kelib sotib oling dedi</option>

											<option value={7}>Mijoz rad edi</option>
										</select>
									</div>
									<img className='2xl:w-5 w-4 2xl:h-[10px] h-2' src={topArrow} alt='arrow icon' />
								</div>

								<div className='top__box top__box-select'>
									<img className='2xl:w-12 w-9 2xl:h-12 h-9 object-cover cursor-pointer mr-3' src={topIcon2} alt='top icon' />
									<div className='top__row'>
										<select className='cursor-pointer w-[90%]' onChange={handleChange} value={selectedId}>
											<option disabled>Menejerni tanlang</option>
											<option value=''>Barchasi</option>
											{operators &&
												operators.map(operator =>
													operator.is_admin == 0 ? (
														<option key={operator.id} value={operator.id}>
															{operator.name}
														</option>
													) : null
												)}
										</select>
									</div>
									<img className='2xl:w-5 w-4 2xl:h-[10px] h-2' src={topArrow} alt='arrow icon' />
								</div>

								{/* Calendar start */}
								<div
									className='top__box  cursor-pointer bg-[#FFFFFF]'
									onClick={() => setIsCalenderActive(true)}
									ref={calendarRef}>
									<div className='flex items-center justify-center '>
										<img className='2xl:w-12 w-9 2xl:h-12 h-9 object-cover cursor-pointer mr-3 ' src={topIcon3} alt='top icon' />
									</div>
									<div className='top__row'>
										<div id='dateSelect' className='text-center 2xl:mr-3 mr-2 2xl:text-base text-sm font-medium'>
											Sanalar oralig&apos;ini tanlang
										</div>
										{isCalenderActive && (
											<div className='top__calendar'>
												<Calendar onChange={setCalenderValues} value={calenderValues} selectRange />
											</div>
										)}
										<div className='text-center 2xl:mr-3 mr-2 2xl:text-base text-sm font-medium'>
											{calenderValues && new Date(calenderValues[0]).toLocaleDateString().replaceAll('.', '-') == 'Invalid Date'
												? null
												: new Date(calenderValues[0]).toLocaleDateString().replaceAll('.', '-')}
											{new Date(calenderValues[0]).toLocaleDateString().replaceAll('.', '-') == 'Invalid Date' ? null : ':'}{' '}
											{new Date(calenderValues[1]).toLocaleDateString().replaceAll('.', '-') == 'Invalid Date'
												? null
												: new Date(calenderValues[1]).toLocaleDateString().replaceAll('.', '-')}
										</div>
									</div>
									<img className='2xl:w-5 w-4 2xl:h-[10px] h-2' src={topArrow} alt='arrow icon' />
								</div>
								{/* Calendar end */}
							</div>
							<div className='top__contract'>
								Filtr:
								<div className='top__wrapper'>
									<input
										className='top__input'
										style={{ fontSize: '16px' }}
										type='search'
										placeholder="Mijoz id va ismi bo'yicha qidirish"
										value={searchById}
										onInput={e => {
											setSearchById(e.target.value);
										}}
									/>
									<img src={topSearch} alt='search icon' />
								</div>
							</div>
						</div>
					</div>
					{/* TOP END */}
					<div className='table'>
						<div className='table__row table__head table__calls'>
							<div className='table__col'>ID</div>
							<div className='table__col'>Menejer</div>
							<div className='table__col'>Mijoz</div>
							<div className='table__col'>Telefon №1</div>
							<div className='table__col'>Telefon №2</div>
							<div className='table__col'>Manzil</div>
							<div className='table__col'>Izoh</div>
							<div className='table__col'>Qo&apos;ng&apos;iroqlar</div>
							<div className='table__col'>Izoh qo&apos;ng&apos;iroqlar</div>
							<div className='table__col'>Qarang</div>
						</div>
						{filteredContacts &&
							filteredContacts.map(data => (
								<div className='table__row table__calls' key={data.id}>
									{/* 1 */}
									<div className='table__col'>{data.id}</div>

									{/* 2 */}
									<div className='table__col' style={{ justifyContent: 'start' }}>
										<img
											className='table__avi'
											src={data.user.image != null ? `${imageUrl}/${data.user.image}` : defaultIcon}
											alt='person avi'
										/>
										<div className=' text-[#6F6767]'>{data.user.name}</div>
									</div>

									{/* 3 */}
									<div className='table__col text-[#6F6767]'>{data.name}</div>

									{/* 4 */}
									<div className='table__col text-[#6F6767]'>{data.phone1}</div>

									{/* 5 */}
									<div className='table__col text-[#6F6767]'>{data.phone2}</div>

									{/* 6 */}
									<div className='table__col text-[#6F6767]'>
										{data.address.length > 15 ? data.address.slice(0, 15) : data.address}{' '}
										{data.address.length > 16 ? '...' : null}
									</div>

									{/* 7 */}
									<div className='table__col text-[#00B074]'>{data.comment ? data.comment : '-'}</div>

									{/* 8 */}
									<div className='table__col flex flex-col items-center justify-center'>
										<div className='flex items-center'>
											<div className='table__action mr-2' onClick={e => checkPlaying(e, data.calls[0].id)}>
												{isPlaying && isPlaying == data.calls[0].id ? (
													<img src={pauseIcon} alt='action icon' />
												) : (
													<img src={playIcon} alt='action icon' />
												)}
											</div>
											<audio
												ref={ref => handleVideoRefs(data.calls[0].id, ref)}
												src={`${recordsUrl}/${data.calls[0].record ? data.calls[0].record : ''}`}></audio>
											<div>
												<p>Yozib olish</p>
												<p style={{ fontSize: '10px' }}>{data.calls[0].date}</p>
											</div>
										</div>
										<div>
											{/* BACHA QO'NG'IROQLAR MODAL START*/}
											<div className='relative' style={{ marginTop: '0px' }}>
												<Button
													style={{ color: '#ff6501', textDecoration: 'underline', fontSize: '8px', fontWeight: '400' }}
													onClick={() => handleOpen(data)}>
													Barcha qo'ng'iroqlar
												</Button>
												<Modal
													open={open}
													onClose={handleClose}
													aria-labelledby='modal-modal-title'
													aria-describedby='modal-modal-description'>
													<Box className='wrapper' sx={styles} style={{ overflowY: 'scroll' }} borderRadius='8px'>
														<img
															onClick={() => handleClose()}
															className='absolute right-4 top-4 w-4 object-cover cursor-pointer'
															src={closeIcon}
															alt=''
														/>
														{calls &&
															calls.map(data => {
																return (
																	<div
																		className='flex justify-center items-center mb-4 border-b-2 border-[#FB8500]'
																		key={data.id}>
																		<div className='table__action mr-7 mb-1' onClick={e => checkPlaying(e, data.id)}>
																			{isPlaying && isPlaying == data.id ? (
																				<img src={pauseIcon} alt='action icon' />
																			) : (
																				<img src={playIcon} alt='action icon' />
																			)}
																		</div>
																		<audio
																			ref={ref => handleVideoRefs(data.id, ref)}
																			src={`${recordsUrl}/${data.record ? data.record : ''}`}></audio>
																		<div className='mb-1'>
																			<p style={{ fontSize: '16px' }}>Yozib olish</p>
																			<p style={{ fontSize: '16px' }}>{data.date}</p>
																		</div>
																	</div>
																);
															})}
													</Box>
												</Modal>
											</div>
											{/* BACHA QO'NG'IROQLAR MODAL END*/}
										</div>
									</div>

									{/* 9 */}
									<div className='table__col flex flex-col items-start justify-center'>
										<p className='mb-4'>
											{data.calls[0].comment.length > 15 ? data.calls[0].comment.slice(0, 15) : data.calls[0].comment}{' '}
											{data.calls[0].comment.length > 16 ? '...' : null}
										</p>

										{/* BARCHA IZOHLAR START */}

										<div className='relative'>
											<Button
												style={{ color: '#ff6501', textDecoration: 'underline', fontSize: '8px', fontWeight: '400' }}
												onClick={() => {
													openModal(data);
												}}>
												Barcha izohlar
											</Button>
											<Modal open={modalOpen} onClose={closeModal}>
												<Box sx={styles} style={{ overflowY: 'scroll' }} className='wrapper'>
													<img
														onClick={() => closeModal()}
														className='absolute right-4 top-4 w-4 object-cover cursor-pointer'
														src={closeIcon}
														alt=''
													/>
													{calls &&
														calls.map((data, index) => {
															return (
																<div className='flex justify-center items-center mb-4 border-b-2 border-[#FB8500]' key={data.id}>
																	<div className='mr-7 mb-1'>
																		<p style={{ fontSize: '16px' }}>
																			<span className='mr-2'>{index + 1}.</span>
																			{data.comment}
																		</p>
																	</div>
																</div>
															);
														})}
												</Box>
											</Modal>
										</div>

										{/* BARCHA IZOHLAR END */}
									</div>

									{/* 11 */}
									<div className='table__col'>
										<Link
											className='py-3 2xl:px-5 px-4 border-[1px] border-transparent bg-[#00B074] text-white rounded-xl hover:text-[#00B074] hover:bg-white hover:border-[#00B074]  transition duration-150 ease-out hover:ease-in'
											to={`/operator-profile/${data.id}`}>
											Ko'proq
										</Link>
									</div>
								</div>
							))}
					</div>

					{total <= perPage ? null : (
						<Pagination
							className='pagination'
							count={Math.ceil(total == null ? 0 : total / perPage)}
							color='primary'
							variant='outlined'
							defaultValue={page}
							page={page}
							onChange={change}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default Calls;
