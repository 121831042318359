import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import userChanger from './userReducer';

const allReducers = combineReducers({
	sendAdmin: adminReducer,
	user: userChanger,
});

export default allReducers;
