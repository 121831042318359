import { Link } from 'react-router-dom';
import defaultIcon from '../images/user.png';
import { Pagination } from '@mui/material';

const OperatorBox = ({ filteredData, total, perPage, change, page }) => {
	const imageUrl = 'https://crm.orzugrand.uz/images';

	return (
		<>
			<div className='operator'>
				{filteredData &&
					filteredData.map((data, index) => (
						<Link to={`/managerCard/${data.id}`} className='operator__item' key={index}>
							<div className='operator__head'>
								<img
									className='operator__avi'
									src={data.image != null ? `${imageUrl}/${data.image}` : defaultIcon}
									alt={defaultIcon}
								/>
								<div className='operator__info'>
									<div className='operator__name'>{data.name}</div>
									<div className='text-[#A3A3A3] font-medium 2xl:text-lg text-sm'>Shahar: {data.city}</div>

									<div className='operator__stats text-black'>Qo'ng'iroqlar statistikasi</div>
								</div>
								<div className='operator__rank'>
									{data.is_admin == 1 ? (
										<h2 className='text-[#2D9CDB] 2xl:text-lg text-base  font-semibold'>ROP</h2>
									) : (
										<h2 className='text-[#A3A3A3] 2xl:text-lg text-base  font-semibold'>Menejer</h2>
									)}
								</div>
							</div>
							<div className='operator__plan'>
								<div className='operator__box orange'>
									<div className='operator__circle'>{data.limit}</div>
									<div className='operator__completed'>Bugunga rejalashtirilgan qo&apos;ng'iroqlar</div>
								</div>

								<div className='operator__box green'>
									<div className='operator__circle'>{data.called}</div>
									<div className='operator__completed'>Bugungi qo&apos;ng'roqlar soni</div>
								</div>
							</div>
						</Link>
					))}
			</div>
			{total <= perPage ? null : (
				<Pagination
					className='pagination mt-8'
					count={Math.ceil(total == null ? 0 : total / perPage)}
					color='primary'
					variant='outlined'
					defaultValue={page}
					page={page}
					onChange={change}
				/>
			)}
		</>
	);
};

export default OperatorBox;
