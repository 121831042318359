import ringIcon from '../images/ring-icon.svg';
import settingsIcon from '../images/settings-icon.svg';
import Notification from './Notification';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import defaultIcon from '../images/user.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Box, Button, IconButton, Modal, Snackbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import closeeNotification from '../images/close.svg';
import MessageAler from './MessageAler';
import logout from '../images/logout.svg';
import Pusher from 'pusher-js';

const style = {
	position: 'absolute',
	top: '45%',
	left: '75%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	borderRadius: '15px',
	boxShadow: 24,
	p: 4,
	height: '420px',
	overflowY: 'auto',
	scrollbarColor: 'red',
};

const styleOut = {
	position: 'absolute',
	top: '26%',
	left: '83%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: 'background.paper',
	borderRadius: '15px',
	boxShadow: 24,
	color: '#464255',
	p: 4,
	'@media (max-width: 1576px)': {
		left: '80%',
	},
};

const Header = () => {
	// USER
	const userInfo = JSON.parse(localStorage.getItem('user'));
	// USER END

	// SNACKBAR START
	const [opens, setOpens] = React.useState(false);

	const handleClicks = () => {
		setOpens(true);
	};

	const handleCloses = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setOpens(false);
	};

	const actions = (
		<div style={{ backgroundColor: 'red !important' }}>
			<Button className='parol-error' color='secondary' size='small' onClick={handleCloses}></Button>
			<IconButton size='small' aria-label='close' color='inherit' onClick={handleCloses}>
				<CloseIcon fontSize='small' />
			</IconButton>
		</div>
	);
	// SNACKBAR END

	// LOGOUT MODAL START
	const [logoutOpen, setLogoutOpen] = React.useState(false);
	const handleLogoutOpen = () => setLogoutOpen(true);
	const handleLogoutClose = () => setLogoutOpen(false);
	// LOGOUT MODAL END

	const navigate = useNavigate();
	const token = Cookies.get('token');

	const viewPost = async id => {
		await axios
			.post(`https://crm.orzugrand.uz/api/view/${id}`, [], {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const notificationSubmit = res.data;
			})
			.catch(er => {
				// console.log(er);
			});
	};

	const [notificationGet, setNotificationGet] = useState('');

	const viewGet = async () => {
		await axios
			.get(`https://crm.orzugrand.uz/api/view/${userInfo.id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const notificationGet = res.data;
				setNotificationGet(notificationGet);
			})
			.catch(er => {
				// console.log(er);
			});
	};
	useEffect(() => {
		viewGet();
	}, []);

	const imageUrl = 'https://crm.orzugrand.uz/images';

	let notification = JSON.parse(localStorage.getItem('notification')) || notificationGet;

	const [myArray, setMyArray] = useState([]);

	useEffect(() => {
		setMyArray(notification);
	}, []);

	useEffect(() => {
		const pusher = new Pusher('fd7742683dadae1ce63c', {
			cluster: 'ap2',
		});

		// Pusher kanali
		const channel = pusher.subscribe(`notification${userInfo && userInfo.id}`);

		channel.bind('notification', function (data) {
			data && handleClicks();

			localStorage.setItem('notification', JSON.stringify(data.data));
			setMyArray(data.data);

			return () => {
				pusher.unsubscribe(`notification${userInfo && userInfo.id}`);
			};
		});
	}, []);

	// NOTICIFATION X QILGANDA REMOVE QILISH START ---------------
	const handleItemClick = id => {
		viewPost(id);
		// Remove the item with the given ID
		const updatedArray = notification.filter(item => item.id != id);
		setMyArray(updatedArray);
		localStorage.setItem('notification', JSON.stringify(null));
		localStorage.setItem('notification', JSON.stringify(updatedArray));
	};

	// MODAL
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		await axios
			.post(`https://crm.orzugrand.uz/api/logout`, [], {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res;
				navigate('/auth');
				Cookies.remove('token');
				localStorage.removeItem('notification');
			})
			.catch(er => {
				// console.log(er);
				alert('boshqatan urining');
			});
	};
	const [showAlert, setShowAlert] = useState(false);

	const [showSecondImage, setShowSecondImage] = useState(false);

	return (
		<div>
			<div>
				<Snackbar open={opens} autoHideDuration={3000} onClose={handleCloses} message='Yangi eslatma keldi!' action={actions} />
			</div>
			<div className='header__action'>
				{/* MESSAGE */}
				<div className='header__news'>
					<MessageAler handleClose={handleClose} showAlert={showAlert} setShowAlert={setShowAlert} />
				</div>

				<div className='header__news relative'>
					<div>
						<Button
							onClick={() => {
								handleOpen();
								setShowAlert(false);
							}}>
							<div className='header__notification orange '>
								<img src={ringIcon} alt='notification icon' />
								<div className='header__badge'>{notification && notification.length}</div>
							</div>
						</Button>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'>
							<Box sx={style} className='wrapper'>
								{notification.length == 0 ? (
									<div className='flex justify-center items-center w-full h-full relative'>
										<img
											onClick={() => {
												handleClose();
											}}
											className='absolute top-0 right-0 cursor-pointer'
											src={closeeNotification}
											alt='close'
										/>
										<h2 className='text-[#EA2121] text-center font-bold text-lg'>Bugungi kunga eslatma yo'q!</h2>
									</div>
								) : (
									<Notification filteredItems={notification} handleItemClick={handleItemClick} />
								)}
							</Box>
						</Modal>
					</div>
				</div>
				{/* SETTING */}
				<Link to={'/profile-settings'} className='header__news'>
					<div className='header__notification red'>
						<img src={settingsIcon} alt='notification icon' />
					</div>
				</Link>

				<div className='header__profile'>
					<Link to={'/profile-settings'} className='header__current cursor-pointer hover:opacity-80'>
						<div className='header__name whitespace-nowrap'>{userInfo && userInfo.name}</div>
						<img
							onClick={() => setShowSecondImage(!showSecondImage)}
							className='header__img'
							src={userInfo && userInfo.image ? `${imageUrl}/${userInfo.image}` : defaultIcon}
							alt='header avi'
						/>
					</Link>
					{/* LOGOUT MODAL START */}
					<div>
						<Button onClick={handleLogoutOpen}>
							<div>
								<img
									className='2xl:w-[52px] w-10 2xl:h-[52px] h-10 cursor-pointer hover:scale-110 ease-in duration-300 ml-1'
									src={logout}
									alt=''
								/>
							</div>
						</Button>
						<Modal
							open={logoutOpen}
							onClose={handleLogoutClose}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'>
							<Box sx={styleOut}>
								<Typography id='modal-modal-title' variant='h6' component='h2' style={{ marginBottom: '30px' }}>
									Tizimdan chiqishingizga ishonchingiz komilmi?
								</Typography>
								<Button
									onClick={() => {
										handleLogoutClose();
									}}
									style={{ float: 'right' }}
									variant='outlined'
									color='error'>
									Yo'q
								</Button>
								<Button
									onClick={() => {
										setShowSecondImage(!showSecondImage);
										handleSubmit();
									}}
									variant='contained'
									color='success'
									style={{ float: 'right', marginRight: '12px' }}>
									Ha
								</Button>
							</Box>
						</Modal>
					</div>

					{/* LOGOUT MODAL END */}
				</div>
			</div>
		</div>
	);
};

export default Header;
