import { useEffect, useState } from 'react';
import StatsTwo from './StatsTwo';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useRef } from 'react';
import Spinner from '../components/Spinner';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Header, ModalForm } from '../components';
import dowland from '../images/dowland.svg';
import playIcon from '../images/play.svg';
import pauseIcon from '../images/pause.svg';
import { Box, Modal } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import topSearch from '../images/top-search.svg';
import status from '../status.json';
import recordModal from '../images/record-modal.svg';
import closeIcon from '../images/close.svg';
import { Pagination } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert ref={ref} variant='filled' {...props} />;
});

const styles = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	height: 400,
	bgcolor: 'background.paper',
	border: '2px solid #FB8500',
	boxShadow: 24,
	p: 4,
};

const CallSchedule = () => {
	const token = Cookies.get('token');
	const userInfo = JSON.parse(localStorage.getItem('user'));
	const navigate = useNavigate();
	// RECORD START
	const recordsUrl = 'https://crm.orzugrand.uz/records';
	const [isPlaying, setIsPlaying] = useState(-1);
	const videoTriggers = useRef({});
	const [activeAudio, setActiveAudio] = useState(null);

	const checkPlaying = (e, id) => {
		e.preventDefault();
		const videoTracker = videoTriggers.current[id];
		const isPaused = videoTracker.paused;
		setActiveAudio(id);
		pauseAll();

		if (isPaused) {
			videoTracker.play();
			setIsPlaying(id);
		} else {
			videoTracker.pause();
			setIsPlaying(-1);
		}

		if (activeAudio === id) {
			setActiveAudio(null);
		}
	};

	const handleVideoRefs = (id, videoRef) => {
		videoTriggers.current[id] = videoRef;
	};

	const pauseAll = () => {
		Object.values(videoTriggers.current).forEach(videoTracker => {
			if (videoTracker) {
				videoTracker.pause();
				videoTracker.currentTime = 0;
			}
		});
	};
	// RECORD END

	// ---------------- STATISTCT TOP API START -------------------
	const [isLoading, setIsLoading] = useState(false);

	const [statistics, setStatistics] = useState();
	const [interestValue, setInterestValue] = useState(0);
	const [finished, setFinishid] = useState(0);

	const statistic = async () => {
		setIsLoading(true);
		await axios
			.post(`https://crm.orzugrand.uz/api/statistic`, [], {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const statistic = res.data.data;
				setStatistics(statistic);
				setInterestValue(Math.min(statistic.unfinished, 300));
				setFinishid(Math.min(statistic.finished, 300));
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	useEffect(() => {
		statistic();
	}, []);
	// ---------------- STATISTCT TOP API END -------------------

	// STATUS START
	const [statu, setStatu] = useState([]);
	const [statusIdGet, setStatusIdGet] = useState('');
	const handleStatu = (e, id, statusId) => {
		setStatusIdGet(e);
		if (statu.length < 1) {
			setStatu([...statu, { id: id, text: e, statusId: statusId }]);
		}
		statu.forEach(item => {
			if (item.id != id) {
				setStatu([...statu, { id: id, text: e, statusId: statusId }]);
			} else {
				statu.splice(statu.indexOf(item), 1);
				setStatu([...statu, { id: id, text: e, statusId: statusId }]);
			}
		});
	};
	// STATUS END

	const [page, setPage] = useState(1);
	const [total, setTotal] = useState();
	const [perPage, setPerPage] = useState();
	const change = (event, value) => {
		setPage(value);
	};

	const [calls, setCalls] = useState([]);
	const [filterId, setFilterId] = useState('');

	const getCalls = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/clients?page=${page}&filter=${filterId}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const calls = res.data.data;
				setCalls(calls.data);
				setTotal(calls.total);
				setPerPage(calls.per_page);
				setIsLoading(false);
			})

			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	const [selectedFiles, setSelectedFiles] = useState([]);
	useEffect(() => {
		getCalls();
	}, [filterId, page]);

	useEffect(() => {
		if (!token) {
			navigate('/auth');
		}
		notification();
	}, []);

	const [show, setShow] = useState(false);
	const [down, setDown] = useState(false);
	const [bgColor, setBgColo] = useState(1);

	const handleCopyClick = text => {
		const textarea = document.createElement('textarea');
		textarea.value = text;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
	};

	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}
		setOpen(false);
	};

	// SEARCH START
	const [inputValue, setInputValue] = useState('');
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		// Filter the data based on the input value whenever the input value changes
		const filtered =
			calls &&
			calls.filter(
				item =>
					item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
					item.phone1.toLowerCase().includes(inputValue.toLowerCase()) ||
					item.phone2.toLowerCase().includes(inputValue.toLowerCase())
			);

		setFilteredData(calls && filtered);
	}, [inputValue, calls]);

	const handleInputChange = event => {
		const { value } = event.target;
		setInputValue(value);
	};
	// SEARCH END

	// FILE YUKLASH START
	const [selectedId, setSelectedId] = useState(null);

	const pickFiles = e => {
		// const file = e.target.files[0];

		if (selectedFiles.length < 1) {
			setSelectedFiles([...selectedFiles, { id: selectedId, name: e.target.files[0]?.name || null, file: e.target.files[0] }]);
		}

		selectedFiles.forEach(item => {
			if (item.id != selectedId) {
				setSelectedFiles([...selectedFiles, { id: selectedId, name: e.target.files[0]?.name || null, file: e.target.files[0] }]);
			} else {
				selectedFiles.splice(selectedFiles.indexOf(item), 1);
				setSelectedFiles([...selectedFiles, { id: selectedId, name: e.target.files[0]?.name || null, file: e.target.files[0] }]);
			}
		});
	};

	const handleFileChange = event => {
		let arr = [];
		const selected = Array.from(event.target.files).filter(file => {
			const allowedExtensions = ['.m4a', '.mpeg', '.mpga', '.mp3', '.wav'];
			const fileExtension = file.name.split('.').pop().toLowerCase();
			return allowedExtensions.includes(`.${fileExtension}`);
		});

		arr.push(selected);
	};

	const fileRef = useRef(null);

	const handleOpenFile = id => {
		fileRef.current?.click();
		setSelectedId(id);
	};

	// FILE YUKLASH END

	const [inputText, setInputText] = useState('');
	const [displayText, setDisplayText] = useState([]);
	const [textArId, setTextAr] = useState(null);

	const handleTextAray = event => {
		setInputText(event.target.value);
	};

	const handleSubmit = (event, elem) => {
		event.preventDefault();
		setShow(false);
		setDown(false);

		if (displayText.length < 1) {
			setDisplayText([...displayText, { id: textArId, comment: inputText }]);
		}

		displayText.forEach(item => {
			if (item.id != textArId) {
				setDisplayText([...displayText, { id: textArId, comment: inputText.toLocaleString().replaceAll(',', '') }]);
			} else {
				displayText.splice(displayText.indexOf(item), 1);
				setDisplayText([...displayText, { id: textArId, comment: inputText.toLocaleString().replaceAll(',', '') }]);
			}
		});

		// setDisplayText([...displayText, { id: textArId, comment: inputText }]);
		setInputText('');
	};
	// CREATECALL POST START

	// SNACKBAR START
	const [opens, setOpens] = React.useState(false);

	const handleClicks = () => {
		setOpens(true);
	};

	const handleCloses = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setOpens(false);
	};
	// SNACKBAR END

	const createCallSubmit = async (userId, data, clientId) => {
		const formData = new FormData();
		formData.append('user_id', userId);
		formData.append('date', data);
		formData.append('client_id', clientId);
		selectedFiles &&
			selectedFiles.map(file => {
				if (file.id == clientId) {
					formData.append('record', file.file);
				}
			});

		statu &&
			statu.map(item => {
				item.id == clientId && formData.append('status', item.statusId);
			});

		displayText &&
			displayText.map(item => {
				item.id == clientId && formData.append('comment', item.comment);
			});

		await axios
			.post(`https://crm.orzugrand.uz/api/call`, formData, {
				headers: {
					'Content-Type': 'multiple/formdata',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const notificationSubmit = res.data.data;
				getCalls();
				statistic();
			})
			.catch(er => {
				if (er.response.status) {
					handleClicks();
				} else {
					// console.log(er);
				}
			});
	};
	// CREATECALL POST END

	// SNACKBAR DELAY ERROR START
	const [delyOpens, setDelyOpens] = React.useState(false);

	const handleClickDely = () => {
		setDelyOpens(true);
	};

	const handleCloseDely = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setDelyOpens(false);
	};
	// SNACKBAR DELAY ERROR END

	// SNACKBAR DELAY ERROR START
	const [opensFinish, setOpensFinish] = React.useState(false);

	const handleClickFinish = () => {
		setOpensFinish(true);
	};

	const handleClos = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setOpensFinish(false);
	};
	// SNACKBAR DELAY ERROR END

	// DELY START
	const delay = async (userId, clientId) => {
		const formData = new FormData();
		formData.append('user_id', userId);
		formData.append('client_id', clientId);
		selectedFiles &&
			selectedFiles.map(file => {
				if (file.id == clientId) {
					formData.append('record', file.file ? file.file : '');
				}
			});

		statu &&
			statu.map(item => {
				item.id == clientId && formData.append('status', item.statusId ? item.statusId : '');
			});

		displayText &&
			displayText.map(item => {
				item.id == clientId && formData.append('comment', item.comment);
			});

		const targetData = displayText && displayText.find(item => item.id === clientId);
		const statusDelay = statu && statu.find(item => item.id === clientId);
		setIsLoading(true);
		await axios
			.post(`https://crm.orzugrand.uz/api/change`, formData, {
				headers: {
					'Content-Type': 'multiple/formdata',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const dely = res.data;
				if (targetData) {
					targetData.comment = '';
				}

				if (statusDelay) {
					statusDelay.text = '';
				}
				selectedFiles &&
					selectedFiles.map(data => {
						return (data.name = '');
					});

				getCalls();
				statistic();

				handleClickFinish();
				setIsLoading(false);
			})
			.catch(er => {
				handleClickDely();
				// console.log(er);
			});
	};
	// DELY END

	// NOTIFICATIONS START
	const [notifications, setNotifications] = useState('');
	const notification = async () => {
		await axios
			.get(`https://crm.orzugrand.uz/api/notification`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				setIsLoading(true);
				const notification = res.data.data;
				setNotifications(notification);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};
	// NOTIFICATIONS END

	// MODAL RECORD START
	const [recorInfo, setRecorInfo] = useState();
	const [modalOpen, setModalOpen] = useState(false);
	const openModal = elem => {
		const filteredDatas = elem.calls.reduce((acc, item) => {
			if (item.record !== null) {
				acc.push(item);
			}
			return acc;
		}, []);
		setRecorInfo(filteredDatas.reverse());
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};
	// MODAL RECORD END

	// MODAL STATUS START --------------
	const [statusInfo, setStatusInfo] = useState();
	const [statusOpen, setStatusOpen] = useState(false);
	const openStatusModal = elem => {
		const filteredData = elem.calls.filter(item => item.status !== null).map(item => item.status);
		setStatusInfo(filteredData.reverse());
		setStatusOpen(true);
	};

	const closeStatusModal = () => {
		setStatusOpen(false);
	};
	// MODAL STATUS END ----------------

	// MODAL STATUS START --------------
	const [commentInfo, setCommentInfo] = useState();
	const [commenOpen, setCommenOpen] = useState(false);
	const openCommenModal = elem => {
		setCommentInfo(elem.calls.reverse());
		setCommenOpen(true);
	};

	const closeCommenModal = () => {
		setCommenOpen(false);
	};
	// MODAL STATUS END ----------------

	// MODAL NOTIFICATION START --------------
	const [notificationInfo, setNotificationInfo] = useState();
	const [openNotification, setOpenNotification] = useState(false);
	const openNotificationModal = elem => {
		setNotificationInfo(elem.notifications.reverse());
		setOpenNotification(true);
	};

	const closeNotificationModal = () => {
		setOpenNotification(false);
	};
	// MODAL NOTIFICATION END ----------------

	return (
		<>
			{/* BAJARILDI CLICK */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={opens} autoHideDuration={3000} onClose={handleCloses}>
					<Alert severity='error'>Holatni tanlang va yozib olingan qo'ng'iroqni yuklang va izoh yozing!</Alert>
				</Snackbar>
			</Stack>
			{/* DELY ERROR CLICK */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={delyOpens} autoHideDuration={3000} onClose={handleCloseDely}>
					<Alert severity='error'>Qo'ng'iroqlarga izoh yozing!</Alert>
				</Snackbar>
			</Stack>
			{/* DELY TRY CLICK */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={opensFinish} autoHideDuration={3000} onClose={handleClos}>
					<Alert severity='success'>Kechiktirildi!</Alert>
				</Snackbar>
			</Stack>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<div className='message-info'>
						<h2 className='font-bold text-3xl text-[#464255] mb-2'>Umumiy qo'ng'iroqlar jadvali</h2>
						<p className='text-[#A3A3A3] font-medium text-base'>
							<span className='text-[#2d9cdb]'>{userInfo && userInfo.name}</span> xush kelibsiz! Bu erda qo'ng'iroqlar jadvali va
							ishingiz statistikasi.
						</p>
					</div>
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<div className='callsSchedule-fontFemlly'>
				<StatsTwo
					pending={isLoading}
					statistics={statistics}
					interestValue={interestValue}
					finished={finished}
					filterId={calls}
					notifications={notifications}
					notification={notification}
				/>
				<div className='top__contract flex justify-between items-center callsSchedule-fontFemlly'>
					<div className='flex'>
						<div
							onClick={() => {
								setBgColo(1);
								setFilterId(1);
							}}
							className={
								bgColor == 1
									? ' 2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-[#FB8500] text-white rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm leading-[21.6px] hover:opacity-[0.8]'
									: '2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-white text-black rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm  leading-[21.6px] hover:opacity-[0.8]'
							}>
							<button>Barcha qo'ng'iroqlar</button>
						</div>

						<div
							onClick={() => {
								setBgColo(3);
								setFilterId(3);
							}}
							className={
								bgColor == 3
									? ' 2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-[#FB8500] text-white rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm leading-[21.6px] hover:opacity-[0.8]'
									: '2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-white text-black rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm  leading-[21.6px] hover:opacity-[0.8]'
							}>
							<button>Tugalanmagan</button>
						</div>
						<div
							onClick={() => {
								setBgColo(4);
								setFilterId(4);
							}}
							className={
								bgColor == 4
									? ' 2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-[#FB8500] text-white rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm leading-[21.6px] hover:opacity-[0.8]'
									: '2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-white text-black rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm  leading-[21.6px] hover:opacity-[0.8]'
							}>
							<button>Tugalangan</button>
						</div>

						<div
							onClick={() => {
								setBgColo(2);
								setFilterId(2);
							}}
							className={
								bgColor == 2
									? ' 2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-[#FB8500] text-white rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm leading-[21.6px] hover:opacity-[0.8]'
									: '2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-white text-black rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm  leading-[21.6px] hover:opacity-[0.8]'
							}>
							<button>O'tkazilgan qo'ng'iroqlar</button>
						</div>

						<div
							onClick={() => {
								setBgColo(5);
								setFilterId(5);
							}}
							className={
								bgColor == 5
									? ' 2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-[#FB8500] text-white rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm leading-[21.6px] hover:opacity-[0.8]'
									: '2xl:mr-3 mr-2 2xl:py-2 py-1 2xl:px-3 px-2 text-center whitespace-nowrap bg-white text-black rounded-[10px] 2xl:font-medium font-normal 2xl:text-base text-sm  leading-[21.6px] hover:opacity-[0.8]'
							}>
							<button>Sotish va natijalar</button>
						</div>
					</div>

					<div className='flex items-center'>
						<p className='mr-3 2xl:font-semibold font-medium text-xs'>Filtr:</p>
						<div className='top__wrapper callSchFillter '>
							<input
								className='top__input 2xl:font-normal font-normal text-xs text-black'
								style={{ padding: '12px' }}
								type='text'
								value={inputValue}
								onChange={handleInputChange}
								placeholder='Mijoz ismi va telefon'
							/>
							<img src={topSearch} alt='search icon' />
						</div>
					</div>
				</div>

				<div>
					{isLoading && <Spinner />}
					{calls && (
						<div className='callsSchedule-fontFemlly'>
							<div className='table tables mt-7 '>
								<div className='table__row table__row2 table__head'>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>ID</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Mijoz</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Telefon №1</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Telefon №2</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Manzil</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Holat</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Qo'ng'iroqlar</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Izoh qo'ng'iroqlarga</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Eslatma</div>
									<div className='table__col table__col2 callsSchedule-fontFemlly'>Tanlang</div>
								</div>

								{filteredData &&
									filteredData.map((call, index) => (
										<div key={call.id}>
											<div className='table__row table__row2'>
												{/* ID */}
												<div className='table__col table__col2'>{call.id}</div>
												<div className='table__col table__col2 flex flex-col items-center justify-center'>
													{/* NAME */}
													<div className='table__col table__col2'>{call.name}</div>
													<Stack>
														<Button
															style={{
																fontSize: '10px',
																color: '#ffffff',
																backgroundColor: '#A3A3A3',
																borderRadius: '10px',
																width: '40px',
																height: '16px',
															}}
															onClick={() => {
																handleClick();
																handleCopyClick(call.name);
															}}>
															Copy
														</Button>
														<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
															<Alert onClose={handleClose}>Nusxala olindi!</Alert>
														</Snackbar>
													</Stack>
												</div>
												{/* NAME END */}
												<div className='flex flex-col items-center justify-center'>
													{/* PHONE 1 */}
													<div className='table__col table__col2 value'>{call.phone1}</div>
													<Stack>
														<Button
															style={{
																fontSize: '10px',
																color: '#ffffff',
																backgroundColor: '#A3A3A3',
																borderRadius: '10px',
																width: '40px',
																height: '16px',
															}}
															onClick={() => {
																handleClick();
																handleCopyClick(call.phone1);
															}}>
															Copy
														</Button>
														<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
															<Alert onClose={handleClose}>Nusxa olindi!</Alert>
														</Snackbar>
													</Stack>
												</div>
												{/* PHONE 1 END */}
												<div className='flex flex-col items-center justify-center'>
													{/* PHONE 2  */}
													<div className='table__col table__col2 value'>{call.phone2}</div>
													<Stack>
														<Button
															style={{
																fontSize: '10px',
																color: '#ffffff',
																backgroundColor: '#A3A3A3',
																borderRadius: '10px',
																width: '40px',
																height: '16px',
															}}
															onClick={() => {
																handleClick();
																handleCopyClick(call.phone2);
															}}>
															Copy
														</Button>
														<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
															<Alert onClose={handleClose}>Nusxa olindi!</Alert>
														</Snackbar>
													</Stack>
												</div>
												{/* PHONE 2 END */}

												{/* ADDRESS */}
												<div className='table__col table__col2'>{call.address}</div>

												{/* HOLAT START */}
												<div className='table__col table__col2 flex flex-col'>
													<div>
														{call.is_finished == 1 ? (
															call.calls[call.calls.length - 1].status == 1 ? (
																'10% chegirma'
															) : null || call.calls[call.calls.length - 1].status == 2 ? (
																'15% chegirma'
															) : null || call.calls[call.calls.length - 1].status == 3 ? (
																'Biz mahsulot sotib oldik'
															) : null || call.calls[call.calls.length - 1].status == 4 ? (
																"O'ylashini ayti"
															) : null || call.calls[call.calls.length - 1].status == 5 ? (
																"Telefonni ko'tarmadi"
															) : null || call.calls[call.calls.length - 1].status == 6 ? (
																'Borib sotib olaman dedi'
															) : null || call.calls[call.calls.length - 1].status == 7 ? (
																'Mijoz rad edi'
															) : null
														) : (
															<div className='relative'>
																<div
																	onClick={() => {
																		setShow(!show ? call.id : false);
																		setDown(false);
																	}}
																	className='bg-[#FB8500] cursor-pointer text-white pt-2 pb-2 pl-4 pr-4 rounded-[10px] flex justify-between items-center hover:opacity-[0.8] transition duration-150 ease-out hover:ease-in'>
																	<button className='mr-4'>
																		Tanlang
																		{statu &&
																			statu.map(
																				(stat, index) =>
																					status &&
																					status.map(item =>
																						stat.id == call.id && stat.text == item.text ? <p key={stat.id}>{stat.text}</p> : null
																					)
																			)}
																	</button>
																	{show == call.id ? (
																		<svg width='11' height='6' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
																			<path
																				d='M10.0103 0.98829C10.2414 0.990304 10.4626 1.08199 10.6274 1.244L19.3546 9.97127C19.5136 10.1359 19.6016 10.3563 19.5996 10.5851C19.5976 10.814 19.5058 11.0329 19.344 11.1947C19.1822 11.3565 18.9633 11.4483 18.7345 11.4503C18.5056 11.4522 18.2852 11.3643 18.1206 11.2053L10.0103 3.09505L1.90008 11.2053C1.81957 11.2887 1.72327 11.3551 1.6168 11.4009C1.51032 11.4466 1.3958 11.4707 1.27992 11.4717C1.16404 11.4727 1.04912 11.4506 0.941865 11.4068C0.83461 11.3629 0.737168 11.2981 0.655225 11.2161C0.573282 11.1342 0.508479 11.0367 0.464597 10.9295C0.420716 10.8222 0.398634 10.7073 0.399641 10.5914C0.400648 10.4755 0.424724 10.361 0.470463 10.2546C0.516202 10.1481 0.582687 10.0518 0.666041 9.97127L9.39331 1.244C9.55806 1.08199 9.77929 0.990304 10.0103 0.98829Z'
																				fill='#fff'
																			/>
																		</svg>
																	) : (
																		<svg width='11' height='6' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
																			<path
																				d='M9.98869 11.4717C9.75764 11.4697 9.53641 11.378 9.37167 11.216L0.644399 2.48875C0.485425 2.32415 0.397459 2.1037 0.399447 1.87487C0.401435 1.64605 0.493219 1.42716 0.65503 1.26535C0.816841 1.10353 1.03573 1.01175 1.26456 1.00976C1.49338 1.00777 1.71384 1.09574 1.87844 1.25471L9.98869 9.36497L18.0989 1.25471C18.1795 1.17136 18.2758 1.10487 18.3822 1.05914C18.4887 1.0134 18.6032 0.989321 18.7191 0.988314C18.835 0.987307 18.9499 1.00939 19.0572 1.05327C19.1644 1.09715 19.2619 1.16195 19.3438 1.2439C19.4257 1.32584 19.4905 1.42328 19.5344 1.53054C19.5783 1.63779 19.6004 1.75271 19.5994 1.86859C19.5984 1.98447 19.5743 2.09899 19.5286 2.20547C19.4828 2.31194 19.4163 2.40824 19.333 2.48875L10.6057 11.216C10.441 11.378 10.2197 11.4697 9.98869 11.4717Z'
																				fill='#fff'
																			/>
																		</svg>
																	)}
																</div>
																{show == call.id && (
																	<div className='absolute bg-[#FB8500] p-2 z-20 text-white  rounded-[10px] flex flex-col top-[50px] right-0'>
																		{status.map(statu => (
																			<span
																				key={statu.id}
																				onClick={() => {
																					handleStatu(statu.text, call.id, statu.id);
																					setShow(false);
																				}}
																				className='whitespace-nowrap mb-1 cursor-pointer bg-[#FB8500] hover:bg-white hover:text-[#FB8500] text-white pt-1 pb-2 pl-3 pr-11 rounded-[10px]'>
																				{statu.text}
																			</span>
																		))}
																	</div>
																)}
															</div>
														)}
													</div>

													{/* BACHA STATUSLAR MODAL START*/}
													<div className='cursor-pointer  rounded-[10px] flex items-center justify-center mr-2'>
														<div className='relative' style={{ marginTop: '0px' }}>
															<Button
																style={{ color: '#FB8500', fontSize: '12px', textTransform: 'lowercase' }}
																onClick={() => openStatusModal(call && call)}>
																<p>barcha statuslar</p>
															</Button>
															<Modal
																style={{ overflowY: 'scroll' }}
																open={statusOpen}
																onClose={() => {
																	closeStatusModal();
																	getCalls();
																}}
																aria-labelledby='modal-modal-title'
																aria-describedby='modal-modal-description'>
																<Box className='wrapper' sx={styles} style={{ overflowY: 'scroll' }} borderRadius='8px'>
																	<img
																		onClick={() => {
																			closeStatusModal();
																			getCalls();
																		}}
																		className='absolute right-4 top-4 w-4 object-cover cursor-pointer'
																		src={closeIcon}
																		alt=''
																	/>

																	{statusInfo && statusInfo.length > 1 ? (
																		statusInfo.map((data, index) => {
																			return (
																				<div
																					className='flex justify-center items-center mb-4 border-b-2 border-[#FB8500]'
																					key={index}>
																					<div className='mr-7 mb-1'>
																						<p style={{ fontSize: '16px' }}>
																							<span className='mr-2'>{index + 1}.</span>
																							{data === '1'
																								? '10% chegirma'
																								: null || data === '2'
																								? '15% chegirma'
																								: null || data === '3'
																								? 'Biz mahsulotni sotib oldik'
																								: null || data === '4'
																								? "O'ylashini aytdi"
																								: null || data === '5'
																								? "Telefoni ko'tarmadi"
																								: null || data === '6'
																								? 'Borib sotib olaman dedi'
																								: null || data === '7'
																								? 'Mijoz rad etdi'
																								: null}
																						</p>
																					</div>
																				</div>
																			);
																		})
																	) : (
																		<h2 className='text-center mt-24 text-[#464255] text-2xl font-bold'>Status mavjud emas!</h2>
																	)}
																</Box>
															</Modal>
														</div>
													</div>
													{/* BACHA STATUSLAR MODAL END*/}
												</div>
												{/* HOLAT END */}

												{/* QO'NG'IOQLAR START */}
												<div className='table__col table__col2' style={{ display: 'flex', alignItems: 'start' }}>
													<div>
														{
															<div className='cursor-pointer  2xl:w-[28px]  2xl:h-[28px] bg-[#D9D9D9] rounded-[10px] flex items-center justify-center mr-2'>
																{/* BACHA QO'NG'IROQLAR MODAL START*/}
																<div className='relative' style={{ marginTop: '0px' }}>
																	<Button onClick={() => openModal(call && call)}>
																		<img width={20} height={20} src={recordModal} alt='recor-modal-icon' />
																	</Button>
																	<Modal
																		style={{ overflowY: 'scroll' }}
																		open={modalOpen}
																		onClose={() => {
																			closeModal();
																			getCalls();
																		}}
																		aria-labelledby='modal-modal-title'
																		aria-describedby='modal-modal-description'>
																		<Box className='wrapper' sx={styles} style={{ overflowY: 'scroll' }} borderRadius='8px'>
																			<img
																				onClick={() => {
																					closeModal();
																					getCalls();
																				}}
																				className='absolute right-4 top-4 w-4 object-cover cursor-pointer'
																				src={closeIcon}
																				alt=''
																			/>
																			{recorInfo && recorInfo.length > 0 ? (
																				recorInfo &&
																				recorInfo.map(data => {
																					return (
																						<div
																							className='flex justify-center items-center mb-4 border-b-2 border-[#FB8500]'
																							key={data.id}>
																							<div className='table__action mr-7 mb-1' onClick={e => checkPlaying(e, data.id)}>
																								{isPlaying && isPlaying == data.id ? (
																									<img src={pauseIcon} alt='action icon' />
																								) : (
																									<img src={playIcon} alt='action icon' />
																								)}
																							</div>
																							<audio
																								ref={ref => handleVideoRefs(data.id, ref)}
																								src={`${recordsUrl}/${data.record}`}></audio>
																							<div className='mb-1 flex flex-col'>
																								<p style={{ fontSize: '16px' }}>Yozib olish</p>
																								<p style={{ fontSize: '16px' }}>{data.created_at.slice(0, 10)}</p>
																							</div>
																						</div>
																					);
																				})
																			) : (
																				<h2 className='text-center mt-24 text-[#464255] text-2xl font-bold'>
																					Yozib olingan qo'ng'iroq mavjud emas!
																				</h2>
																			)}
																		</Box>
																	</Modal>
																</div>
																{/* BACHA QO'NG'IROQLAR MODAL END*/}
															</div>
														}
													</div>

													{call.is_finished == 1 ? null : (
														<>
															<div className='flex flex-col items-center justify-center '>
																<div
																	onClick={() => {
																		handleOpenFile(call.id);
																	}}
																	className='cursor-pointer  2xl:w-[28px] w-6  2xl:h-[28px] h-6 bg-[#D9D9D9] rounded-[10px] flex items-center justify-center mb-1'>
																	<img width={20} height={20} src={dowland} alt='dowland' />
																</div>
																<p>
																	{selectedFiles &&
																		selectedFiles.map(file => {
																			if (file.id == call.id && file.name) {
																				return file.name.slice(0, 15);
																			}
																			return null;
																		})}{' '}
																	{selectedFiles &&
																		selectedFiles.map(file => file.id == call.id && file.name && file.name.length > 16 && '...')}
																</p>
															</div>
															<input
																ref={fileRef}
																type='file'
																hidden
																accept='.m4a, .mpeg, .mpga, .mp3, .wav'
																onChange={e => {
																	handleFileChange(e);
																	pickFiles(e);
																}}
															/>
														</>
													)}

													<div>
														{call.is_finished == 1 ? (
															<div
																className=' cursor-pointer 2xl:w-[28px] w-6  2xl:h-[28px] h-6  bg-[#D9D9D9] rounded-full flex items-center justify-center'
																onClick={e => checkPlaying(e, call.id)}>
																{isPlaying && isPlaying == call.id ? (
																	<img width={12} height={12} src={pauseIcon} alt='action icon' />
																) : (
																	<img width={12} height={12} src={playIcon} alt='action icon' />
																)}
															</div>
														) : null}

														{call.is_finished == 1 ? (
															<audio
																ref={ref => handleVideoRefs(call.id, ref)}
																src={`${recordsUrl}/${
																	call.calls[call.calls.length - 1] && call.calls[call.calls.length - 1].record
																		? call.calls[call.calls.length - 1] && call.calls[call.calls.length - 1].record
																		: ''
																}`}></audio>
														) : null}
													</div>
													{/* {call.calls.length > 0 ? (
													<div className='flex items-center ml-2'>
														<div className='flex flex-col'>
															<p className='whitespace-nowrap'>Yozib olish</p>
															<p className='whitespace-nowrap'>{call && call.calls[0].date}</p>
														</div>
													</div>
												) : null} */}
												</div>
												{/* QO'NG'IROQLAR END */}

												{/* Izoh qo'ng'iroqlarga START */}
												<div className='table__col table__col2 relative flex flex-col'>
													<div>
														{call.is_finished == 1 ? (
															call.calls[call.calls.length - 1].comment.slice(0, 50)
														) : (
															<>
																<div className='flex flex-col items-center justify-center'>
																	<p className='text-start ml-1 mb-1'>
																		{displayText &&
																			displayText.map(comment => comment.id == call.id && comment.comment.slice(0, 60))}
																		{displayText &&
																			displayText.map(comment => comment.id == call.id && comment.comment.length > 61 && '...')}
																	</p>

																	<div
																		onClick={() => {
																			setDown(!down ? call.id : false);
																			setShow(false);
																			setTextAr(call.id);
																		}}>
																		<button className='pt-2 pl-8  pb-2 pr-8  text-white bg-[#2D9CDB]  rounded-[10px] border-[1px] border-transparent hover:bg-white hover:text-[#2d9cdb] hover:border-[1px] hover:border-[#2d9cdb] transition duration-150 ease-out hover:ease-in '>
																			Izoh
																		</button>
																	</div>
																</div>

																{down == call.id && (
																	<>
																		<form
																			onSubmit={e => handleSubmit(e, call)}
																			action=''
																			className='absolute  rounded-[10px] top-[60px] right-0 bg-[#2D9CDB]  z-20 pt-3 pl-2 pb-3 pr-2'>
																			<textarea
																				defaultValue={displayText.map(comment =>
																					comment.id == call.id ? comment.comment : inputText
																				)}
																				onChange={handleTextAray}
																				placeholder='izoh qoldiring'
																				className='resize-none min-w-[230px] rounded-[10px] text-[#343434] p-2'></textarea>
																			<button
																				type='submit'
																				className='text-[#2D9CDB] mt-2 float-right pt-1 pl-4 pb-1 pr-4 bg-white rounded-[10px] hover:opacity-[0.8] transition duration-150 ease-out hover:ease-in'>
																				Saqlash
																			</button>
																		</form>
																	</>
																)}
															</>
														)}
													</div>

													{/* BACHA IZOHLAR MODAL START*/}
													<div className='cursor-pointer  rounded-[10px] flex items-center justify-center mr-2'>
														<div className='relative' style={{ marginTop: '0px' }}>
															<Button
																style={{ color: '#FB8500', fontSize: '12px', textTransform: 'lowercase' }}
																onClick={() => openCommenModal(call && call)}>
																<p>barcha izohlar</p>
															</Button>
															<Modal
																style={{ overflowY: 'scroll' }}
																open={commenOpen}
																onClose={() => {
																	closeCommenModal();
																	getCalls();
																}}
																aria-labelledby='modal-modal-title'
																aria-describedby='modal-modal-description'>
																<Box className='wrapper' sx={styles} style={{ overflowY: 'scroll' }} borderRadius='8px'>
																	<img
																		onClick={() => {
																			closeCommenModal();
																			getCalls();
																		}}
																		className='absolute right-4 top-4 w-4 object-cover cursor-pointer'
																		src={closeIcon}
																		alt=''
																	/>

																	{commentInfo && commentInfo.length > 0 ? (
																		commentInfo &&
																		commentInfo.map((data, index) => {
																			return (
																				<div
																					className='flex justify-center items-center mb-4 border-b-2 border-[#FB8500]'
																					key={data.id}>
																					<div className='mr-7 mb-1'>
																						<p style={{ fontSize: '16px' }}>
																							<span className='mr-2'>{index + 1}.</span>
																							{data.comment}
																						</p>
																					</div>
																				</div>
																			);
																		})
																	) : (
																		<h2 className='text-center mt-24 text-[#464255] text-2xl font-bold'>
																			Qo'ng'iroqlarga izoh mavjud emas!
																		</h2>
																	)}
																</Box>
															</Modal>
														</div>
													</div>
													{/* BACHA IZOHLAR MODAL END*/}
												</div>

												{/* Izoh qo'ng'iroqlarga END */}

												{/* Eslatma START */}
												<div className='table__col table__col2 flex flex-col'>
													<div>
														<ModalForm
															disablet={call.is_finished}
															nameCLient={call.name}
															clientId={call.id}
															notification={notification}
														/>
													</div>

													{/* BACHA NOTIFICATION MODAL START*/}
													<div className='cursor-pointer  rounded-[10px] flex items-center justify-center mr-2 '>
														<div className='relative' style={{ marginTop: '0px' }}>
															<Button
																style={{ color: '#FB8500', fontSize: '12px', textTransform: 'lowercase' }}
																onClick={() => openNotificationModal(call && call)}>
																<p>barcha eslatmalar</p>
															</Button>
															<Modal
																style={{ overflowY: 'scroll' }}
																open={openNotification}
																onClose={() => {
																	closeNotificationModal();
																	getCalls();
																}}
																aria-labelledby='modal-modal-title'
																aria-describedby='modal-modal-description'>
																<Box className='wrapper' sx={styles} style={{ overflowY: 'scroll' }} borderRadius='8px'>
																	<img
																		onClick={() => {
																			closeNotificationModal();
																			getCalls();
																		}}
																		className='absolute right-4 top-4 w-4 object-cover cursor-pointer'
																		src={closeIcon}
																		alt=''
																	/>

																	{notificationInfo && notificationInfo.length > 0 ? (
																		notificationInfo &&
																		notificationInfo.map((data, index) => {
																			return (
																				<div
																					className='flex justify-center items-center mb-4 border-b-2 border-[#FB8500]'
																					key={data.id}>
																					<div className='mr-7 mb-1 flex'>
																						<span className='font-bold text-xl mr-2'>{index + 1}.</span>
																						<div className='text-[#464255]'>
																							<h2 className='font-bold text-xl'>{data.title}</h2>
																							{data.description.length > 180 ? (
																								<p style={{ fontSize: '16px' }}>{data.description.slice(0, 180)} ...</p>
																							) : (
																								<p style={{ fontSize: '16px' }}>{data.description.slice(0, 180)}</p>
																							)}
																						</div>
																					</div>
																				</div>
																			);
																		})
																	) : (
																		<h2 className='text-center mt-24 text-[#464255] text-2xl font-bold'>
																			Eslatmalar mavjud emas!
																		</h2>
																	)}
																</Box>
															</Modal>
														</div>
													</div>
													{/* BACHA NOTIFICATION MODAL END*/}
												</div>
												{/* ESLATMA END */}

												<div className='table__col table__col2 flex flex-col items-center justify-center'>
													{call.is_finished == 1 ? (
														<Link
															className='py-2 px-3 cursor-pointer font-normal text-xs border-[1px] border-transparent bg-[#00B074] text-white rounded-xl hover:text-[#00B074] hover:bg-white hover:border-[#00B074]  transition duration-150 ease-out hover:ease-in'
															to={`/operator-profile/${call.id}`}>
															Ko'proq
														</Link>
													) : null}
													{call.is_finished == 1 ? null : (
														<button
															onClick={() => {
																createCallSubmit(call.user_id, call.updated_at.slice(0, 10), call.id);
															}}
															className='mb-2 py-2 px-3 cursor-pointer font-normal text-xs border-[1px] border-transparent bg-[#00B074] text-white rounded-xl hover:text-[#00B074] hover:bg-white hover:border-[#00B074]  transition duration-150 ease-out hover:ease-in'>
															Bajarildi
														</button>
													)}

													{call.is_finished == 1 ? null : (
														<button
															onClick={() => {
																delay(call.user_id, call.id);
															}}
															className='py-2 px-3 cursor-pointer font-normal text-xs border-[1px] border-transparent bg-[#FF6501] text-white rounded-xl hover:text-[#FF6501] hover:bg-white hover:border-[#FF6501]  transition duration-150 ease-out hover:ease-in'>
															Kechiktirish
														</button>
													)}
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					)}
				</div>
				{total <= perPage ? null : (
					<Pagination
						className='pagination mt-8'
						count={Math.ceil(total == null ? 0 : total / perPage)}
						color='primary'
						variant='outlined'
						defaultValue={page}
						page={page}
						onChange={change}
					/>
				)}
			</div>
		</>
	);
};

export default CallSchedule;
