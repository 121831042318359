import { Box } from '@mui/material';
import budunic from '../images/budunic-icon.svg';
import close from '../images/close.svg';
import stroke from '../images/stroke.svg';
import subtract from '../images/Subtract.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const style = {
	textAlign: 'center',
};

const Notification = props => {
	const [show, setShow] = useState(true);

	return (
		<>
			<div className=''>
				{/* Kun uchun yangi reja */}
				{/* {show && (
					<div className='flex items-center border-b-[1px] pb-4 mb-4'>
						<img className='mr-5' src={subtract} alt='Subtract' width={16} height={20} />
						<div className='mr-auto text-base'>
							<h4 className='text-[#E7983E] mb-2 font-semibold'>Kun uchun yangi reja</h4>
							<p className='text-black font-medium '>Qo'ng'iroqlar: {props.filteredItems && props.filteredItems.length}</p>
						</div>
						<button className='cursor-pointer' onClick={() => setShow(false)}>
							<img src={close} alt='close' />
						</button>
					</div>
				)} */}
				{props.filteredItems &&
					props.filteredItems.map(data => {
						return (
							<div key={data.id}>
								{/* Eslatma */}
								<button className='float-right w-4 h-4' onClick={() => props.handleItemClick(data.id)}>
									<img className='cursor-pointer notification-img' src={close} alt='close' width={15} height={15} />
								</button>
								<div className=' pb-3 mb-2 border-b-[1px]'>
									<div className='flex items-center '>
										<img className='mr-5' src={stroke} alt='Subtract' width={16} height={20} />

										<div className='mr-auto text-base'>
											<h4 className='text-[#E7983E] mb-2 font-semibold'>{data.title}</h4>
											<p className='text-black font-medium mb-2 '>{data.description}</p>
										</div>
									</div>
									<Link className='underline decoration-1 text-cyan-500' to={'/reminder'}>
										Eslatma sahifasiga o'tish
									</Link>
								</div>
							</div>
						);
					})}
				{props.showMore && props.filteredItems && props.filteredItems.length > 1 ? (
					<Box
						sx={style}
						className='text-[#FF6501] font-semibold text-base text-center cursor-pointer notification-text'
						onClick={props.handleShowAllClick}>
						Hammasini ko'rish
					</Box>
				) : null}
			</div>
		</>
	);
};

export default Notification;
