import axios from 'axios';
import { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import defaultIcon from '../images/user.png';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendAdmin } from '../redux/actions/adminAction';
import { Pagination } from '@mui/material';
import searchIcon from '../images/search-icon.svg';
import { Header } from '../components';

const Operator = () => {
	const navigateAuth = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState();
	const [perPage, setPerPage] = useState();
	const token = Cookies.get('token');

	const change = (event, value) => {
		setPage(value);
	};

	useEffect(() => {
		if (!token) {
			navigateAuth('/auth');
		}
		getOperators();
	}, [page]);
	const imageUrl = 'https://crm.orzugrand.uz/images';

	const [operators, setOperators] = useState();

	const getOperators = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/user?page=${page}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res.data.data;
				setOperators(operators.data);
				setTotal(operators.total);
				setPerPage(operators.per_page);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	const dispatch = useDispatch();
	const isAdmin = useSelector(state => state.sendAdmin);
	const admin = value => {
		dispatch(sendAdmin(value));
	};

	const [inputValue, setInputValue] = useState('');
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		// Filter the data based on the input value whenever the input value changes
		const filtered =
			operators &&
			operators.filter(
				item =>
					item.name.toLowerCase().includes(inputValue.toLowerCase()) || item.city.toLowerCase().includes(inputValue.toLowerCase())
			);

		setFilteredData(operators && filtered);
	}, [inputValue, operators]);

	const handleInputChange = event => {
		const { value } = event.target;
		setInputValue(value);
	};

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input
						type='search'
						name='search'
						value={inputValue}
						onChange={handleInputChange}
						placeholder="Xodimlarni ismi va joylashuvi bo'yicha qidirish"
					/>

					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<div className='content'>
				{isLoading && <Spinner />}
				<div className='boxes'>
					<div className='boxes__item'>
						<div className='boxes__top'>
							<div className='head'>
								<h2 className='title'>Menejerlar</h2>
								<div className='greytitle'>Menejerlarning umumiy soni</div>
							</div>
							<Link to='/create-user' className='btn btn_green' onClick={() => admin(0)}>
								Qo&apos;shish
							</Link>
						</div>
						<div className='boxes__section'>
							{filteredData &&
								filteredData.map(
									person =>
										person.is_admin == 0 && (
											<div className='boxes__card' key={person.id}>
												<div className='boxes__main'>
													<img
														src={person && person.image !== null ? `${imageUrl}/${person.image}` : defaultIcon}
														alt='operator img'
													/>
													<div className='boxes__info'>
														<div className='boxes__name'>{person.name}</div>
														<div className='boxes__collector green'>Operator: {person.name}</div>
													</div>
												</div>

												<div style={{ color: ' #FF6501' }} className='boxes__profession green'>
													Menejer
												</div>
											</div>
										)
								)}
						</div>
					</div>

					<div className='boxes__item'>
						<div className='boxes__top'>
							<div className='head'>
								<h2 className='title'>Bo'lim boshlig'i</h2>
								<div className='greytitle'>Adminlarning umumiy soni</div>
							</div>

							<Link to='/create-user' className='btn btn_green' onClick={() => admin(1)}>
								Qo&apos;shish
							</Link>
						</div>
						<div className='boxes__section'>
							{filteredData &&
								filteredData.map(
									person =>
										person.is_admin == 1 && (
											<div className='boxes__card' key={person.id}>
												<div className='boxes__main'>
													<img
														src={person && person.image !== null ? `${imageUrl}/${person.image}` : defaultIcon}
														alt='operator img'
													/>
													<div className='boxes__info'>
														<div className='boxes__name'>{person.name}</div>
														<div className='boxes__collector green'>Kollektor:{person.name}</div>
													</div>
												</div>

												<div className='boxes__profession orange'>Bo'lim B</div>
											</div>
										)
								)}
						</div>
					</div>
				</div>
			</div>

			{total <= perPage ? null : (
				<Pagination
					className='pagination mt-8'
					count={Math.ceil(total / perPage)}
					color='primary'
					variant='outlined'
					defaultValue={page}
					page={page}
					onChange={change}
				/>
			)}
		</>
	);
};

export default Operator;
