import authIcon from '../images/auth-1.svg';
import authIcon2 from '../images/auth-2.svg';
import authIcon3 from '../images/eye-closed.svg';
import authIcon4 from '../images/eye.svg';
import banner from '../images/auth-banner.png';
import logos from '../images/logos.svg';
import $ from 'jquery';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';

const Auth = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	$('.auth__eye').on({
		mousedown: function () {
			$(this).attr('src', authIcon4);
			$(this).siblings('input').attr('type', 'text');
		},

		mouseup: function () {
			$(this).attr('src', authIcon3);
			$(this).siblings('input').attr('type', 'password');
		},
	});

	const token = Cookies.get('token');
	const [email, setEmail] = useState('Admin@gmail.com');
	const [password, setPassword] = useState('password');

	const handleSubmit = async e => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('email', email);
		formData.append('password', password);

		await axios
			.post(`https://crm.orzugrand.uz/api/login`, formData, {
				headers: {
					'Content-Type': 'multiple/formdata',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				if (res.data.data) {
					Cookies.set('token', `Bearer ${res.data.data.token}`);
					if (res.data.data.user.is_admin != 0) {
						navigate('/');
						const payload = res.data.data.user;
						localStorage.setItem('user', JSON.stringify(payload));
						dispatch({ type: 'SEND_USER', payload });
					} else {
						navigate('/calls-schedule');
						const payload = res.data.data.user;
						localStorage.setItem('user', JSON.stringify(payload));
						dispatch({ type: 'SEND_USER', payload });
					}
				}
			})
			.catch(er => {
				// console.log(er);
				alert(er.response.data.data);
			});
	};

	return (
		<>
			<div className='auth'>
				<div className='auth__inner'>
					<div className='auth__banner' style={{ backgroundImage: `url(${banner})` }}>
						Donolikning asosi - xotirjamlik va sabr-toqat
					</div>

					<div className='auth__content '>
						<img className='auth__logo' src={logos} alt='auth logo' />
						<h2 className='auth__title'>
							<span>ORZU GRAND</span> Qo&apos;ng'roqlar hisob tizimiga Xush kelibsiz
						</h2>

						<form onSubmit={handleSubmit} className='auth__form'>
							<div className='auth__naming'>
								<span>Shaxsiy kabinetga kirish</span>
							</div>
							<div className='auth__box'>
								<div className='auth__wrapper'>
									<img className='auth__icon' src={authIcon} alt='auth icon' />
									<input
										autoComplete='off'
										onChange={e => setEmail(e.target.value)}
										required
										type='email'
										name='email'
										placeholder='Email'
									/>
								</div>
							</div>

							<div className='auth__box'>
								<div className='auth__wrapper'>
									<img className='auth__icon' src={authIcon2} alt='auth icon' />
									<input
										autoComplete='off'
										required
										onChange={e => setPassword(e.target.value)}
										type='password'
										name='pass'
										placeholder='Parol'
									/>
									<img className='auth__eye' src={authIcon3} alt='auth icon' />
								</div>
							</div>

							<button className='auth__btn'>Kirish</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Auth;
