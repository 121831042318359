import React, { useState } from 'react';
import MessageIcon from '../images/message-icon.svg';
import Close from '../images/close.svg';
import Pusher from 'pusher-js';
import defaultIcon from '../images/user.png';
import { useEffect } from 'react';
import { Alert, Snackbar, Stack } from '@mui/material';
import axios from 'axios';

const MessageAler = props => {
	const imageUrl = 'https://crm.orzugrand.uz/images';

	// USER
	const userInfo = JSON.parse(localStorage.getItem('user'));
	// USER END

	// SNACKBAR START
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	// SNACKBAR END

	const [alert, setAlert] = useState('');
	const getAlert = async () => {
		await axios
			.get(`https://chat.orzugrand.uz/api/alert/${userInfo && userInfo.id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			})
			.then(res => {
				const chats = res.data;
				setAlert(chats);
			})
			.catch(er => {
				// console.log(er);
			});
	};

	useEffect(() => {
		getAlert();
	}, []);

	// SNACKBAR END

	useEffect(() => {
		const pusher = new Pusher('fd7742683dadae1ce63c', {
			cluster: 'ap2',
		});

		const channel = pusher.subscribe(`message${userInfo && userInfo.id}`);
		channel.bind('message', function (data) {
			{
				data && handleClick();
			}
			getAlert();
			return () => {
				pusher.unsubscribe(`message${userInfo && userInfo.id}`);
			};
		});
	}, []);

	useEffect(() => {
		const pusher = new Pusher('fd7742683dadae1ce63c', {
			cluster: 'ap2',
		});

		// Pusher kanali
		const channel = pusher.subscribe(`clik${userInfo && userInfo.id}`);

		channel.bind('clik', function (data) {
			getAlert();
			return () => {
				pusher.unsubscribe(`clik${userInfo && userInfo.id}`);
			};
		});
	}, []);

	const initialTotalCount = 0;

	const totalCount = alert.length > 0 ? alert.reduce((total, obj) => total + Number(obj.count), initialTotalCount) : 0;

	localStorage.setItem('raqam', totalCount);

	return (
		<div className='button-container'>
			{/* XABAR KELDI */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
						Xabar keldi!
					</Alert>
				</Snackbar>
			</Stack>
			<button className='click-button'>
				<div
					className='header__notification blue'
					onClick={() => {
						props.handleClose();
						getAlert();
						props.setShowAlert(!props.showAlert);
					}}>
					<img src={MessageIcon} alt='MessageIcon icon' />
					<div className='header__badge'>{totalCount}</div>
				</div>
			</button>
			{props.showAlert && (
				<div>
					<div className='alert-animation wrapper'>
						<img
							onClick={() => {
								props.setShowAlert(false);
							}}
							src={Close}
							alt='close-icon'
							className='float-right cursor-pointer'
						/>
						<div className='pt-5'>
							{alert.length > 0 ? (
								props.showAlert &&
								alert.map(item => {
									return (
										<div className='flex flex-col'>
											<div className='flex items-center mb-5 w-[95%]'>
												<img
													className='mr-4 w-14 h-14 rounded-full object-cover'
													src={item.image ? `${imageUrl}/${item.image}` : defaultIcon}
													alt='message-icon'
												/>
												<div className='mr-auto'>
													<h2 className='text-base font-semibold text-[#464255] mb-2'>{item.name ? item.name : '-'}</h2>
													<p className='text-xs font-medium'>
														{item ? (item.text.length > 20 ? item.text.slice(0, 21) : item.text) : '-'}
													</p>
												</div>
												<div>
													<p className='items-start'>{item ? item.time.slice(11, 16) : '-'}</p>
													<div className='w-[22px] h-[22px] rounded-full flex items-center justify-center bg-[#7684B6]'>
														<span className='text-white'>{item.count}</span>
													</div>
												</div>
											</div>

											<div className='bg-[#F1F1F1] h-[1px] mb-3'></div>
										</div>
									);
								})
							) : (
								<h2 className='text-center font-bold '>Xabar mavjud emas!</h2>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MessageAler;
