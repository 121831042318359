import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import Cookies from 'js-cookie';
import { Pagination, Snackbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { TopMini } from '../components';
import { Header } from '../components';
import searchIcon from '../images/search-icon.svg';
import MuiAlert from '@mui/material/Alert';
import { Stack } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const headTitle = "To'lov qilmagan mijozlar";
const greyText = `Bu yerda siz barcha operatorlar uchun barcha qo'ng'iroqlarni
ko'rishingiz yoki kerakli operator bo'yicha tartiblashingiz mumkin`;

const AddClients = () => {
	const navigate = useNavigate();
	const sessionStorage = window.sessionStorage;
	const [isLoading, setIsLoading] = useState(false);

	const token = Cookies.get('token');
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState();
	const [perPage, setPerPage] = useState();

	const change = (event, value) => {
		setPage(value);
	};

	useEffect(() => {
		if (!token) {
			navigate('/auth');
		}
		getAddClients();
		// fromChild();
		sessionStorage.setItem('operatorId', '');
		window.addEventListener('beforeunload', function () {
			sessionStorage.setItem('operatorId', '');
		});
	}, [page]);

	const [checkedItems, setCheckedItems] = useState([]);
	const [snackbar, setSnackbar] = useState(null);
	const [checketCount, setChecketCount] = useState(0);

	const selectHandler = (e, elem) => {
		setSnackbar(e.target.checked);
		if (e.target.checked) {
			setCheckedItems([...checkedItems, elem.id]);
			setChecketCount(checkedItems.length + 1);
		} else {
			setChecketCount(checkedItems.length - 1);
			checkedItems.splice(checkedItems.indexOf(elem.id), 1);
		}
	};

	// SNACKBAR START
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const handleSnackbarClick = () => {
		if (!snackbar) {
			setOpenSnackbar(true);
		}
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};
	// SNACKBAR END

	const [addClient, setAddClient] = useState();

	const getAddClients = async () => {
		setIsLoading(true);
		await axios
			.get(`https://crm.orzugrand.uz/api/client?page=${page}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const addClient = res.data.data[0];
				setDataForSearch(res.data.data[1].map((e, i) => ({ ...e, listOrder: i + 1 })));
				setAddClient(addClient.data.map((e, i) => ({ ...e, listOrder: i + 1 + (page - 1) * 15 })));
				setTotal(addClient.total);
				setPerPage(addClient.per_page);
				setIsLoading(false);
			})
			.catch(er => {
				// console.log(er);
				setIsLoading(true);
			});
	};

	const clearCheckedItems = () => {
		setCheckedItems([]);
		getAddClients();
	};

	const [inputValue, setInputValue] = useState('');
	const [filteredData, setFilteredData] = useState([]);
	const [dataForSearch, setDataForSearch] = useState([]);

	const handleInputChange = event => {
		const { value } = event.target;
		setInputValue(value);
	};

	useEffect(() => {
		if (inputValue.trim() === '') {
			setFilteredData(addClient);
			return;
		}
		const filtered =
			dataForSearch &&
			dataForSearch.filter(
				item =>
					item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
					item.address.toLowerCase().includes(inputValue.toLowerCase())
			);

		setFilteredData(dataForSearch && filtered);
	}, [inputValue, dataForSearch]);

	return (
		<>
			{/* CHECKED XATO */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
					<Alert severity='error'>Mijoz tanlang!</Alert>
				</Snackbar>
			</Stack>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					<input
						type='search'
						name='search'
						value={inputValue}
						onChange={handleInputChange}
						placeholder="Mijoz ismi va manzili bo'yicha qidirish"
					/>

					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			{isLoading && <Spinner />}
			{addClient && (
				<div className='wrapper'>
					<TopMini
						checketCount={checketCount}
						setChecketCount={setChecketCount}
						handleSnackbarClick={handleSnackbarClick}
						checkedItems={checkedItems}
						handleChange={getAddClients}
						addClient={addClient}
						token={token}
						title={headTitle}
						grey={greyText}
						clearCheckedItems={clearCheckedItems}
					/>
					<div className='table table__row-wrapper'>
						<div className='table__row table__row3 table__head'>
							<div className='table__col'>#</div>
							<div className='table__col'>Tanlang</div>
							<div className='table__col'>Mijoz</div>
							<div className='table__col'>Telefon №1</div>
							<div className='table__col'>Telefon №2</div>
							<div className='table__col'>
								Mijoz <br /> manzili
							</div>
							<div className='table__col'>Izoh</div>
							<div className='table__col'>Qo'ng'iroqlar</div>
							<div className='table__col'>
								Izoh <br /> qo'ng'iroqlarga
							</div>
							<div className='table__col'>Qarang</div>
						</div>

						{filteredData &&
							filteredData.map((data, index) => {
								return (
									<div className='table__row table__row3' key={data.id}>
										{/* ID */}
										<div className='text-center'>{data.listOrder || index + 1}</div>
										{/* BARCHASINI TANLASH */}

										<div className='table__col'>
											<input
												type='checkbox'
												onChange={e => {
													selectHandler(e, data);
												}}
											/>
										</div>
										{/* NAME */}
										<div className='table__col'>{data.name}</div>
										{/* TELL */}
										<div className='table__col text-center'>{data.phone1}</div>
										{/* TELL2 */}
										<div className='table__col text-center'>{data.phone2}</div>
										{/* ADDRES */}
										<div className='table__col'>{data.address}</div>
										{/* HOLAT */}
										<div className='table__col text-center'>{data.comment ? data.comment : '-'}</div>
										{/* CALLS */}
										<div className='table__col'>Yo'q</div>
										{/* Izoh qo'ng'iroqlarga */}
										<div className='table__col'>Yo'q</div>

										{/* KO"PROQ */}
										<div className='table__col'>
											<Link
												className='2xl:py-3 py-2 2xl:px-5 px-4 font-normal text-xs border-[1px] border-transparent bg-[#00B074] text-white rounded-xl hover:text-[#00B074] hover:bg-white hover:border-[#00B074]  transition duration-150 ease-out hover:ease-in'
												to={`/clientmoreinfo/${data.id}`}>
												Ko'proq
											</Link>
										</div>
									</div>
								);
							})}
						{total <= perPage ? null : (
							<Pagination
								className='pagination mt-8'
								count={Math.ceil(total / perPage)}
								color='primary'
								variant='outlined'
								defaultValue={page}
								page={page}
								onChange={change}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default AddClients;
