import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Return from '../components/Return';
import $ from 'jquery';
import ClientCard from '../components/ClientCard';
import SalesHistory from '../components/SalesHistory';
import searchIcon from '../images/search-icon.svg';
import { Header } from '../components';

const OperatorProfile = () => {
	const navigateAuth = useNavigate();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const token = Cookies.get('token');
	const [down, setDown] = useState(1);

	useEffect(() => {
		if (!token) {
			navigateAuth('/auth');
		}
		getProfile();
	}, []);

	const [profile, setProfile] = useState();
	const getProfile = async () => {
		setIsLoading(true);

		await axios
			.get(`https://crm.orzugrand.uz/api/client/${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const profile = res.data.data;
				setProfile(profile);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	const [istory] = Object.values(profile && profile.calls ? profile.calls : []);

	const [inputValue, setInputValue] = useState('');
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		const filtered =
			istory &&
			istory.filter(
				item =>
					item.user.name.toLowerCase().includes(inputValue.toLowerCase()) ||
					item.user.city.toLowerCase().includes(inputValue.toLowerCase())
			);

		setFilteredData(istory && filtered);
	}, [inputValue, istory]);

	const handleInputChange = event => {
		const { value } = event.target;
		setInputValue(value);
	};

	return (
		<>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search opacity-0'>
					<input
						type='search'
						name='search'
						value={inputValue}
						onChange={handleInputChange}
						placeholder="Xodimlarni ismi va joylashuvi bo'yicha qidirish"
					/>

					<img src={searchIcon} alt='search icon' />
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<Return title='Jadvalga qaytish' navigate={-1} />
			<div className='max-w-5xl w-full 2xl:mb-14 mb-10'>
				<h2 className='text-[#464255] 2xl:text-[28px] text-2xl font-bold'>Mijoz kartasi</h2>
				<p className='greytitle'>
					Bu erda siz mijoz haqida batafsil ma'lumot olishingiz va menejerning ishi bilan batafsil tanishishingiz va butun voqeani
					o'qishingiz mumkin. ushbu mijoz uchun va qo'ng'iroq yozuvlari va sharhlarini tinglang va mijoz bilan aloqaning butun
					tarixini kuzatib boring.
				</p>
			</div>
			<div className=''>
				<div className='flex items-center 2xl:mb-12 mb-9'>
					<div
						onClick={() => {
							setDown(1);
						}}
						className={
							down == 1
								? ' 2xl:mr-4 mr-3 py-2 2xl:px-5 px-4 bg-[#FB8500] text-white rounded-[10px] font-medium 2xl:text-lg text-base leading-[21.6px] border-[1px] border-transparent  hover:bg-white hover:text-[#FB8500] hover:border-[#FB8500]'
								: '2xl:mr-4 mr-3 py-2 2xl:px-5 px-4 bg-white text-black rounded-[10px] font-medium 2xl:text-lg text-base leading-[21.6px] hover:opacity-80'
						}>
						<Link to={``}>Mijoz kartasi</Link>
					</div>

					<div
						onClick={() => {
							setDown(2);
						}}
						className={
							down == 2
								? ' 2xl:mr-4 mr-3 py-2 2xl:px-5 px-4 bg-[#FB8500] text-white rounded-[10px] font-medium 2xl:text-lg text-base leading-[21.6px] border-[1px] border-transparent  hover:bg-white hover:text-[#FB8500] hover:border-[#FB8500]'
								: '2xl:mr-4 mr-3 py-2 2xl:px-5 px-4 bg-white text-black rounded-[10px] font-medium 2xl:text-lg text-base leading-[21.6px] hover:opacity-80'
						}>
						<Link to={``}>Sotish tarixi</Link>
					</div>
				</div>

				{down == 1 ? <ClientCard profile={profile} isLoading={isLoading} /> : null}
				{down == 2 ? <SalesHistory profile={profile} filteredData={filteredData} isLoading={isLoading} /> : null}

				<Return title='Jadvalga qaytish' classes='second-view' navigate={-1} />
			</div>
		</>
	);
};

export default OperatorProfile;
