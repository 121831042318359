// import dotsIcon from '../images/dots.svg';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { Doughnut, Line } from 'react-chartjs-2';
import topIcon3 from '../images/calendar-icon.svg';
import topArrow from '../images/top-arrow.svg';
import globos from '../images/globe-icon.svg';
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import dowlandIcon from '../images/dowland-icon.svg';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { Header } from '../components';
import searchIcon from '../images/search-icon.svg';
import { Link, useNavigate } from 'react-router-dom';

const citys = [
	{ id: 1, name: "Farg'ona" },
	{ id: 2, name: 'Namangan' },
	{ id: 3, name: 'Samarqand' },
	{ id: 4, name: 'Toshkent' },
];

const selectMonths = [
	{ id: 1, name: '1 kunlikni yuklash' },
	{ id: 2, name: '1 oylikni yuklash' },
];

ChartJS.register(ArcElement, Tooltip, Legend);

export const data3 = {
	datasets: [
		{
			data: [80, 20],
			backgroundColor: ['#00B074', 'rgba(0, 176, 116, 0.15)'],

			options: {
				responsive: false,
				borderWidth: false,
			},
		},
	],
};

export const data5 = {
	datasets: [
		{
			data: [80, 20],
			backgroundColor: ['#6FC4F4', 'rgba(111, 196, 244, 0.15)'],

			options: {
				responsive: false,
				borderWidth: false,
			},
		},
	],
};

const Stats = () => {
	const navigate = useNavigate();
	const userInfo = JSON.parse(localStorage.getItem('user'));
	const [analitic, setAnalitic] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const token = Cookies.get('token');
	const [totalCall, setTotalCall] = useState();
	const [statsMonth, setStatsMonth] = useState([]);
	const [calenderValues, setCalenderValues] = useState(new Date());
	const from = calenderValues && calenderValues.length > 0 ? moment(calenderValues[0]).format('YYYY-MM-DD') : '';
	const to = calenderValues && calenderValues.length > 0 ? moment(calenderValues[1]).format('YYYY-MM-DD') : '';

	const [dateState, setDateState] = useState(new Date());
	const changeDate = e => {
		setDateState(e);
	};

	const [city, setCity] = useState('');

	const onCity = e => {
		setCity(e);
	};

	const [selectMonth, setSelectMonths] = useState('');
	const [selectMontDown, setSelectMontDown] = useState(false);
	const [selectXLS, setSelectXLS] = useState(1);

	const onDays = (e, id) => {
		setSelectMonths(e);
		setSelectXLS(id);
	};

	useEffect(() => {
		postAnalitk();
		if (!token) {
			navigate('/auth');
		}
	}, [dateState, city, from, to]);

	const [yearsValue, setYearsValue] = useState(0);
	const [monthlyValue, setMonthlyValue] = useState(0);
	const [menadjerValue, setMenadjerValue] = useState(0);
	const [ropValue, setRopValue] = useState(0);
	const [boughtValue, setBoughtValue] = useState(0);

	const postAnalitk = async () => {
		setIsLoading(true);
		await axios
			.get(
				`https://crm.orzugrand.uz/api/analitics?data=${moment(dateState).format('YYYY-MM-DD')}&${
					city ? `&city=${city}` : ''
				}&from=${from}&to=${to}`,
				{
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: token,
					},
				}
			)
			.then(response => {
				setIsLoading(true);
				const dataAnalitic = response.data.data;

				setYearsValue(Math.min(dataAnalitic.year, dataAnalitic.menadjer * 300));
				setMonthlyValue(Math.min(dataAnalitic.monthly, dataAnalitic.menadjer * 300));
				setMenadjerValue(Math.min(dataAnalitic.menadjer, dataAnalitic.menadjer * 300));
				setRopValue(Math.min(dataAnalitic.rop, dataAnalitic.menadjer * 300));
				setBoughtValue(Math.min(dataAnalitic.bought, dataAnalitic.menadjer * 300));
				setAnalitic(dataAnalitic);
				const lineArray = [];
				const lineArrayCalls = [];

				dataAnalitic.line_chart.map(line => {
					lineArray.push(line.month);
					lineArrayCalls.push(line.total_call);
					setStatsMonth(lineArray);
					setTotalCall(lineArrayCalls);
				});
				setIsLoading(false);
			})
			.catch(error => {
				setIsLoading(true);
				console.error('Error fetching data:', error);
			});
	};

	const lineData = {
		labels: statsMonth.reverse(),
		datasets: [
			{
				data: totalCall,
				borderColor: '#2D9CDB',
				fill: false,
				options: {
					responsive: false,
					borderWidth: false,
					legend: {
						display: false,
					},
					tooltips: {
						enabled: false,
					},
				},
			},
		],
	};

	// DATEPICKER
	const [isCalenderActive, setIsCalenderActive] = useState(false);
	const calendarRef = useRef();

	const [show, setShow] = useState(false);
	const [down, setDown] = useState(false);

	useEffect(() => {
		postAnalitk();

		const handleClickOutside = event => {
			if (calendarRef.current && !calendarRef.current.contains(event.target)) {
				setIsCalenderActive(false);
			}
		};

		window.addEventListener('click', handleClickOutside);

		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	// -------------- 1 -------------------
	const remainingValue = analitic.menadjer * 300 - yearsValue;
	const chartData = {
		datasets: [
			{
				data: [yearsValue, remainingValue],
				backgroundColor: ['#FF6501', 'rgba(255, 91, 91, 0.15)'],
			},
		],
	};

	// ----------------- 2 ------------------
	const remainingValue2 = analitic.menadjer * 300 - monthlyValue;
	const chartData2 = {
		datasets: [
			{
				data: [monthlyValue, remainingValue2],
				backgroundColor: ['#FFA66C', 'rgba(255, 91, 91, 0.15)'],
			},
		],
	};

	// ----------------- 3 ------------------
	const remainingValue3 = analitic.menadjer * 300 - boughtValue;
	const chartData3 = {
		datasets: [
			{
				data: [boughtValue, remainingValue3],
				backgroundColor: ['#00B074', 'rgba(0, 176, 116, 0.15)'],
			},
		],
	};

	// ----------------- 4 ------------------
	const remainingValue4 = analitic.menadjer * 300 - menadjerValue;
	const chartData4 = {
		datasets: [
			{
				data: [menadjerValue, remainingValue4],
				backgroundColor: ['#2D9CDB', 'rgba(45, 156, 219, 0.15)'],
			},
		],
	};

	// ----------------- 5 ------------------
	const remainingValue5 = analitic.menadjer * 300 - ropValue;
	const chartData5 = {
		datasets: [
			{
				data: [ropValue, remainingValue5],
				backgroundColor: ['#2D9CDB', 'rgba(45, 156, 219, 0.15)'],
			},
		],
	};

	const canvasWidth = 153; // Istalgan o'lchamni tanlang
	const canvasHeight = 153; // Istalgan o'lchamni tanlang

	return (
		<div>
			{/* HEADER START */}
			<header className='header'>
				<div className='header__search'>
					{/* <input type='search' name='search' placeholder='Qidirish' />
					<img src={searchIcon} alt='search icon' /> */}
				</div>
				<Header />
			</header>
			{/* HEADER END */}
			<div className='content'>
				<div className='head'>
					<h2 className='title'>Analitika va Statistika</h2>
					<div className='greytitle'>
						Xush Kelibsiz, <span className='text-[#2D9CDB]'>{userInfo && userInfo.name}</span>
					</div>
				</div>
				<div className='stats'>
					<div className='stats__item'>
						<div className='stats__top flex items-center justify-center'>
							<h3 className='minititle mr-1'>Qo&apos;ng'iroqlarning umumiy soni</h3>
							{/* Calendar start */}
							<div className='relative'>
								<div
									onClick={() => setShow(!show)}
									className='flex items-center justify-between bg-[#F3F2F7] rounded-xl py-3 px-4 cursor-pointer'>
									<img
										className='flex items-center justify-center 2xl:w-12 w-8 2xl:h-12 h-8 bg-[#2D9CDB]/[.15] 2xl:rounded-[15px] rounded-lg 2xl:mr-3 mr-2'
										src={topIcon3}
										alt='top icon'
									/>
									<div className='text-center mr-2 2xl:text-base text-sm font-medium whitespace-nowrap'>
										Sana tanlang <br />
										<span>{dateState && moment(dateState).format('YYYY-MM-DD')}</span>
									</div>
									<img className='2xl:w-5 w-3 2xl:h-5 h-3 object-cover ml-2' src={topArrow} alt='arrow icon' />
								</div>
								{show && <Calendar className='absolute z-50 top-20 right-0 ' value={dateState} onChange={changeDate} />}
							</div>
							{/* Calendar end */}
						</div>

						<div className='stats__content stats__content_two'>
							<div className='stats__chars'>
								<div className='stats__graph cursor-pointer'>
									<Doughnut
										data={chartData}
										width={canvasWidth}
										height={canvasHeight}
										options={{ responsive: true, borderWidth: false }}
									/>
									<div className='stats__per'>{analitic.year}</div>
								</div>
								<div className='stats__name'>Yil</div>
							</div>

							<div className='stats__chars'>
								<div className='stats__graph cursor-pointer'>
									<Doughnut
										data={chartData2}
										width={canvasWidth}
										height={canvasHeight}
										options={{ responsive: true, borderWidth: false }}
									/>
									<div className='stats__per'>{analitic.monthly}</div>
								</div>
								<div className='stats__name'>Oy</div>
							</div>
						</div>
					</div>

					<div className='stats__item'>
						<div className='stats__top'>
							<div>
								<h3 className='minititle mb-[20px]'>Umumiy sotish</h3>
								<div className='stats__chars'>
									<div className='stats__graph cursor-pointer'>
										<Doughnut
											data={chartData3}
											width={canvasWidth}
											height={canvasHeight}
											options={{ responsive: true, borderWidth: false }}
										/>
										<div className='stats__per'>{analitic.bought}</div>
									</div>
									<div className='stats__name'>Sotish</div>
								</div>
							</div>

							<div>
								{/* GLOBS start */}
								<div
									className=' relative top__box analitica-calendar cursor-pointer 2xl:mb-6 mb-4 flex justify-between'
									onClick={() => setDown(!down)}>
									<div className='flex items-center justify-center 2xl:w-12 w-8 2xl:h-12 h-8 bg-[#2D9CDB]/[.15] 2xl:rounded-[15px] rounded-lg 2xl:mr-3 mr-2'>
										<img className='2xl:w-6 w-4 2xl:h-7 h-5' src={globos} alt='top icon' />
									</div>
									<div className=' mr-3 '>{city && city ? city : 'Shaharni tanlang'}</div>

									<img className='2xl:w-5 w-3 2xl:h-5 h-3 object-cover' src={topArrow} alt='arrow icon' />
									{down && (
										<div className='w-full text-center absolute top-24 right-0 z-50 shadow shadow-blue-500/40 hover:shadow-indigo-500/40'>
											{citys &&
												citys.map(city => {
													return (
														<div className='border-b border-neutral-600 mb-4' key={city.id}>
															<h2
																onClick={() => {
																	onCity(city.name);
																	postAnalitk();
																}}
																className='mb-1 text-center 2xl:mr-3 mr-2 2xl:text-base text-sm font-medium cursor-pointer'>
																{city.name}
															</h2>
														</div>
													);
												})}
										</div>
									)}
								</div>
								{/* GLOBS end */}

								{/* Calendar start */}
								<div
									className='top__box analitica-calendar cursor-pointer '
									onClick={() => setIsCalenderActive(true)}
									ref={calendarRef}>
									<div className='flex items-center justify-center '>
										<img className='2xl:mr-3 mr-2  2xl:w-12 w-8 2xl:h-12 h-8 ' src={topIcon3} alt='top icon' />
									</div>
									<div className='top__row mr-3 '>
										<div id='dateSelect' className='text-center 2xl:mr-3 mr-2 2xl:text-base text-sm font-medium'>
											Sanalar oralig&apos;ini tanlang
										</div>
										{isCalenderActive && (
											<div className='top__calendar'>
												<Calendar onChange={setCalenderValues} value={calenderValues} selectRange />
											</div>
										)}
										<div className='text-center 2xl:mr-3 mr-2 2xl:text-base text-sm font-medium'>
											{calenderValues && new Date(calenderValues[0]).toLocaleDateString().replaceAll('.', '-') == 'Invalid Date'
												? null
												: new Date(calenderValues[0]).toLocaleDateString().replaceAll('.', '-')}
											{new Date(calenderValues[0]).toLocaleDateString().replaceAll('.', '-') == 'Invalid Date' ? null : ':'}{' '}
											{new Date(calenderValues[1]).toLocaleDateString().replaceAll('.', '-') == 'Invalid Date'
												? null
												: new Date(calenderValues[1]).toLocaleDateString().replaceAll('.', '-')}
										</div>
									</div>
									<img className='2xl:w-5 w-3 2xl:h-5 h-3 object-cover' src={topArrow} alt='arrow icon' />
								</div>
								{/* Calendar end */}
							</div>
						</div>
					</div>

					<div className='stats__item'>
						<div className='stats__top flex items-center justify-center'>
							<h3 className='minititle'>Menejerlarning umumiy soni</h3>
						</div>
						<div className='stats__content stats__content_two'>
							<div className='stats__chars'>
								<div className='stats__graph cursor-pointer'>
									<Doughnut
										data={chartData4}
										width={canvasWidth}
										height={canvasHeight}
										options={{ responsive: true, borderWidth: false }}
									/>
									<div className='stats__per'>{analitic.menadjer}</div>
								</div>
								<div className='stats__name'>Xodim</div>
							</div>

							<div className='stats__chars'>
								<div className='stats__graph cursor-pointer'>
									<Doughnut
										data={chartData5}
										width={canvasWidth}
										height={canvasHeight}
										options={{ responsive: true, borderWidth: false }}
									/>
									<div className='stats__per'>{analitic.rop}</div>
								</div>
								<div className='stats__name'>ROP</div>
							</div>
						</div>
					</div>

					{/* LINYA */}
					<div className='stats__item'>
						<div className='stats__top'>
							<h3 className='minititle'>Butun jamoaning oylar bo'yicha ko'rsatkichlari</h3>
							<div className='flex flex-col'>
								{/* DAYS start */}
								<div
									className=' relative top__box analitica-calendar cursor-pointer  flex justify-between mb-3'
									style={{ paddingTop: '15px', paddingBottom: '15px' }}
									onClick={() => setSelectMontDown(!selectMontDown)}>
									<div className=' mr-3 '>{selectMonth && selectMonth ? selectMonth : 'Kunlik yoki Oylik'}</div>

									<img className='2xl:w-5 w-3 2xl:h-5 h-3 object-cover' src={topArrow} alt='arrow icon' />
									{selectMontDown && (
										<div className='w-full text-center absolute top-16 right-0 z-50 shadow g-cyan-500  shadow-cyan-500/50'>
											{selectMonths &&
												selectMonths.map(days => {
													return (
														<div className='border-b border-neutral-600 mb-4' key={days.id}>
															<h2
																onClick={() => {
																	onDays(days.name, days.id);
																}}
																className='mb-1 text-center 2xl:mr-3 mr-2 2xl:text-base text-sm font-medium cursor-pointer'>
																{days.name}
															</h2>
														</div>
													);
												})}
										</div>
									)}
								</div>
								{/* DAYS end */}
								<Link to={`https://crm.orzugrand.uz/api/downloadExcel/${selectXLS}`}>
									<button className='2xl:font-bold font-semibold 2xl:text-lg text-base cursor-pointer flex justify-between items-center text-[#2D9CDB] border-[1px] border-[#2D9CDB] rounded-xl py-3 px-5 hover:text-[#2d9bdbc5]'>
										<img className='2xl:mr-3 mr-2  2xl:w-6 w-5 2xl:h-6 h-5 object-cover' src={dowlandIcon} alt='' /> Yuklab olish
									</button>
								</Link>
							</div>
						</div>

						<div className='stats__chars'>
							<div className='stats__graph2'>
								<Line data={lineData} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Stats;
