/* eslint-disable no-unused-expressions */
import authIcon from '../images/auth-1.svg';
import authIcon2 from '../images/auth-2.svg';
import authIcon3 from '../images/eye-closed.svg';
import trashIcon from '../images/trash.svg';
import authIcon4 from '../images/eye.svg';
import operatorAdd from '../images/operator-add.svg';
import Return from '../components/Return';
import defaultIcon from '../images/user.png';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { Header } from '../components';
import searchIcon from '../images/search-icon.svg';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Stack } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CreateUser = () => {
	const navigateAuth = useNavigate();
	$('.auth__eye').on({
		mousedown: function () {
			$(this).attr('src', authIcon4);
			$(this).siblings('input').attr('type', 'text');
		},

		mouseup: function () {
			$(this).attr('src', authIcon3);
			$(this).siblings('input').attr('type', 'password');
		},
	});

	const [isValidated, setIsValidated] = useState(false);
	const [photoSelected, setPhotoSelected] = useState(false);

	const [prevImage, setPrevImage] = useState(defaultIcon);
	const navigate = useNavigate();
	const uploadTrigger = useRef();
	const inputFile = useRef();

	const token = Cookies.get('token');

	// SNACKBAR START
	const [opens, setOpens] = React.useState(false);

	const handleClicks = () => {
		setOpens(true);
	};

	const handleCloses = (event, reason) => {
		if (reason == 'clickaway') {
			return;
		}

		setOpens(false);
	};
	// SNACKBAR END

	const [user, setUser] = useState({ password: '', confirm_password: '', parent_id: null, city: '' });
	const [inputImga, setInputImg] = useState(true);

	const clearImage = () => {
		setPrevImage(defaultIcon);
		setPhotoSelected(false);
		user.image = '';
	};

	const creatingUser = async e => {
		if (!user.image) {
			handleClick();
		}
		const formData = new FormData();
		formData.append('name', user.name);
		formData.append('email', user.email);
		formData.append('city', user.city);
		formData.append('image', user.image);
		formData.append('is_admin', isAdmin);
		formData.append('password', user.password);
		formData.append('confirm_password', user.confirm_password);
		if (isAdmin !== 1) {
			formData.append('parent_id', user.parent_id);
		}
		e.preventDefault();
		await axios
			.post(`https://crm.orzugrand.uz/api/user`, formData, {
				headers: {
					'Content-Type': 'multiple/formData',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const creatingUser = res.data.data;
				navigate('/operator');
			})
			.catch(er => {
				// console.log(er);
				handleClicks();
			});
	};

	// user API
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!token) {
			navigateAuth('/auth');
		}
		getOperators();
	}, []);

	const [userCreate, setUserCreate] = useState('');

	const getOperators = async () => {
		setIsLoading(true);
		await axios
			.get('https://crm.orzugrand.uz/api/user', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token,
				},
			})
			.then(res => {
				const operators = res.data.data;
				setUserCreate(operators);
				setIsLoading(false);
			})
			.catch(er => {
				setIsLoading(true);
				// console.log(er);
			});
	};

	const triggerUpload = e => {
		e.preventDefault();
		uploadTrigger.current.nextElementSibling.click();
		setInputImg(false);
	};

	const isAdmin = useSelector(state => state.sendAdmin);

	// SNACBAR START
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	// SNACBAR END

	return (
		<>
			{/* OLD PASWWORD XATO */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar
					open={open}
					autoHideDuration={3000}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert severity='error'>Rasim yuklang!</Alert>
				</Snackbar>
			</Stack>
			<>
				{/* OLD PASWWORD XATO */}
				<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={opens} autoHideDuration={3000} onClose={handleCloses}>
						<Alert severity='error'>Bu email mavjud!</Alert>
					</Snackbar>
				</Stack>
				{/* HEADER START */}
				<header className='header'>
					<div className='header__search opacity-0'>
						<input
							type='search'
							name='search'
							// onChange={e => setSearchByNameNum(e.target.value)}
							placeholder="Xodimlarni name va tell raqami bo'yicha qidirish"
						/>

						<img src={searchIcon} alt='search icon' />
					</div>
					<Header />
				</header>
				{/* HEADER END */}
				<Return title='Royhatga qaytish' navigate={-1} />
				<div className='auth auth_create'>
					<div className='auth__inner flex items-center justify-between'>
						<div className='auth__content'>
							<h2 className='auth__title auth__title_margin'>
								{isAdmin === 0 ? <span>Menejer qo&apos;shish</span> : <span>Admin qo&apos;shish</span>}
							</h2>

							{isAdmin === 0 ? (
								<div className='auth__grey'>Menejerning barcha ma'lumotlarini kiriting </div>
							) : (
								<div className='auth__grey'>Adminning barcha ma'lumotlarini kiriting </div>
							)}

							<form onSubmit={creatingUser} className='auth__form'>
								<div className='auth__naming'>
									<span>Shaxsiy kabinet qo&apos;shish</span>
								</div>
								<div className='auth__set '>
									<div className='auth__wrapping' onClick={clearImage}>
										<img className='auth__avatar' src={prevImage ? prevImage : defaultIcon} alt='user img' />
										{photoSelected ? (
											<div className='auth__clear'>
												<img className='auth__del' src={trashIcon} alt='trash icon' />
											</div>
										) : (
											''
										)}
									</div>
									<button onClick={triggerUpload} ref={uploadTrigger} className='auth__btn'>
										Rasm qo&apos;yish
									</button>

									<input
										ref={inputFile}
										onChange={e => {
											setUser({ ...user, [e.target.name]: e.target.files[0] });
											const files = e.target.files;
											if (files && files.length > 0) {
												const selectedFile = files[0];
												if (typeof window !== 'undefined') {
													const url = URL.createObjectURL(selectedFile);
													setPrevImage(url);
													setPhotoSelected(true);
												}
											}
										}}
										accept='.jpg,.png'
										type='file'
										name='image'
									/>
								</div>
								<div className='auth__box'>
									<div className='auth__wrapper'>
										<img className='auth__icon' src={authIcon} alt='auth icon' />
										<input
											onChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
											type='text'
											name='name'
											placeholder='Ism va Familiya kiriting'
											required
										/>
									</div>
								</div>
								<div className='auth__box'>
									{isAdmin === 0 ? (
										<select
											required
											defaultValue={''}
											name='parent_id'
											className='mt-3'
											onChange={e => {
												setUser({ ...user, [e.target.name]: parseInt(e.target.value) });
											}}>
											<option disabled selected value={''}>
												Admini tanlang
											</option>

											{userCreate &&
												userCreate.data.map(userCreat => {
													return (
														userCreat.is_admin == 1 && (
															<option key={userCreat.id} value={userCreat.id}>
																{userCreat.name}
															</option>
														)
													);
												})}
										</select>
									) : null}

									<select
										required
										name='city'
										className='mt-3'
										onChange={e => setUser({ ...user, [e.target.name]: e.target.value })}>
										<option disabled selected value={''}>
											Shaharni tanlang
										</option>
										<option value="Farg'ona">Farg'ona</option>
										<option value='Samarqand'>Samarqand</option>
										<option value='Namangan'>Namangan </option>
										<option value='Toshkent'>Toshkent</option>
									</select>
								</div>
								<div className='auth__box'>
									<div className='auth__wrapper'>
										<img className='auth__icon' src={authIcon} alt='auth icon' />
										<input
											onChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
											type='email'
											name='email'
											placeholder='Email'
											required
										/>
									</div>
								</div>
								<div className='auth__box'>
									<div className='auth__wrapper'>
										<img className='auth__icon' src={authIcon2} alt='auth icon' />
										<input
											className={isValidated ? 'input-danger' : ''}
											onChange={e => {
												setUser({ ...user, [e.target.name]: e.target.value });
												user.confirm_password === e.target.value ? setIsValidated(false) : setIsValidated(true);
											}}
											type='password'
											required
											name='password'
											placeholder='Parol'
										/>
										<img className='auth__eye' src={authIcon3} alt='auth icon' />
									</div>
								</div>
								<div className='auth__box'>
									<div className='auth__wrapper'>
										<img className='auth__icon' src={authIcon2} alt='auth icon' />
										<input
											className={isValidated ? 'input-danger' : ''}
											onChange={e => {
												setUser({ ...user, [e.target.name]: e.target.value });
												user.password === e.target.value ? setIsValidated(false) : setIsValidated(true);
											}}
											type='password'
											name='confirm_password'
											required
											placeholder='Parolni takrorlang'
										/>
										<img className='auth__eye' src={authIcon3} alt='auth icon' />
									</div>
								</div>
								{isValidated ? <div className='auth__alert'>Parollar bir xil bo&apos;lishi kerak!</div> : ''}

								<div className='auth__btns'>
									<button className='auth__btn'>Yaratish</button>
									<button type='reset' className='auth__btn auth__btn_grey'>
										Bekor qilish
									</button>
								</div>
							</form>
						</div>

						<div className='auth__banner'>
							<img src={operatorAdd} alt='operator img' width={656} height={656} />
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default CreateUser;
